import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import {
  Constant,
  DASHBOARD_TAB_NAME,
  FIELD_COMPONENT,
  FormatDateEnum,
  MODULE_NAME,
  MediaTypeEnum,
  PERIOD,
  TypeMediaFileEnum,
  TypeMediaInDashBoard
} from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogCustomSortComponent } from 'app/dialog/dialog-custom-sort/dialog-custom-sort.component';
import { DialogExtractSettingComponent } from 'app/dialog/dialog-extract-setting/dialog-extract-setting.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogSettingDeviceMonitorAutoRefreshComponent } from 'app/dialog/dialog-setting-device-monitor-auto-refresh/dialog-setting-device-monitor-auto-refresh.component';
import { Common } from 'app/model/entity/common';
import { ConditionFilterDeviceLog } from 'app/model/entity/condition-filter-device-log';
import { ConditionFilterMediaLog } from 'app/model/entity/condition-filter-medialog';
import { CustomTag } from 'app/model/entity/custom-tag';
import { Device } from 'app/model/entity/device';
import { DeviceLog } from 'app/model/entity/device-log';
import { MediaLog } from 'app/model/entity/mediaLog';
import { MediaLogEntities } from 'app/model/entity/mediaLogEntities';
import { SettingDeviceAutoRefresh } from 'app/model/entity/setting-device-auto-refresh';
import { SimpleMedia } from 'app/model/entity/simple/simple-media';
import { SaveDashboardStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { APICustomerService } from 'app/service/api-customer.service';
import { CommonService } from 'app/service/common.service';
import { ConditionFilterDeviceLogService } from 'app/service/condition-filter-device-log.service';
import { ConditionFilterMediaLogService } from 'app/service/condition-filter-media-log.service';
import { CustomTagService } from 'app/service/custom-tag.service';
import { DataService } from 'app/service/data.service';
import { DeviceService } from 'app/service/device.service';
import { DialogService } from 'app/service/dialog.service';
import { ExecutingService } from 'app/service/executing.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { SettingDeviceAutoRefreshService } from 'app/service/setting-device-auto-refresh.service';
import { SimpleMediaService } from 'app/service/simple/simple-media.service';
import { AppState } from 'app/store/app.state';
import * as fileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import { DatePickerDirective } from 'ng2-date-picker';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, interval } from 'rxjs';
import { repeatWhen, takeUntil, timeInterval } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  /**
   * common
   */
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  DASHBOARD_TAB_NAME = DASHBOARD_TAB_NAME;
  Helper = Helper;
  MediaTypeEnum = MediaTypeEnum;
  PERIOD = PERIOD;
  Constant = Constant;
  TypeMediaInDashBoard = TypeMediaInDashBoard;
  TypeMediaFileEnum = TypeMediaFileEnum;
  public readonly TYPES = ['jpg', 'png', 'bmp', 'mp4'];

  /**
   * constants
   */
  public readonly OLD_TIME_FROM = 'oldTimeFrom';
  public readonly OLD_TIME_TO = 'oldTimeTo';
  public readonly IS_CHANGE_TOTAL_PER_DEVICE = 'isChangeTotalPerDevice';
  public readonly TIME_FROM = 'from';
  public readonly TIME_TO = 'to';
  public readonly IS_CHANGE_GRAND_TOTAL_PER_MEDIA = 'isChangeGrandTotalPerMedia';
  private readonly FORMAT_DATE = 'YYYY-MM-DD';
  private readonly PREVIOUS_DATE_FROM = 'previousDateFrom';
  private readonly PREVIOUS_DATE_TO = 'previousDateTo';
  private readonly DISABLE_LATEST_MEDIA_LOG = 'disableLatestMediaLog';
  private readonly REGISTRATION_ID_ELEMENT = 'registrationId';
  private readonly FIRST_ELEMENT = 'first';
  private readonly SECOND_ELEMENT = 'second';
  private readonly URL = 'url';
  private readonly MEDIA_KEY_SPLIT = '_';
  private readonly URL_KEY_SPLIT = '/';
  private readonly MEDIA_EXTENSIONS_KEY_SPLIT = '.';
  private readonly NO_DATA_TO_EXPORT = 'no-data-to-export';
  private readonly NO_SELECT_MEDIA = 'no-select';
  private readonly SELECT_ALL = 'select-all';
  private readonly ALL_DEVICE = 'all-device';
  private readonly ALL_DEVICE_MULTI_LANGUAGE = ['All Devices', 'すべてのデバイス'];
  private readonly SELECT_ALL_MULTI_LANGUAGE = ['Select All', 'すべて選択'];
  private readonly JP_KEY = 1;
  private readonly EN_KEY = 0;
  private readonly pauseCallCurrentStatusSubject = new Subject<void>();
  private readonly startCallCurrentStatusSubject = new Subject<void>();
  private readonly LAST_MONTH_OF_YEAR = 12;
  private readonly LAST_WEEK_OF_YEAR = 52;
  private readonly FIRST_MONTH_OF_YEAR = 1;
  /**
   * apiCommandDevice
   */
  private apiCommandDevice: string;

  /**
   * boolean
   */
  isChangeLayout: boolean;
  isActiveDeviceMonitorTab: boolean;
  isActiveContentReportTab: boolean;
  isActivePropertiesTab: boolean;
  isActiveLogTab: boolean;
  isHideCol2: boolean;
  isChangedTimeFromContentReport: boolean;
  isChangedTimeToContentReport: boolean;
  isChangedGrandTotal: boolean;
  isChangedTotal: boolean;
  hasDataInDeviceMonitorTab: boolean;

  /**
   * object
   */
  period: number = PERIOD.DAILY;
  mediaLogContentSelected: MediaLogContent;
  mediaTabProperties: SimpleMedia;
  conditionFilterMediaLog: ConditionFilterMediaLog;
  conditionFilterDeviceLog: ConditionFilterDeviceLog = new ConditionFilterDeviceLog();
  mediaNameFilters: Array<string>;
  mediaLogContents: Array<MediaLogContent> = new Array<MediaLogContent>();
  dateAndNumberPlays: Array<NumberPlayPerDay>;
  mediaNamesToDisplay: Array<string>;
  customTags: Array<CustomTag>;
  deviceLogSelected: DeviceLog;
  deviceLogs: Array<DeviceLog> = new Array<DeviceLog>();
  allDevices: Array<Device>;
  customTagSubject: Subject<boolean> = new Subject();
  isOldPeriodMonth: boolean;
  subTotalPlayPerDay: SubTotalPlayMedia;
  mediaObjectInDB: Array<SimpleMedia> = new Array<SimpleMedia>();
  mediaTypesToDisplay: string[];

  /**
   * time
   */
  timeFrom: any;
  timeTo: any;
  timeFromDisplay: string;
  timeToDisplay: string;
  firstDateOfWeek: string;
  endDateOfWeek: string;
  settingDeviceMonitorAutoRefresh: SettingDeviceAutoRefresh;
  intervalCurrentStatusReport: any;
  config: any;

  /**
   * list of action subscriptions
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  subscribeCallCustomAPICurrentStatus: any;

  /**
   * sort filter variable
   */
  isSortFilter: boolean;
  isCheckAllOptionFilter: boolean = true;
  mediaLogPerDevicesDisplay: any;
  columnSortFiltering: string;
  isShowPopUpSortFilter: boolean;
  lastColumnFilter: string;
  listFilterDisplay: Array<listFilter> = new Array<listFilter>();
  listFilterDisplayOrigin: Array<listFilter> = new Array<listFilter>();
  mediaLogPerDevicesFilterDisplay: any;
  isFilter: boolean;
  isClear: boolean;
  listCurrentFilter: IHash = {};
  listSorted: any = [];
  columns: any[] = [];
  // Common object
  private commonObject: Common;
  /**
   * language key
   */
  languageKey: string;

  /**
   * lastTimeUpdate
   */
  public lastTimeUpdate;

  /**
   * timeByImg
   */
  private timeByImg;
  /**
   * date header period
   */
  public dateHeaderPeriod: number;

  /**
   * originalObject
   */
  originalObject: any;

  /**
   * custom tag element name
   */
  public customTagElementName: string;

  /**
   * list device
   */
  devices: Array<Device>;

  /**
   * device display
   */
  public deviceDisplay: Device[] = new Array<Device>();

  /**
   * stateOfComponent
   */
  stateOfComponent: {
    isChangeLayout: boolean;
    isActiveDeviceMonitorTab: boolean;
    isActiveContentReportTab: boolean;
    isActivePropertiesTab: boolean;
    isActiveLogTab: boolean;
    isHideCol2: boolean;
    isChangedTimeFromContentReport: boolean;
    isChangedTimeToContentReport: boolean;
    isChangedGrandTotal: boolean;
    isChangedTotal: boolean;
    period: number;
    mediaLogContentSelected: MediaLogContent;
    mediaTabProperties: SimpleMedia;
    conditionFilterMediaLog: ConditionFilterMediaLog;
    conditionFilterDeviceLog: ConditionFilterDeviceLog;
    mediaNameFilters: Array<string>;
    mediaLogContents: Array<MediaLogContent>;
    dateAndNumberPlays: Array<NumberPlayPerDay>;
    mediaNamesToDisplay: Array<string>;
    customTags: Array<CustomTag>;
    deviceLogSelected: DeviceLog;
    deviceLogs: Array<DeviceLog>;
    allDevices: Array<Device>;
    customTagSubject: Subject<boolean>;
    isOldPeriodMonth: boolean;
    timeFrom: any;
    timeTo: any;
    timeFromDisplay: string;
    timeToDisplay: string;
    firstDateOfWeek: string;
    endDateOfWeek: string;
    settingDeviceMonitorAutoRefresh: SettingDeviceAutoRefresh;
    intervalCurrentStatusReport: any;
    config: any;
    subTotalPlayPerDay: SubTotalPlayMedia;
    isSortFilter: boolean;
    isCheckAllOptionFilter: boolean;
    mediaLogPerDevicesDisplay: any;
    columnSortFiltering: string;
    isShowPopUpSortFilter: boolean;
    lastColumnFilter: string;
    listFilterDisplay: Array<listFilter>;
    listFilterDisplayOrigin: Array<listFilter>;
    mediaLogPerDevicesFilterDisplay: any;
    isFilter: boolean;
    isClear: boolean;
    listCurrentFilter: IHash;
    listSorted: any;
    columns: any[];
    mediaObjectInDB: Array<SimpleMedia>;
    mediaTypesToDisplay: string[];
    hasDataInDeviceMonitorTab: boolean;
    dateHeaderPeriod: number;
  };

  userId: Number;

  /**
   * true if check all
   */
  public isCheckedAll: boolean;
  constructor(
    private dialogService: DialogService,
    private customTagService: CustomTagService,
    private deviceService: DeviceService,
    private actionService: MenuActionService,
    public readonly store: Store<AppState>,
    private apiCustomerService: APICustomerService,
    private settingDeviceAutoRefreshService: SettingDeviceAutoRefreshService,
    private conditionFilterMediaLogService: ConditionFilterMediaLogService,
    private conditionFilterDeviceLogService: ConditionFilterDeviceLogService,
    private dataService: DataService,
    private commonService: CommonService,
    public translateService: TranslateService,
    private executingService: ExecutingService,
    private simpleMediaService: SimpleMediaService,
    private datePipe: DatePipe,
    private toastrService: ToastrService
  ) {
    // action sort filter
    this.subscriptions.push(
      this.actionService.actionSortAndFilter.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DashboardComponent]) {
          this.isSortFilter = !this.isSortFilter;
        }
      })
    );
    // action clear setting
    this.subscriptions.push(
      this.actionService.actionClearSetting.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DashboardComponent]) {
          this.clearSetting();
        }
      })
    );
    // action get latest media log
    this.subscriptions.push(
      this.actionService.actionGetLatestMediaLog.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DashboardComponent]) {
          this.getLatestMediaLog();
        }
      })
    );
    // action export media log
    this.subscriptions.push(
      this.actionService.actionExportMediaLog.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DashboardComponent]) {
          this.exportMediaLog();
        }
      })
    );
    // action export content report
    this.subscriptions.push(
      this.actionService.actionExportContentReport.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DashboardComponent]) {
          this.exportContentReport();
        }
      })
    );
    // action setting auto refresh
    this.subscriptions.push(
      this.actionService.actionSetting.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DashboardComponent]) {
          this.setting();
        }
      })
    );

    // action command device
    this.subscriptions.push(
      this.actionService.actionCommandDevice.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DashboardComponent]) {
          this.commandDevice();
        }
      })
    );

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        // multi language date picker
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        this.lastTimeUpdate = Helper.formatString(
          this.translateService.instant(`timetable-operation-manager.last-time-update`),
          Helper.updateLanguageLastTime(this.timeByImg, this.languageKey)
        );
        this.deviceLogs.forEach(device => {
          if (device.systemTime) {
            device.systemTime = Helper.updateLanguageSystemTime(device.systemTime, this.languageKey);
          }
        });
        if (
          this.conditionFilterMediaLog?.registrationIds?.includes(this.ALL_DEVICE_MULTI_LANGUAGE[this.EN_KEY]) ||
          this.conditionFilterMediaLog?.registrationIds?.includes(this.ALL_DEVICE_MULTI_LANGUAGE[this.JP_KEY])
        ) {
          this.conditionFilterMediaLog.registrationIds = [this.translateService.instant('dashboard.tab-content-report.all-device')];
        }
        if (
          this.conditionFilterMediaLog?.playlistNames?.includes(this.SELECT_ALL_MULTI_LANGUAGE[this.EN_KEY]) ||
          this.conditionFilterMediaLog?.playlistNames?.includes(this.SELECT_ALL_MULTI_LANGUAGE[this.JP_KEY])
        ) {
          this.conditionFilterMediaLog.playlistNames = [this.translateService.instant('dashboard.tab-content-report.select-all')];
        }
        if (
          this.conditionFilterDeviceLog[Constant.LABEL_1]?.includes(this.SELECT_ALL_MULTI_LANGUAGE[this.EN_KEY]) ||
          this.conditionFilterDeviceLog[Constant.LABEL_1]?.includes(this.SELECT_ALL_MULTI_LANGUAGE[this.JP_KEY])
        ) {
          this.conditionFilterDeviceLog[Constant.LABEL_1] = this.translateService.instant('dashboard.tab-device-monitor.select-all');
        }
        this.mediaTypesToDisplay = Helper.handleMediaTypesToDisplay(
          this.conditionFilterMediaLog?.condition?.mediaTypes,
          this.translateService
        );
        setTimeout(() => {
          this.updateConfig(this.period);
        });
      })
    );

    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState?.dashboardState?.stateOfComponent.isChangeLayout,
            isActiveDeviceMonitorTab: componentState?.dashboardState?.stateOfComponent.isActiveDeviceMonitorTab,
            isActiveContentReportTab: componentState?.dashboardState?.stateOfComponent.isActiveContentReportTab,
            isActivePropertiesTab: componentState?.dashboardState?.stateOfComponent.isActivePropertiesTab,
            isActiveLogTab: componentState?.dashboardState?.stateOfComponent.isActiveLogTab,
            isHideCol2: componentState?.dashboardState?.stateOfComponent.isHideCol2,
            isChangedGrandTotal: componentState?.dashboardState?.stateOfComponent.isChangedGrandTotal,
            isChangedTotal: componentState?.dashboardState?.stateOfComponent.isChangedTotal,
            period: componentState?.dashboardState?.stateOfComponent.period,
            mediaLogContentSelected: componentState?.dashboardState?.stateOfComponent.mediaLogContentSelected,
            mediaTabProperties: componentState?.dashboardState?.stateOfComponent.mediaTabProperties,
            conditionFilterMediaLog: componentState?.dashboardState?.stateOfComponent.conditionFilterMediaLog,
            conditionFilterDeviceLog: componentState?.dashboardState?.stateOfComponent.conditionFilterDeviceLog,
            mediaNameFilters: componentState?.dashboardState?.stateOfComponent.mediaNameFilters,
            mediaLogContents: componentState?.dashboardState?.stateOfComponent.mediaLogContents,
            dateAndNumberPlays: componentState?.dashboardState?.stateOfComponent.dateAndNumberPlays,
            mediaNamesToDisplay: componentState?.dashboardState?.stateOfComponent.mediaNamesToDisplay,
            customTags: componentState?.dashboardState?.stateOfComponent.customTags,
            deviceLogSelected: componentState?.dashboardState?.stateOfComponent.deviceLogSelected,
            deviceLogs: componentState?.dashboardState?.stateOfComponent.deviceLogs,
            allDevices: componentState?.dashboardState?.stateOfComponent.allDevices,
            customTagSubject: componentState?.dashboardState?.stateOfComponent.customTagSubject,
            isOldPeriodMonth: componentState?.dashboardState?.stateOfComponent.isOldPeriodMonth,
            timeFrom: componentState?.dashboardState?.stateOfComponent.timeFrom,
            timeTo: componentState?.dashboardState?.stateOfComponent.timeTo,
            timeFromDisplay: componentState?.dashboardState?.stateOfComponent.timeFromDisplay,
            timeToDisplay: componentState?.dashboardState?.stateOfComponent.timeToDisplay,
            firstDateOfWeek: componentState?.dashboardState?.stateOfComponent.firstDateOfWeek,
            endDateOfWeek: componentState?.dashboardState?.stateOfComponent.endDateOfWeek,
            settingDeviceMonitorAutoRefresh: componentState?.dashboardState?.stateOfComponent.settingDeviceMonitorAutoRefresh,
            intervalCurrentStatusReport: componentState?.dashboardState?.stateOfComponent.intervalCurrentStatusReport,
            config: componentState?.dashboardState?.stateOfComponent.config,
            subTotalPlayPerDay: componentState?.dashboardState?.stateOfComponent.subTotalPlayPerDay,
            mediaLogPerDevicesDisplay: componentState?.dashboardState?.stateOfComponent.mediaLogPerDevicesDisplay,
            columns: componentState?.dashboardState?.stateOfComponent.columns,
            isSortFilter: componentState?.dashboardState?.stateOfComponent.isSortFilter,
            isCheckAllOptionFilter: componentState?.dashboardState?.stateOfComponent.isCheckAllOptionFilter,
            columnSortFiltering: componentState?.dashboardState?.stateOfComponent.columnSortFiltering,
            isShowPopUpSortFilter: componentState?.dashboardState?.stateOfComponent.isShowPopUpSortFilter,
            lastColumnFilter: componentState?.dashboardState?.stateOfComponent.lastColumnFilter,
            listFilterDisplay: componentState?.dashboardState?.stateOfComponent.listFilterDisplay,
            listFilterDisplayOrigin: componentState?.dashboardState?.stateOfComponent.listFilterDisplayOrigin,
            mediaLogPerDevicesFilterDisplay: componentState?.dashboardState?.stateOfComponent.mediaLogPerDevicesFilterDisplay,
            isFilter: componentState?.dashboardState?.stateOfComponent.isFilter,
            isClear: componentState?.dashboardState?.stateOfComponent.isClear,
            listCurrentFilter: componentState?.dashboardState?.stateOfComponent.listCurrentFilter,
            listSorted: componentState?.dashboardState?.stateOfComponent.listSorted,
            mediaObjectInDB: componentState?.dashboardState.stateOfComponent.mediaObjectInDB,
            mediaTypesToDisplay: componentState?.dashboardState.stateOfComponent.mediaTypesToDisplay,
            isChangedTimeFromContentReport: componentState?.dashboardState?.stateOfComponent.isChangedTimeFromContentReport,
            isChangedTimeToContentReport: componentState?.dashboardState?.stateOfComponent.isChangedTimeToContentReport,
            hasDataInDeviceMonitorTab: componentState?.dashboardState?.stateOfComponent.hasDataInDeviceMonitorTab,
            dateHeaderPeriod: componentState?.dashboardState?.stateOfComponent.dateHeaderPeriod
          };
        })
    );
    this.commonObject = this.commonService.getCommonObject();
    this.userId = this.commonObject?.userId;
  }

  async ngOnInit(): Promise<void> {
    this.languageKey = this.commonObject?.setting?.language;
    await this.getOriginalContentObject();
    await this.getAllDevices();
    if (!this.stateOfComponent?.isChangeLayout) {
      this.handleActiveTabDashboard();
      // fetch data registration id
      this.fetchAllRegistrationIds();
      // get information setting auto refresh
      this.getInformationSettingAutoRefresh();
      setTimeout(() => {
        this.updateConfig(this.period);
        if (this.isActiveDeviceMonitorTab) {
          this.dataService.sendData([this.DISABLE_LATEST_MEDIA_LOG, true]);
          this.getDataDeviceMonitorTab();
        } else {
          this.mediaLogContentSelected = undefined;
          this.mediaLogContents = [];
          this.mediaTabProperties = undefined;
          this.fetchMediaDisplay();
        }
        this.dataService.sendData([Constant.DISABLE_BUTTON_DASHBOARD, this.isActiveDeviceMonitorTab]);
      });
    } else {
      this.handleAfterChangeLayout();
    }
  }

  ngOnDestroy(): void {
    this.intervalCurrentStatusReport?.unsubscribe();
    this.subscribeCallCustomAPICurrentStatus?.unsubscribe();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.store.dispatch(
      new SaveDashboardStateAction({
        isChangeLayout: true,
        isActiveDeviceMonitorTab: this.isActiveDeviceMonitorTab,
        isActiveContentReportTab: this.isActiveContentReportTab,
        isActivePropertiesTab: this.isActivePropertiesTab,
        isActiveLogTab: this.isActiveLogTab,
        isHideCol2: this.isHideCol2,
        isChangedGrandTotal: this.isChangedGrandTotal,
        isChangedTotal: this.isChangedTotal,
        period: this.period,
        mediaLogContentSelected: this.mediaLogContentSelected,
        mediaTabProperties: this.mediaTabProperties,
        conditionFilterMediaLog: this.conditionFilterMediaLog,
        conditionFilterDeviceLog: this.conditionFilterDeviceLog,
        mediaNameFilters: this.mediaNameFilters,
        mediaLogContents: this.mediaLogContents,
        dateAndNumberPlays: this.dateAndNumberPlays,
        mediaNamesToDisplay: this.mediaNamesToDisplay,
        customTags: this.customTags,
        deviceLogSelected: this.deviceLogSelected,
        deviceLogs: this.deviceLogs,
        allDevices: this.allDevices,
        customTagSubject: this.customTagSubject,
        isOldPeriodMonth: this.isOldPeriodMonth,
        timeFrom: this.timeFrom,
        timeTo: this.timeTo,
        timeFromDisplay: this.timeFromDisplay,
        timeToDisplay: this.timeToDisplay,
        firstDateOfWeek: this.firstDateOfWeek,
        endDateOfWeek: this.endDateOfWeek,
        settingDeviceMonitorAutoRefresh: this.settingDeviceMonitorAutoRefresh,
        intervalCurrentStatusReport: this.intervalCurrentStatusReport,
        config: this.config,
        subTotalPlayPerDay: this.subTotalPlayPerDay,
        mediaLogPerDevicesDisplay: this.mediaLogPerDevicesDisplay,
        columns: this.columns,
        isSortFilter: this.isSortFilter,
        isCheckAllOptionFilter: this.isCheckAllOptionFilter,
        columnSortFiltering: this.columnSortFiltering,
        isShowPopUpSortFilter: this.isShowPopUpSortFilter,
        lastColumnFilter: this.lastColumnFilter,
        listFilterDisplay: this.listFilterDisplay,
        listFilterDisplayOrigin: this.listFilterDisplayOrigin,
        mediaLogPerDevicesFilterDisplay: this.mediaLogPerDevicesFilterDisplay,
        isFilter: this.isFilter,
        isClear: this.isClear,
        listCurrentFilter: this.listCurrentFilter,
        listSorted: this.listSorted,
        mediaObjectInDB: this.mediaObjectInDB,
        mediaTypesToDisplay: this.mediaTypesToDisplay,
        isChangedTimeFromContentReport: this.isChangedTimeFromContentReport,
        isChangedTimeToContentReport: this.isChangedTimeToContentReport,
        hasDataInDeviceMonitorTab: this.hasDataInDeviceMonitorTab,
        dateHeaderPeriod: this.dateHeaderPeriod
      })
    );
    delete this.commonObject.isActiveDeviceMonitorTab;
    delete this.commonObject.isActivePropertiesTab;
    delete this.commonObject.isActiveLogTab;
  }

  /**
   * handle after change layout
   */
  private handleAfterChangeLayout(): void {
    this.isActiveDeviceMonitorTab = this.stateOfComponent?.isActiveDeviceMonitorTab;
    this.isActiveContentReportTab = this.stateOfComponent?.isActiveContentReportTab;
    this.isActivePropertiesTab = this.stateOfComponent?.isActivePropertiesTab;
    this.isActiveLogTab = this.stateOfComponent?.isActiveLogTab;
    this.isHideCol2 = this.stateOfComponent?.isHideCol2;
    this.isChangedGrandTotal = this.stateOfComponent?.isChangedGrandTotal;
    this.isChangedTotal = this.stateOfComponent?.isChangedTotal;
    this.period = this.stateOfComponent?.period;
    this.dateAndNumberPlays = this.stateOfComponent?.dateAndNumberPlays;
    this.mediaLogContentSelected = this.stateOfComponent?.mediaLogContentSelected;
    this.mediaTabProperties = this.stateOfComponent?.mediaTabProperties;
    this.conditionFilterMediaLog = this.stateOfComponent?.conditionFilterMediaLog;
    this.conditionFilterDeviceLog = this.stateOfComponent?.conditionFilterDeviceLog;
    this.mediaNameFilters = this.stateOfComponent?.mediaNameFilters;
    this.mediaLogContents = this.stateOfComponent?.mediaLogContents;
    this.mediaNamesToDisplay = this.stateOfComponent?.mediaNamesToDisplay;
    this.customTags = this.stateOfComponent?.customTags;
    this.deviceLogSelected = this.stateOfComponent?.deviceLogSelected;
    this.deviceLogs = this.stateOfComponent?.deviceLogs;
    this.allDevices = this.stateOfComponent?.allDevices;
    this.customTagSubject = this.stateOfComponent?.customTagSubject;
    this.isOldPeriodMonth = this.stateOfComponent?.isOldPeriodMonth;
    this.timeFrom = this.stateOfComponent?.timeFrom;
    this.timeTo = this.stateOfComponent?.timeTo;
    this.timeFromDisplay = this.stateOfComponent?.timeFromDisplay;
    this.timeToDisplay = this.stateOfComponent?.timeToDisplay;
    this.firstDateOfWeek = this.stateOfComponent?.firstDateOfWeek;
    this.endDateOfWeek = this.stateOfComponent?.endDateOfWeek;
    this.settingDeviceMonitorAutoRefresh = this.stateOfComponent?.settingDeviceMonitorAutoRefresh;
    this.intervalCurrentStatusReport = this.stateOfComponent?.intervalCurrentStatusReport;
    this.config = this.stateOfComponent?.config;
    this.subTotalPlayPerDay = this.stateOfComponent?.subTotalPlayPerDay;
    this.mediaLogPerDevicesDisplay = this.stateOfComponent?.mediaLogPerDevicesDisplay;
    this.columns = this.stateOfComponent?.columns;
    this.isSortFilter = this.stateOfComponent?.isSortFilter;
    this.isCheckAllOptionFilter = this.stateOfComponent?.isCheckAllOptionFilter;
    this.columnSortFiltering = this.stateOfComponent?.columnSortFiltering;
    this.isShowPopUpSortFilter = this.stateOfComponent?.isShowPopUpSortFilter;
    this.lastColumnFilter = this.stateOfComponent?.lastColumnFilter;
    this.listFilterDisplay = this.stateOfComponent?.listFilterDisplay;
    this.listFilterDisplayOrigin = this.stateOfComponent?.listFilterDisplayOrigin;
    this.mediaLogPerDevicesFilterDisplay = this.stateOfComponent?.mediaLogPerDevicesFilterDisplay;
    this.isFilter = this.stateOfComponent?.isFilter;
    this.isClear = this.stateOfComponent?.isClear;
    this.listCurrentFilter = this.stateOfComponent?.listCurrentFilter;
    this.listSorted = this.stateOfComponent?.listSorted;
    this.mediaObjectInDB = this.stateOfComponent?.mediaObjectInDB;
    this.mediaTypesToDisplay = this.stateOfComponent?.mediaTypesToDisplay;
    this.isChangedTimeFromContentReport = this.stateOfComponent?.isChangedTimeFromContentReport;
    this.isChangedTimeToContentReport = this.stateOfComponent?.isChangedTimeToContentReport;
    this.hasDataInDeviceMonitorTab = this.stateOfComponent?.hasDataInDeviceMonitorTab;
    this.dateHeaderPeriod = this.stateOfComponent?.dateHeaderPeriod;
    this.dataService.sendData([Constant.DISABLE_BUTTON_DASHBOARD, this.isActiveDeviceMonitorTab]);
    this.callCustomAPICurrentStatus(this.deviceLogs);

    // enable / disable button
    this.dataService.sendData([this.DISABLE_LATEST_MEDIA_LOG, this.isActiveDeviceMonitorTab]);
    this.commonObject.isActiveDeviceMonitorTab = this.isActiveDeviceMonitorTab;
    this.commonObject.isActivePropertiesTab = this.isActivePropertiesTab;
    this.commonObject.isActiveLogTab = this.isActiveLogTab;
    Helper.saveMainStateAction(this.store, this.commonObject);
  }

  /**
   * get all registration ids
   */
  private fetchAllRegistrationIds(): void {
    this.deviceService.getDevice().subscribe(data => {
      this.allDevices = data;
    });
  }

  /**
   * Get data device monitor tab
   */
  private getDataDeviceMonitorTab(): void {
    if (this.hasDataInDeviceMonitorTab) {
      return;
    }
    this.hasDataInDeviceMonitorTab = true;
    this.conditionFilterDeviceLogService.getConditionFilterDeviceLogByUserId(this.userId as number).subscribe(
      dataResponse => {
        // fetch data custom tag
        this.fetchCustomTag(dataResponse);
        if (!dataResponse) {
          return;
        }
        // dataResponse['first'] is condition device
        this.conditionFilterDeviceLog = dataResponse[this.FIRST_ELEMENT];
        this.customTagSubject.subscribe(() => {
          this.setLabelContentCustomTag('customTag01', Constant.LIST_CUSTOM_TAG_ELEMENT_1, Constant.LABEL_1);
          this.setLabelContentCustomTag('customTag02', Constant.LIST_CUSTOM_TAG_ELEMENT_2, Constant.LABEL_2);
          this.setLabelContentCustomTag('customTag03', Constant.LIST_CUSTOM_TAG_ELEMENT_3, Constant.LABEL_3);
        });
        if (!dataResponse[this.SECOND_ELEMENT] || dataResponse[this.SECOND_ELEMENT].length == 0) {
          this.intervalCurrentStatusReport?.unsubscribe();
          this.subscribeCallCustomAPICurrentStatus?.unsubscribe();
          return;
        }
        // dataResponse['second'] is list device searched
        // call api with new device results
        this.callCustomAPICurrentStatus(_.sortBy(dataResponse[this.SECOND_ELEMENT], ['id']));
      },
      () => this.handleShowMessage(Constant.ERROR_TITLE, Constant.COMMON_ERROR)
    );
  }

  /**
   * Handle active tab dashboard
   */
  private handleActiveTabDashboard(): void {
    // get condition active tab in store
    this.isActiveDeviceMonitorTab =
      this.commonObject?.isActiveDeviceMonitorTab === undefined ? true : this.commonObject.isActiveDeviceMonitorTab;
    this.isActiveContentReportTab = !this.isActiveDeviceMonitorTab;
    if (this.isActiveDeviceMonitorTab) {
      this.isActiveLogTab = false;
      this.isActivePropertiesTab = false;
    } else {
      this.isActivePropertiesTab = this.commonObject?.isActivePropertiesTab === undefined ? true : this.commonObject.isActivePropertiesTab;
      this.isActiveLogTab = !this.isActivePropertiesTab;
    }
  }

  /**
   * set label content custom tag on display
   */
  private setLabelContentCustomTag(customTagKey: string, listElementKey: string, labelKey: string): void {
    if (this.conditionFilterDeviceLog?.[customTagKey]) {
      let customTag = this.customTags.find(data => data.id == this.conditionFilterDeviceLog[customTagKey].id);
      if (customTag) {
        let listElement = customTag.elements?.filter(item =>
          this.conditionFilterDeviceLog[listElementKey]?.find(data => data.id == item.id)
        );
        this.conditionFilterDeviceLog[labelKey] =
          listElement?.length == customTag.elements?.length
            ? this.translateService.instant('dashboard.tab-device-monitor.select-all')
            : listElement?.map(element => element.name).join(',');
      }
    }
  }

  /**
   * enable period to display data
   */
  public enablePeriod(): void {
    if (this.validatePeriod()) {
      this.handleShowMessage(Constant.ERROR_TITLE, Constant.INVALID_DATE);
      return;
    }
    // reset
    this.isChangedTimeFromContentReport = false;
    this.isChangedTimeToContentReport = false;
    this.isChangedGrandTotal = false;
    this.isChangedTotal = false;

    // update condition to save
    this.updateConditionToSave();
    // save condition filter media log
    let conditionFilterMediaLogConvert = Helper.convertDataMediaLogToBackend(this.conditionFilterMediaLog);
    this.conditionFilterMediaLogService.saveConditionFilterMediaLog(conditionFilterMediaLogConvert).subscribe(
      data => {
        this.handleDisplayCustomTagByConditionFilterMediaLog(data);
        this.conditionFilterMediaLog[this.OLD_TIME_FROM] = this.conditionFilterMediaLog?.dateFrom;
        this.conditionFilterMediaLog[this.OLD_TIME_TO] = this.conditionFilterMediaLog?.dateTo;
        this.deviceDisplay = [];
        this.filterDeviceByCustomtagAndElement();
        this.setMediaConditionFilterInGUI();
        this.getMediaByExtractCondition();
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * handle display custom tag by condition filter media log
   */
  public handleDisplayCustomTagByConditionFilterMediaLog(data: any): void {
    this.conditionFilterMediaLog = Helper.convertDataFilterMediaLogToFrontEnd(data);
    if (this.conditionFilterMediaLog.condition.customTag) {
      let index = this.customTags.findIndex(customtag => customtag.id == this.conditionFilterMediaLog.condition.customTag.id);
      this.conditionFilterMediaLog.condition.customTag = this.customTags[index];
    }
  }

  /**
   * validate period
   * @returns
   */
  private validatePeriod(): boolean {
    if (!this.timeTo || !this.timeFrom) {
      return true;
    } else if (this.period == PERIOD.DAILY) {
      return new Date(this.timeFromDisplay).getTime() > new Date(this.timeToDisplay).getTime();
    }
    return new Date(this.timeFrom).getTime() > new Date(this.timeTo).getTime();
  }

  /**
   * get media display between dates
   * @param chosenDate
   * @param dateType
   */
  public getMediaDisplayBetweenDates(chosenDate: Date, dateType: boolean): void {
    let from = dateType
      ? moment(chosenDate).format(this.FORMAT_DATE)
      : this.period == PERIOD.DAILY
      ? this.timeFromDisplay
      : this.firstDateOfWeek;
    let to = dateType
      ? this.period == PERIOD.DAILY
        ? this.timeToDisplay
        : this.endDateOfWeek
      : moment(chosenDate).format(this.FORMAT_DATE);
    if (!from || !to || this.mediaLogContents?.length == 0) {
      return;
    }
    // get log media
    this.getLogMediaAllDevices(from, to);
  }

  /**
   * get log media all devices
   *
   * @param fromDate time from
   * @param toDate time to
   */
  public getLogMediaAllDevices(fromDate: string, toDate: string): void {
    const playlistNames = this.getPlaylistNamesToExportLog();
    let offsetHour = 0;
    let offsetMinute = 0;
    let setting = this.commonService.getCommonObject().setting;
    if (setting) {
      offsetHour = setting.timezone.offsetHour;
      offsetMinute = setting.timezone.offsetMinute;
    }
    this.deviceService
      .getLogMediaForDevices(fromDate, toDate, this.conditionFilterMediaLog.condition.deviceIds, playlistNames, offsetHour, offsetMinute)
      .subscribe(
        data => {
          const validDates = this.getListDateToDisplay(data);
          this.dateAndNumberPlays = this.getListDate(
            this.conditionFilterMediaLog.dateFrom,
            this.conditionFilterMediaLog.dateTo,
            validDates
          );
          const mediaLogs = this.updateNumberPlayMediaLog(data);
          this.mediaLogContents.forEach((mediaLog, index) => {
            mediaLog[this.IS_CHANGE_GRAND_TOTAL_PER_MEDIA] = mediaLog.grandTotal != mediaLogs[index]?.grandTotal;
            mediaLog.logPerDay = mediaLogs[index]?.logPerDay;
            mediaLog.grandTotal = mediaLogs[index]?.grandTotal;
            mediaLog.logPlayPerMedias = mediaLogs[index]?.logPlayPerMedias;
            const oldMediaLogPerDevices = _.cloneDeep(mediaLog.mediaLogPerDevices);
            mediaLog.mediaLogPerDevices = mediaLogs.find(data => data?.nameMedia == mediaLog?.nameMedia)?.mediaLogPerDevices;
            if (mediaLog.mediaLogPerDevices) {
              mediaLog.mediaLogPerDevices?.forEach(logData => {
                const oldLogData = oldMediaLogPerDevices?.find(oldLog => oldLog.registrationId == logData.registrationId);
                if (!oldLogData) {
                  return;
                }
                logData[this.IS_CHANGE_TOTAL_PER_DEVICE] = oldLogData.totalPerDevice != logData.totalPerDevice;
              });
            }
          });
          this.isChangedGrandTotal = this.mediaLogContents?.some(data => data[this.IS_CHANGE_GRAND_TOTAL_PER_MEDIA]);
          if (!this.mediaLogContentSelected) {
            return;
          }
          this.fetchDataDeviceToMediaLogContent();
        },
        () => this.handleShowMessage(Constant.ERROR_TITLE, Constant.COMMON_ERROR)
      );
  }

  /**
   * Handle media log detail
   */
  private handleMediaLogDetail(): void {
    this.mediaLogPerDevicesDisplay = _.cloneDeep(this.mediaLogContentSelected?.mediaLogPerDevices);
    this.mediaLogPerDevicesFilterDisplay = _.cloneDeep(this.mediaLogContentSelected?.mediaLogPerDevices);
    let totalPlayPerDays: Array<number> = this.mediaLogContentSelected.logPerDay
      ? new Array<number>(this.mediaLogContentSelected.logPerDay?.length).fill(0)
      : undefined;
    this.subTotalPlayPerDay = { subTotal: 0, totalPlayPerDay: totalPlayPerDays };
  }

  /**
   * Fetch data device to media log content
   */
  private fetchDataDeviceToMediaLogContent(): void {
    this.resetSortFilter();
    const registrationIds = this.mediaLogContentSelected?.mediaLogPerDevices?.map(dataLog => dataLog.registrationId);
    if (!registrationIds || registrationIds.length == 0) {
      this.handleMediaLogDetail();
      return;
    }
    this.deviceService.getDataDisplayByRegistrationIDs(registrationIds).subscribe(dataResponse => {
      if (dataResponse?.length) {
        this.mediaLogContentSelected.mediaLogPerDevices?.forEach((logData, index) => {
          if (index >= dataResponse.length) {
            return;
          }
          // dataResponse[index]['first'] is device id
          logData.deviceId = dataResponse[index][this.FIRST_ELEMENT];
          // dataResponse[index]['second'] is custom tag
          logData.customTag1 = dataResponse[index][this.SECOND_ELEMENT][0];
          logData.customTag2 = dataResponse[index][this.SECOND_ELEMENT][1];
          logData.customTag3 = dataResponse[index][this.SECOND_ELEMENT][2];
        });
        this.mediaLogContentSelected?.logPlayPerMedias?.forEach(logPerMedia => {
          const index = registrationIds?.findIndex(registrationID => registrationID == logPerMedia.registrationId);
          logPerMedia.deviceId = index != -1 ? dataResponse[index][this.FIRST_ELEMENT] : undefined;
        });
        this.mediaLogPerDevicesDisplay = _.cloneDeep(this.mediaLogContentSelected.mediaLogPerDevices);
        this.mediaLogPerDevicesFilterDisplay = _.cloneDeep(this.mediaLogContentSelected.mediaLogPerDevices);
        this.isChangedTotal = this.mediaLogPerDevicesDisplay?.some(item => item[this.IS_CHANGE_TOTAL_PER_DEVICE]);
      }
      this.calculateSubTotalPerMedia();
    });
  }

  /**
   * get log media
   *
   * @param periodFrom
   * @param periodTo
   * @param mediaNameFilters
   * @param isEnablePeriod
   */
  public getLogMedia(periodFrom: string, periodTo: string, mediaNameFilters: Array<string>, isEnablePeriod?: boolean): void {
    const playlistNames = this.getPlaylistNamesToExportLog();
    let offsetHour = 0;
    let offsetMinute = 0;
    let setting = this.commonService.getCommonObject().setting;
    if (setting) {
      offsetHour = setting.timezone.offsetHour;
      offsetMinute = setting.timezone.offsetMinute;
    }
    this.deviceService
      .getLogMediaForDevices(
        periodFrom,
        periodTo,
        this.conditionFilterMediaLog.condition.deviceIds,
        playlistNames,
        offsetHour,
        offsetMinute
      )
      .subscribe(async data => {
        this.mediaNamesToDisplay = [...new Set(await this.getMediaNames(mediaNameFilters, data as MediaLog[]).then())] as Array<string>;
        this.mediaNamesToDisplay.sort();
        const validDates = this.getListDateToDisplay(data);
        this.dateAndNumberPlays = this.getListDate(periodFrom, periodTo, validDates);
        this.mediaLogContents = this.updateNumberPlayMediaLog(data);
        if (isEnablePeriod) {
          this.dateHeaderPeriod = this.period;
          this.mediaLogContentSelected = undefined;
          this.mediaTabProperties = undefined;
        }
      });
  }

  /**
   * Get playlist names to export log
   *
   * @returns
   */
  private getPlaylistNamesToExportLog(): string[] {
    return this.conditionFilterMediaLog?.playlistNames?.length
      ? this.conditionFilterMediaLog.playlistNames.includes(this.translateService.instant('dashboard.tab-content-report.select-all'))
        ? [this.SELECT_ALL]
        : this.conditionFilterMediaLog.playlistNames
      : [];
  }

  /**
   * Get list date to display
   *
   * @param data
   * @returns
   */
  private getListDateToDisplay(data: MediaLog[]): Array<string> {
    let listDates = new Array<string>();
    const playLogs = data.map(item => item.playLog);
    playLogs.forEach(playLog => {
      const dates = playLog.map(it => it?.date?.substring(0, it?.date?.indexOf(' ')));
      listDates.push(...dates);
    });
    return [...new Set(listDates)].filter(date => moment(date).isValid()).sort();
  }

  /**
   * get all devices
   */
  public getAllDevices(): void {
    this.deviceService.getDevice().subscribe(data => {
      if (!data) {
        return;
      }
      this.devices = _.sortBy(data, ['id']);
    });
  }

  /**
   * get media names
   *
   * @param mediaNameFilters
   * @param data
   * @returns
   */
  private async getMediaNames(mediaNameFilters: any, data: any): Promise<string> {
    let medias = new Array<string>();
    let mediaNames = new Array<string>();
    data.forEach(mediaLog => {
      mediaLog.playLog.forEach(mediaLogEntities => {
        if (!mediaLogEntities.media) {
          return;
        }
        let mediaName = mediaLogEntities.media;
        if (mediaName && !medias.includes(mediaName)) {
          medias.push(mediaName);
        }
      });
    });
    await new Promise<void>(resolve => {
      this.simpleMediaService.getSimpleMediaNameInLog(medias).subscribe(
        simpleMedias => {
          if (simpleMedias.length == 0) {
            resolve();
            return;
          }
          simpleMedias.forEach(simpleMedia => {
            if (simpleMedia.id) {
              mediaNames.push(`${simpleMedia.name.substring(0, simpleMedia.name.lastIndexOf(this.MEDIA_KEY_SPLIT))}.${simpleMedia.type}`);
              this.mediaObjectInDB.push(Helper.convertDataSimpleMedia(simpleMedia, false));
            } else {
              mediaNames.push(simpleMedia.name);
            }
          });
          resolve();
        },
        () => {
          this.handleShowMessage(Constant.ERROR_TITLE, Constant.COMMON_ERROR);
          resolve();
        }
      );
    });
    return mediaNameFilters ? mediaNameFilters.filter(item => mediaNames.includes(item)) : mediaNames;
  }
  /**
   * get list dates
   *
   * @param validDates
   * @param periodFrom
   * @param periodTo
   * @returns
   */
  private getListDate(periodFrom: string, periodTo: string, validDates: Array<string>): Array<NumberPlayPerDay> {
    if (validDates.length == 0) {
      return new Array<NumberPlayPerDay>();
    }
    var numberPlayPerDates = new Array<NumberPlayPerDay>();
    const firstDate = periodFrom ?? validDates[0];
    const secondDate = periodTo ?? validDates[validDates.length - 1];
    if (firstDate && secondDate) {
      var endDate = new Date(secondDate);
      var startDate = new Date(firstDate);
      switch (this.period) {
        case PERIOD.DAILY:
          while (startDate <= endDate) {
            numberPlayPerDates.push({
              date: moment(startDate).format(this.FORMAT_DATE),
              numberPlay: 0,
              year: startDate.getFullYear()
            });
            startDate.setDate(startDate.getDate() + 1);
          }
          break;
        case PERIOD.WEEKLY:
          let weekFrom = +(moment(firstDate, this.FORMAT_DATE).isoWeeks() + '');
          let weekTo = +(moment(secondDate, this.FORMAT_DATE).isoWeeks() + '');
          // start year and end year is equals
          if (startDate.getFullYear() == endDate.getFullYear()) {
            while (weekFrom <= weekTo) {
              numberPlayPerDates.push({
                date: `${weekFrom}`.padStart(2, '0'),
                numberPlay: 0,
                year: startDate.getFullYear()
              });
              weekFrom++;
            }
          } else {
            // start year and end year is not equals
            // get week for first year
            let lastWeekOfYear = this.getLastWeekOfYear(firstDate);
            while (weekFrom <= lastWeekOfYear) {
              numberPlayPerDates.push({
                date: `${weekFrom}`.padStart(2, '0'),
                numberPlay: 0,
                year: startDate.getFullYear()
              });
              weekFrom++;
            }
            // get week for next year
            for (let index = startDate.getFullYear() + 1; index < endDate.getFullYear(); index++) {
              let firstWeek = 1;
              let lastWeekOfYear = this.getLastWeekOfYear(`${index}`);
              while (firstWeek <= lastWeekOfYear) {
                numberPlayPerDates.push({
                  date: `${firstWeek}`.padStart(2, '0'),
                  numberPlay: 0,
                  year: index
                });
                firstWeek++;
              }
            }
            // get week for last year
            let firstWeek = 1;
            while (
              firstWeek <= weekTo &&
              (weekTo != this.LAST_WEEK_OF_YEAR || (weekTo == this.LAST_WEEK_OF_YEAR && endDate.getMonth() + 2 > this.LAST_MONTH_OF_YEAR))
            ) {
              numberPlayPerDates.push({
                date: `${firstWeek}`.padStart(2, '0'),
                numberPlay: 0,
                year: endDate.getFullYear()
              });
              firstWeek++;
            }
          }
          break;
        case PERIOD.MONTHLY:
          let monthFrom = +(moment(firstDate, this.FORMAT_DATE).month() + 1 + '').padStart(2, '0');
          let monthTo = +(moment(secondDate, this.FORMAT_DATE).month() + 1 + '').padStart(2, '0');
          // start year and end year is equals
          if (startDate.getFullYear() == endDate.getFullYear()) {
            while (monthFrom <= monthTo) {
              numberPlayPerDates.push({
                date: `${monthFrom}`.padStart(2, '0'),
                numberPlay: 0,
                year: startDate.getFullYear()
              });
              monthFrom++;
            }
          } else {
            // start year and end year is not equals
            // get month for first year
            let lastMonthOfYear = this.LAST_MONTH_OF_YEAR;
            while (monthFrom <= lastMonthOfYear) {
              numberPlayPerDates.push({
                date: `${monthFrom}`.padStart(2, '0'),
                numberPlay: 0,
                year: startDate.getFullYear()
              });
              monthFrom++;
            }
            // get month for next year
            for (let index = startDate.getFullYear() + 1; index < endDate.getFullYear(); index++) {
              let firstMonth = 1;
              let lastMonth = this.LAST_MONTH_OF_YEAR;
              while (firstMonth <= lastMonth) {
                numberPlayPerDates.push({
                  date: `${firstMonth}`.padStart(2, '0'),
                  numberPlay: 0,
                  year: index
                });
                firstMonth++;
              }
            }
            // get month for last year
            let firstMonth = 1;
            while (firstMonth <= monthTo) {
              numberPlayPerDates.push({
                date: `${firstMonth}`.padStart(2, '0'),
                numberPlay: 0,
                year: endDate.getFullYear()
              });
              firstMonth++;
            }
          }
          break;
        default:
          break;
      }
    }
    return numberPlayPerDates;
  }

  /**
   * Get last week of year
   *
   * @param value
   * @returns
   */
  private getLastWeekOfYear(value: string): number {
    let lastDayOfYear = moment(value)
      .endOf('year')
      .format(this.FORMAT_DATE);
    return +(moment(lastDayOfYear).isoWeeks() + '');
  }

  /**
   * update number play of media
   *
   * @param data
   * @returns
   */
  private updateNumberPlayMediaLog(data: MediaLog[]): Array<MediaLogContent> {
    if (!this.mediaNamesToDisplay) {
      return [];
    }

    var results = new Array<MediaLogContent>();
    this.mediaNamesToDisplay.forEach(media => {
      var mediaLogPerDevices = new Array<MediaLogPerDevice>();
      var logPlayPerMedias = new Array<LogPerMedia>();
      var total = 0;
      var allPlayedDates = new Array<string>();
      data.forEach(mediaLog => {
        var datePlaysPerDevice = new Array<string>();
        var totalPerDevice = 0;
        mediaLog.playLog.forEach(mediaLogEntities => {
          const index = mediaLogEntities.media?.indexOf('.');
          let mediaName;
          if (index && index != -1) {
            mediaName = this.getMediaNameByPlayLogType(mediaLogEntities);
          } else {
            mediaName = mediaLogEntities.media;
          }
          if (mediaName == media) {
            total += 1;
            totalPerDevice += 1;
            allPlayedDates.push(mediaLogEntities.date.split(' ')[0]);
            datePlaysPerDevice.push(mediaLogEntities.date.split(' ')[0]);
          }
        });
        var playLogByMediasPerDevice = _.cloneDeep(this.dateAndNumberPlays);
        datePlaysPerDevice.forEach(datePlayed => {
          playLogByMediasPerDevice.forEach(playLogByMedia => {
            if (this.isSatisfiedDate(playLogByMedia, datePlayed)) {
              playLogByMedia.numberPlay += 1;
            }
          });
        });
        mediaLogPerDevices.push(new MediaLogPerDevice(mediaLog.registrationId, totalPerDevice, playLogByMediasPerDevice));
        // get log per media
        mediaLog?.playLog?.forEach(playLog => {
          const index = playLog.media?.indexOf('.');
          let mediaName;
          if (index && index != -1) {
            mediaName = this.getMediaNameByPlayLogType(playLog);
          } else {
            mediaName = playLog.media;
          }
          if (mediaName != media) {
            return;
          }
          logPlayPerMedias.push(new LogPerMedia(playLog.date, mediaLog.registrationId));
        });
        logPlayPerMedias = logPlayPerMedias?.sort(function(log1, log2) {
          return new Date(log1.time).getTime() - new Date(log2.time).getTime();
        });
      });
      var playLogByMedias = _.cloneDeep(this.dateAndNumberPlays);
      allPlayedDates.forEach(datePlayed => {
        playLogByMedias.forEach(playLogByMedia => {
          if (this.isSatisfiedDate(playLogByMedia, datePlayed)) {
            playLogByMedia.numberPlay += 1;
          }
        });
      });
      let isRemoveMediaLog = false;
      for (let i = 0; i < mediaLogPerDevices.length; i++) {
        if (isRemoveMediaLog) {
          i--;
        }
        if (mediaLogPerDevices[i].registrationId == mediaLogPerDevices[i + 1]?.registrationId) {
          mediaLogPerDevices[i].totalPerDevice += mediaLogPerDevices[i + 1]?.totalPerDevice;
          mediaLogPerDevices[i + 1]?.logPerDayOfDevice.forEach((logPerDay, index) => {
            mediaLogPerDevices[i].logPerDayOfDevice[index].numberPlay += logPerDay.numberPlay;
          });
          mediaLogPerDevices.splice(i + 1, 1);
        }
        isRemoveMediaLog = mediaLogPerDevices[i]?.registrationId == mediaLogPerDevices[i + 1]?.registrationId;
      }
      results.push({
        grandTotal: total,
        nameMedia: media,
        logPerDay: playLogByMedias,
        mediaLogPerDevices: mediaLogPerDevices?.filter(mediaLog =>
          mediaLog?.logPerDayOfDevice?.some(logPerDay => logPerDay?.numberPlay != 0)
        ),
        logPlayPerMedias: logPlayPerMedias,
        oldLogPlayPerMedias: _.cloneDeep(logPlayPerMedias)
      });
    });
    return results;
  }

  /**
   * True if date per day equals date in log
   *
   * @param datePlay
   * @param datePerDay
   * @returns
   */
  private isSatisfiedDate(datePlay: NumberPlayPerDay, datePerDay: any): boolean {
    let date = datePerDay.replaceAll('/', '-');
    const month = moment(date, this.FORMAT_DATE).month();
    const year = moment(date, this.FORMAT_DATE).year();
    switch (this.period) {
      case PERIOD.DAILY:
        return date == datePlay.date;
      case PERIOD.WEEKLY:
        let weeklyDate = (moment(date, this.FORMAT_DATE).isoWeeks() + '').padStart(2, '0');
        const lastWeekOfYear = this.LAST_WEEK_OF_YEAR.toString();
        return (
          weeklyDate == datePlay.date &&
          ((datePlay.year == year &&
            (weeklyDate != lastWeekOfYear || (weeklyDate == lastWeekOfYear && month + 2 > this.LAST_MONTH_OF_YEAR))) ||
            (datePlay.year < year && weeklyDate == lastWeekOfYear && month < this.FIRST_MONTH_OF_YEAR))
        );

      case PERIOD.MONTHLY:
        let monthDate = (month + 1 + '').padStart(2, '0');
        return monthDate == datePlay.date && datePlay.year == year;
      default:
        break;
    }
    return false;
  }

  /**
   * Get media name by play log type
   *
   * @param mediaLogEntities
   * @returns
   */
  private getMediaNameByPlayLogType(mediaLogEntities: MediaLogEntities): string {
    let mediaName = mediaLogEntities.media;
    if (mediaName) {
      mediaName = `${mediaName.substring(0, mediaName.lastIndexOf(this.MEDIA_KEY_SPLIT))}${mediaName.substring(
        mediaName.lastIndexOf(this.MEDIA_EXTENSIONS_KEY_SPLIT)
      )}`;
    }
    return mediaName;
  }

  /**
   * call api current status
   *
   * @param devices
   * @returns
   */
  private callCustomAPICurrentStatus(devices: any): void {
    if (!devices || !devices.length || !this.isActiveDeviceMonitorTab) {
      return;
    }
    const timeOut = interval(this.settingDeviceMonitorAutoRefresh?.timeAutoRefresh * 1000);
    this.intervalCurrentStatusReport?.unsubscribe();
    let registrationIds: string[] = devices?.map(device => device[this.REGISTRATION_ID_ELEMENT]);
    // call api
    this.subscribeCallCustomAPICurrentStatus = this.apiCustomerService.getCurrentStatusRequest(registrationIds).subscribe(
      data => {
        this.deviceLogs = [];
        for (let i = 0; i < devices?.length; i++) {
          let deviceResponseAPICompleted = data[Constant.COMPLETE_STATUS]?.find(
            deviceResponse => devices[i].registrationId === deviceResponse.id
          );
          let deviceLog = new DeviceLog();
          // Check network of device
          if (deviceResponseAPICompleted) {
            deviceLog = Helper.convertDataDeviceLog(deviceResponseAPICompleted, devices[i], this.languageKey);
            deviceLog[Constant.NETWORK_STATUS] = true;
          } else {
            deviceLog = Helper.convertDataDeviceLog(undefined, devices[i], this.languageKey);
            deviceLog[Constant.NETWORK_STATUS] = false;
          }
          deviceLog.id = devices[i].id;
          this.deviceLogs.push(deviceLog);
        }
        this.deviceLogs = _.sortBy(this.deviceLogs, ['id']);
        // select device log first time or get information of device
        if (!this.deviceLogSelected && this.deviceLogs.length > 0) {
          this.selectDevice(this.deviceLogs[0]);
        }
        this.deviceLogSelected = this.deviceLogs.find(e => e.id == this.deviceLogSelected.id);
        this.isCheckedAll = false;
        // if not auto refresh
        if (!this.settingDeviceMonitorAutoRefresh?.autoRefresh || this.deviceLogs.length == 0) {
          return;
        }
        // auto refresh with timeAutoRefresh
        this.intervalCurrentStatusReport = timeOut
          .pipe(
            timeInterval(),
            takeUntil(this.pauseCallCurrentStatusSubject),
            repeatWhen(() => this.startCallCurrentStatusSubject)
          )
          .subscribe(() => {
            this.callCustomAPICurrentStatus(devices);
          });
      },
      () => {
        if (this.deviceLogs.length > 0) {
          this.intervalCurrentStatusReport = timeOut
            .pipe(
              timeInterval(),
              takeUntil(this.pauseCallCurrentStatusSubject),
              repeatWhen(() => this.startCallCurrentStatusSubject)
            )
            .subscribe(() => {
              this.callCustomAPICurrentStatus(devices);
            });
          this.handleDataDeviceLogsWhenNetworkNG();
        }
      }
    );
  }

  /**
   * Handle data device logs when network NG
   */
  private handleDataDeviceLogsWhenNetworkNG(): void {
    this.deviceLogs.forEach(deviceLog => {
      deviceLog.network = false;
      deviceLog.systemTime = undefined;
      deviceLog.firmwareVersion = undefined;
      deviceLog.deviceScreenCaptureUrl = undefined;
      deviceLog.shutdownTime = undefined;
    });
  }

  /**
   * open popup extract setting data
   */
  public extractSetting(): void {
    // if api is calling auto refresh
    if (this.isActiveDeviceMonitorTab && this.settingDeviceMonitorAutoRefresh?.autoRefresh) {
      this.pauseCallCurrentStatusSubject.next();
    }
    this.dialogService.showDialog(
      DialogExtractSettingComponent,
      {
        data: {
          isDeviceMonitor: this.isActiveDeviceMonitorTab,
          conditionFilterLog: this.isActiveDeviceMonitorTab
            ? _.cloneDeep(this.conditionFilterDeviceLog)
            : _.cloneDeep(this.conditionFilterMediaLog),
          customTags: this.customTags,
          userId: this.userId,
          devices: this.devices
        }
      },
      result => {
        if (result) {
          if (this.isActiveDeviceMonitorTab) {
            let dataResponse = result;
            this.conditionFilterDeviceLog = dataResponse[this.FIRST_ELEMENT];
            // dataResponse['first'] is condition device log
            this.setLabelContentCustomTag('customTag01', Constant.LIST_CUSTOM_TAG_ELEMENT_1, Constant.LABEL_1);
            this.setLabelContentCustomTag('customTag02', Constant.LIST_CUSTOM_TAG_ELEMENT_2, Constant.LABEL_2);
            this.setLabelContentCustomTag('customTag03', Constant.LIST_CUSTOM_TAG_ELEMENT_3, Constant.LABEL_3);
            this.resetTabDeviceMonitor();
            if (!dataResponse[this.SECOND_ELEMENT] || dataResponse[this.SECOND_ELEMENT].length == 0) {
              this.intervalCurrentStatusReport?.unsubscribe();
              this.subscribeCallCustomAPICurrentStatus?.unsubscribe();
              return;
            }
            // dataResponse['second'] is list device searched
            // call api with new device results
            this.callCustomAPICurrentStatus(_.sortBy(dataResponse[this.SECOND_ELEMENT], ['id']));
          } else {
            this.conditionFilterMediaLog = result;
            if (
              this.conditionFilterMediaLog.condition &&
              this.conditionFilterMediaLog.condition.customTag &&
              this.conditionFilterMediaLog.condition.customTagElementId
            ) {
              const index = this.conditionFilterMediaLog.condition.customTag?.elements.findIndex(
                element => element.id == this.conditionFilterMediaLog.condition.customTagElementId
              );
              this.customTagElementName = this.conditionFilterMediaLog.condition.customTag?.elements[index].name;
            }
            this.setMediaConditionFilterInGUI();
            this.mediaTypesToDisplay = Helper.handleMediaTypesToDisplay(
              this.conditionFilterMediaLog.condition.mediaTypes,
              this.translateService
            );
            this.conditionFilterMediaLog[this.OLD_TIME_FROM] = this.conditionFilterMediaLog?.dateFrom;
            this.conditionFilterMediaLog[this.OLD_TIME_TO] = this.conditionFilterMediaLog?.dateTo;
            this.isChangedTimeFromContentReport = false;
            this.isChangedTimeToContentReport = false;
            this.getMediaByExtractCondition();
          }
        } else if (this.isActiveDeviceMonitorTab) {
          this.startCallCurrentStatusSubject.next();
        }
      }
    );
  }

  /**
   * select media
   *
   * @param mediaLogContent
   */
  public selectMedia(mediaLogContent: MediaLogContent): void {
    if (this.mediaLogContentSelected?.nameMedia == mediaLogContent.nameMedia) {
      return;
    }
    this.mediaTabProperties = undefined;
    this.resetSortFilter();
    this.mediaLogContentSelected = mediaLogContent;
    this.mediaLogContentSelected.logPlayPerMedias = _.cloneDeep(this.mediaLogContentSelected.oldLogPlayPerMedias);
    const registrationIds = this.mediaLogContentSelected?.mediaLogPerDevices?.map(dataLog => dataLog.registrationId);
    if (registrationIds?.length > 0) {
      this.deviceService.getDataDisplayByRegistrationIDs(registrationIds).subscribe(dataResponse => {
        if (dataResponse?.length) {
          this.mediaLogContentSelected?.mediaLogPerDevices?.forEach((logData, index) => {
            if (index >= dataResponse.length) {
              return;
            }
            // dataResponse[index]['first'] is device id
            logData.deviceId = dataResponse[index][this.FIRST_ELEMENT];
            // dataResponse[index]['second'] is custom tag
            logData.customTag1 = dataResponse[index][this.SECOND_ELEMENT][0];
            logData.customTag2 = dataResponse[index][this.SECOND_ELEMENT][1];
            logData.customTag3 = dataResponse[index][this.SECOND_ELEMENT][2];
          });
          this.mediaLogPerDevicesDisplay = _.cloneDeep(this.mediaLogContentSelected?.mediaLogPerDevices);
          this.mediaLogPerDevicesFilterDisplay = _.cloneDeep(this.mediaLogContentSelected?.mediaLogPerDevices);
          this.mediaLogContentSelected?.logPlayPerMedias?.forEach(logPerMedia => {
            const index = registrationIds?.findIndex(registrationID => registrationID == logPerMedia.registrationId);
            logPerMedia.deviceId = index != -1 ? dataResponse[index][this.FIRST_ELEMENT] : undefined;
          });
        }
        this.calculateSubTotalPerMedia();
      });
    } else {
      this.handleMediaLogDetail();
    }
    const fullNameMedia = this.mediaLogContentSelected.nameMedia;
    let name = fullNameMedia.substring(
      fullNameMedia.lastIndexOf(this.URL_KEY_SPLIT) + 1,
      fullNameMedia.lastIndexOf(this.MEDIA_EXTENSIONS_KEY_SPLIT)
    );
    const mediaName = this.mediaObjectInDB.find(media => media.name.substring(0, media.name.lastIndexOf(this.MEDIA_KEY_SPLIT)) == name)
      ?.name;
    if (!mediaName) {
      return;
    }
    const mediaType = fullNameMedia.substring(fullNameMedia.lastIndexOf(this.MEDIA_EXTENSIONS_KEY_SPLIT) + 1);
    const FIRST_INDEX = 0;
    this.simpleMediaService.getMediaByNameAndType(mediaName, mediaType).subscribe(
      simpleMedias => {
        if (simpleMedias.length == 0) {
          this.mediaTabProperties = undefined;
          return;
        }
        this.mediaTabProperties = Helper.convertDataSimpleMedia(simpleMedias[FIRST_INDEX], false);
      },
      () => this.handleShowMessage(Constant.ERROR_TITLE, Constant.COMMON_ERROR)
    );
    this.isChangedTotal = this.mediaLogContentSelected?.mediaLogPerDevices?.some(item => item[this.IS_CHANGE_TOTAL_PER_DEVICE]);
  }

  /**
   * fetch data custom tag
   * @param deviceMonitorSetting
   */
  private fetchCustomTag(deviceMonitorSetting?: any): void {
    this.customTagService.getCustomTag().subscribe(
      customTagData => {
        this.customTags = customTagData;
        this.customTagSubject.next();
        if (!deviceMonitorSetting) {
          this.setConditionFilterDeviceLogDefault();
        }
        this.columns = [];
        for (let i = 0; i < this.customTags?.length; i++) {
          this.columns.push({
            headerName: this.customTags[i].name,
            property: `customTag${this.customTags[i].indexCustom + 1}`,
            isSortBy: '',
            isFilterBy: ''
          });
        }
      },
      () => this.handleShowMessage(Constant.ERROR_TITLE, Constant.COMMON_ERROR)
    );
  }

  /**
   * update config for picker
   *
   * @param period
   */
  private updateConfig(period: number): void {
    this.config = {
      showWeekNumbers: period == PERIOD.WEEKLY,
      format: this.FORMAT_DATE,
      firstDayOfWeek: 'mo',
      unSelectOnClick: false,
      locale: Helper.getLocale(this.languageKey)
    };
  }

  /**
   * event when change period mode
   */
  public changePeriodMode(): void {
    const originalDateFrom = this.conditionFilterMediaLog.dateFrom;
    const originalDateTo = this.conditionFilterMediaLog.dateTo;
    // update config date
    setTimeout(() => {
      this.updateConfig(this.period);
    });
    // change period monthly -> daily | weekly
    if (this.isOldPeriodMonth && this.period != PERIOD.MONTHLY) {
      const firstDay = this.firstDateOfWeek ? moment(this.firstDateOfWeek, this.FORMAT_DATE) : undefined;
      const endDay = this.endDateOfWeek ? moment(this.endDateOfWeek, this.FORMAT_DATE) : undefined;
      this.timeFrom = !firstDay
        ? undefined
        : this.period == PERIOD.DAILY
        ? moment(firstDay).format(this.FORMAT_DATE)
        : moment(firstDay.startOf('isoWeek').toString()).format(this.FORMAT_DATE);
      this.timeTo = !endDay
        ? undefined
        : this.period == PERIOD.DAILY
        ? moment(endDay).format(this.FORMAT_DATE)
        : moment(endDay.endOf('isoWeek').toString()).format(this.FORMAT_DATE);
    }
    this.isOldPeriodMonth = this.period == PERIOD.MONTHLY;
    this.getStartDate(this.period);
    this.getEndDate(this.period);
    // get time display
    this.getTimeDisplay(this.period);

    this.conditionFilterMediaLog.dateFrom = this.period == PERIOD.DAILY ? this.timeFromDisplay : this.firstDateOfWeek;
    this.conditionFilterMediaLog.dateTo = this.period == PERIOD.DAILY ? this.timeToDisplay : this.endDateOfWeek;

    const isChangedFrom = originalDateFrom != this.conditionFilterMediaLog.dateFrom;
    const isChangedTo = originalDateTo != this.conditionFilterMediaLog.dateTo;

    this.isChangedTimeFromContentReport = this.isChangedTimeFromContentReport ? this.isChangedTimeFromContentReport : isChangedFrom;
    this.isChangedTimeToContentReport = this.isChangedTimeToContentReport ? this.isChangedTimeToContentReport : isChangedTo;
  }

  /**
   * choose date from
   *
   * @param period
   * @returns
   */
  public chooseDateFrom(period: number): void {
    const oldTimeFrom = this.period == PERIOD.DAILY ? this.timeFromDisplay : this.firstDateOfWeek;
    const originalDateFrom = this.conditionFilterMediaLog?.dateFrom;
    if (this.timeFrom == originalDateFrom) {
      return;
    }
    switch (period) {
      case PERIOD.DAILY:
        this.timeFromDisplay = this.timeFrom;
        break;
      case PERIOD.WEEKLY:
        this.timeFromDisplay = (moment(this.timeFrom, this.FORMAT_DATE).isoWeeks() + '').padStart(2, '0');
        break;
      case PERIOD.MONTHLY:
        this.timeFromDisplay = (moment(this.timeFrom, this.FORMAT_DATE).month() + 1 + '').padStart(2, '0');
        break;
      default:
        break;
    }
    this.getStartDate(period);
    this.getTimeDisplay(period);
    this.conditionFilterMediaLog.dateFrom = this.period == PERIOD.DAILY ? this.timeFromDisplay : this.firstDateOfWeek;
    this.isChangedTimeFromContentReport = oldTimeFrom && this.timeFrom && originalDateFrom != this.conditionFilterMediaLog?.dateFrom;
  }

  /**
   * choose date to
   *
   * @param period
   * @returns
   */
  public chooseDateTo(period: number): void {
    const oldTimeTo = this.period == PERIOD.DAILY ? this.timeToDisplay : this.endDateOfWeek;
    const originalDateTo = this.conditionFilterMediaLog?.dateTo;
    if (this.timeTo == originalDateTo) {
      return;
    }
    switch (period) {
      case PERIOD.DAILY:
        this.timeToDisplay = this.timeTo;
        break;
      case PERIOD.WEEKLY:
        this.timeToDisplay = (moment(this.timeTo, this.FORMAT_DATE).isoWeeks() + '').padStart(2, '0');
        break;
      case PERIOD.MONTHLY:
        this.timeToDisplay = (moment(this.timeTo, this.FORMAT_DATE).month() + 1 + '').padStart(2, '0');
        break;
      default:
        break;
    }
    this.getEndDate(period);
    this.getTimeDisplay(period);
    this.conditionFilterMediaLog.dateTo = this.period == PERIOD.DAILY ? this.timeToDisplay : this.endDateOfWeek;
    this.isChangedTimeToContentReport = oldTimeTo && this.timeTo && originalDateTo != this.conditionFilterMediaLog?.dateTo;
  }

  /**
   * get time view to display
   * @param period
   */
  public getTimeDisplay(period: number): void {
    switch (period) {
      case PERIOD.DAILY:
        this.timeFromDisplay = this.timeFrom ?? undefined;
        this.timeToDisplay = this.timeTo ?? undefined;
        break;
      case PERIOD.WEEKLY:
        this.timeFromDisplay = this.firstDateOfWeek
          ? (moment(this.firstDateOfWeek, this.FORMAT_DATE).isoWeeks() + '').padStart(2, '0')
          : undefined;
        this.timeToDisplay = this.endDateOfWeek
          ? (moment(this.endDateOfWeek, this.FORMAT_DATE).isoWeeks() + '').padStart(2, '0')
          : undefined;
        break;
      case PERIOD.MONTHLY:
        this.timeFromDisplay = this.firstDateOfWeek
          ? (moment(this.firstDateOfWeek, this.FORMAT_DATE).month() + 1 + '').padStart(2, '0')
          : undefined;
        this.timeToDisplay = this.endDateOfWeek
          ? (moment(this.endDateOfWeek, this.FORMAT_DATE).month() + 1 + '').padStart(2, '0')
          : undefined;
        break;
      default:
        break;
    }
  }

  /**
   * get start date of week or month
   * @param period
   */
  public getStartDate(period: number): void {
    if (period == PERIOD.DAILY || !this.timeFrom) {
      return;
    }
    const day = moment(this.timeFrom, this.FORMAT_DATE);
    const firstDate = period == PERIOD.WEEKLY ? day.startOf('isoWeek').toString() : day.startOf('month').toString();
    this.timeFrom = moment(firstDate).format(this.FORMAT_DATE);
    this.firstDateOfWeek = this.timeFrom;
  }

  /**
   * get end date of week or month
   * @param period
   */
  public getEndDate(period: number): void {
    if (period == PERIOD.DAILY || !this.timeTo) {
      return;
    }
    const day = moment(this.timeTo, this.FORMAT_DATE);
    const endDate = period == PERIOD.WEEKLY ? day.endOf('isoWeek').toString() : day.endOf('month').toString();
    this.timeTo = moment(endDate).format(this.FORMAT_DATE);
    this.endDateOfWeek = this.timeTo;
  }

  /**
   * get time when on th screen
   */
  private getTimeStart(): void {
    this.timeFrom = this.conditionFilterMediaLog.dateFrom;
    this.timeTo = this.conditionFilterMediaLog.dateTo;
    this.getStartDate(this.period);
    this.getEndDate(this.period);
    this.getTimeDisplay(this.period);
  }

  /**
   * update condition to save
   */
  private updateConditionToSave(): void {
    this.conditionFilterMediaLog.period = this.period;
  }

  /**
   * reset time period
   */
  private resetTimePeriod(): void {
    this.timeFrom = undefined;
    this.timeTo = undefined;
    this.timeFromDisplay = undefined;
    this.timeToDisplay = undefined;
    this.firstDateOfWeek = undefined;
    this.endDateOfWeek = undefined;
  }

  /**
   * choose tab Device Monitor / Content Report / Properties / Log
   *
   * @param tabName
   */
  public chooseTabDashboard(tabName: DASHBOARD_TAB_NAME): void {
    switch (tabName) {
      case DASHBOARD_TAB_NAME.DEVICE_MONITOR:
        this.isActiveDeviceMonitorTab = true;
        this.isActiveLogTab = false;
        this.commonObject.isActiveLogTab = this.isActiveLogTab;
        this.commonObject.isActiveDeviceMonitorTab = this.isActiveDeviceMonitorTab;
        this.isActiveContentReportTab = false;
        this.isActivePropertiesTab = false;
        this.commonObject.isActivePropertiesTab = this.isActivePropertiesTab;
        this.dataService.sendData([this.DISABLE_LATEST_MEDIA_LOG, true]);
        if (this.deviceLogSelected?.deviceScreenCaptureUrl) {
          this.deviceLogSelected.deviceScreenCaptureUrl = '';
        }
        if (this.settingDeviceMonitorAutoRefresh?.autoRefresh && this.hasDataInDeviceMonitorTab) {
          this.subscribeCallCustomAPICurrentStatus?.unsubscribe();
          this.callCustomAPICurrentStatus(_.sortBy(this.deviceLogs, ['id']));
        }
        this.getDataDeviceMonitorTab();
        break;
      case DASHBOARD_TAB_NAME.CONTENT_REPORT:
        this.pauseCallCurrentStatusSubject.next();
        this.isActiveContentReportTab = true;
        this.isActivePropertiesTab = true;
        this.commonObject.isActivePropertiesTab = this.isActivePropertiesTab;
        this.isActiveDeviceMonitorTab = false;
        this.isActiveLogTab = false;
        this.commonObject.isActiveDeviceMonitorTab = this.isActiveDeviceMonitorTab;
        this.mediaLogContents = [];
        this.dateAndNumberPlays = undefined;
        this.mediaLogContentSelected = undefined;
        this.mediaTabProperties = undefined;
        this.lastTimeUpdate = null;
        this.timeByImg = null;
        this.dataService.sendData([this.DISABLE_LATEST_MEDIA_LOG, false]);
        this.fetchMediaDisplay();
        break;
      case DASHBOARD_TAB_NAME.PROPERTIES:
        this.isActivePropertiesTab = true;
        this.commonObject.isActivePropertiesTab = this.isActivePropertiesTab;
        this.isActiveLogTab = false;
        this.commonObject.isActiveLogTab = this.isActiveLogTab;
        break;
      case DASHBOARD_TAB_NAME.LOG:
        this.isActiveLogTab = true;
        this.isActivePropertiesTab = false;
        this.commonObject.isActiveLogTab = this.isActiveLogTab;
        this.commonObject.isActivePropertiesTab = this.isActivePropertiesTab;
        break;
      case DASHBOARD_TAB_NAME.SCREEN_CAPTURE:
        this.isActiveLogTab = false;
        this.isActivePropertiesTab = false;
        this.commonObject.isActiveLogTab = this.isActiveLogTab;
        this.commonObject.isActivePropertiesTab = this.isActivePropertiesTab;
        break;
      default:
        break;
    }
    Helper.saveMainStateAction(this.store, this.commonObject);
    this.dataService.sendData([Constant.DISABLE_BUTTON_DASHBOARD, tabName == DASHBOARD_TAB_NAME.DEVICE_MONITOR]);
  }

  /**
   * fetch media first time
   */
  private fetchMediaDisplay(): void {
    if (!this.userId) {
      return;
    }
    this.conditionFilterMediaLogService.getConditionFilterMediaLogByUserId(this.userId as number).subscribe(
      data => {
        if (!data) {
          this.conditionFilterMediaLog = new ConditionFilterMediaLog(this.userId as number, this.period);
          this.setConditionFilterMediaLogDefault();
          return;
        }
        this.handleDisplayCustomTagByConditionFilterMediaLog(data);
        if (
          this.conditionFilterMediaLog.condition &&
          this.conditionFilterMediaLog.condition.customTag &&
          this.conditionFilterMediaLog.condition.customTagElementId
        ) {
          const index = this.conditionFilterMediaLog.condition.customTag?.elements.findIndex(
            element => element.id == this.conditionFilterMediaLog.condition.customTagElementId
          );
          this.customTagElementName = this.conditionFilterMediaLog.condition.customTag?.elements[index].name;
        }
        this.filterDeviceByCustomtagAndElement();
        this.setMediaConditionFilterInGUI();
        this.mediaTypesToDisplay = Helper.handleMediaTypesToDisplay(
          this.conditionFilterMediaLog?.condition?.mediaTypes,
          this.translateService
        );
        this.conditionFilterMediaLog[this.OLD_TIME_FROM] = this.conditionFilterMediaLog.dateFrom;
        this.conditionFilterMediaLog[this.OLD_TIME_TO] = this.conditionFilterMediaLog.dateTo;
        this.period = data.period;
        this.isChangedTimeFromContentReport = false;
        this.isChangedTimeToContentReport = false;
        if (this.period != PERIOD.DAILY) {
          setTimeout(() => {
            this.updateConfig(this.period);
          });
        }
        this.getTimeStart();
        if (!this.conditionFilterMediaLog?.condition) {
          this.mediaNameFilters = null;
          return;
        }
      },
      () => this.handleShowMessage(Constant.ERROR_TITLE, Constant.COMMON_ERROR)
    );
  }

  /**
   * filter device by customtag and element
   */
  public filterDeviceByCustomtagAndElement(): void {
    // filter device display by custom tag and custom tag element
    // get all device
    if (!this.conditionFilterMediaLog.condition.customTag && !this.conditionFilterMediaLog.condition.customTagElementId) {
      this.deviceDisplay = _.cloneDeep(this.devices);
      // get devices by custom tag
    } else if (this.conditionFilterMediaLog.condition.customTag && !this.conditionFilterMediaLog.condition.customTagElementId) {
      this.handleGetDeviceWithCustomTagAndNoElement(this.conditionFilterMediaLog.condition.customTag.id);
      // get devices by custom tag element
    } else if (this.conditionFilterMediaLog.condition.customTag && this.conditionFilterMediaLog.condition.customTagElementId) {
      if (this.devices && this.devices.length > 0) {
        this.getDeviceByConditionOfElement(this.conditionFilterMediaLog.condition.customTagElementId);
      }
    }
    if (this.deviceDisplay.length == this.conditionFilterMediaLog.registrationIds.length) {
      this.conditionFilterMediaLog.registrationIds = [Constant.ALL_DEVICE_KEY];
    }
  }

  /**
   * handle get device with custom tag and no element
   */
  public handleGetDeviceWithCustomTagAndNoElement(customTagId: Number): void {
    let elementIds = this.customTags?.filter(customTag => customTag.id == customTagId)[0].elements?.map(element => element.id);
    if (this.devices && this.devices.length > 0) {
      elementIds.forEach(elementId => {
        this.getDeviceByConditionOfElement(elementId);
      });
    }
  }

  /**
   * get device by condition of custom tag and element
   * @param id
   */
  public getDeviceByConditionOfElement(id: number): void {
    this.devices.forEach(device => {
      if (device.customTag0 && device.customTag0.id == id) {
        this.deviceDisplay.push(device);
      } else if (device.customTag1 && device.customTag1.id == id) {
        this.deviceDisplay.push(device);
      } else if (device.customTag2 && device.customTag2.id == id) {
        this.deviceDisplay.push(device);
      } else if (device.customTag3 && device.customTag3.id == id) {
        this.deviceDisplay.push(device);
      } else if (device.customTag4 && device.customTag4.id == id) {
        this.deviceDisplay.push(device);
      }
    });
  }

  /**
   * Set media condition filter in GUI
   */
  private setMediaConditionFilterInGUI(): void {
    if (this.conditionFilterMediaLog?.registrationIds?.includes(this.ALL_DEVICE)) {
      this.conditionFilterMediaLog.registrationIds = [this.translateService.instant('dashboard.tab-content-report.all-device')];
    }
    if (this.conditionFilterMediaLog?.playlistNames?.includes(this.SELECT_ALL)) {
      this.conditionFilterMediaLog.playlistNames = [this.translateService.instant('dashboard.tab-content-report.select-all')];
    }
  }

  /**
   * Set condition filter media log default
   */
  private setConditionFilterMediaLogDefault(): void {
    this.conditionFilterMediaLog.registrationIds = [this.translateService.instant('dashboard.tab-content-report.all-device')];
    this.conditionFilterMediaLog.playlistNames = [this.translateService.instant('dashboard.tab-content-report.select-all')];
    this.mediaTypesToDisplay = this.translateService.instant('dashboard.tab-content-report.select-all');
  }

  /**
   * get media extract condition
   */
  private getMediaByExtractCondition(): void {
    if (!this.conditionFilterMediaLog?.condition) {
      return;
    }
    this.simpleMediaService.getMediaByExtractCondition(this.conditionFilterMediaLog.condition).subscribe(
      data => {
        this.mediaNameFilters = data;
        if (!this.conditionFilterMediaLog?.dateFrom && !this.conditionFilterMediaLog?.dateTo) {
          this.dateAndNumberPlays = [];
          this.mediaLogPerDevicesDisplay = [];
          this.mediaTabProperties = undefined;
          this.mediaLogPerDevicesFilterDisplay = [];
          this.subTotalPlayPerDay = { subTotal: 0, totalPlayPerDay: [] };
          this.mediaNamesToDisplay = data;
          this.fetchMediaLogContentsByMediaNames(this.mediaNameFilters);
          return;
        }
        this.getLogMedia(this.timeFrom, this.timeTo, this.mediaNameFilters, true);
      },
      () => this.handleShowMessage(Constant.ERROR_TITLE, Constant.COMMON_ERROR)
    );
  }

  /**
   * Set condition filter device log default
   */
  private setConditionFilterDeviceLogDefault(): void {
    this.conditionFilterDeviceLog = new ConditionFilterDeviceLog(this.userId as number);
    this.conditionFilterDeviceLog['customTag01'] = this.customTags.find(customTag => customTag.name == Constant.SIGNAGE_GROUP);
    this.conditionFilterDeviceLog[Constant.LIST_CUSTOM_TAG_ELEMENT_1] = this.conditionFilterDeviceLog['customTag01'].elements;
    this.conditionFilterDeviceLog[Constant.LABEL_1] = this.translateService.instant('dashboard.tab-device-monitor.select-all');
  }

  /**
   * open date picker
   */
  public addPseudoSpan(): void {
    if (this.isActiveContentReportTab) {
      if (this.period == PERIOD.DAILY) {
        this.conditionFilterMediaLog[this.PREVIOUS_DATE_FROM] = this.timeFromDisplay ?? undefined;
        this.conditionFilterMediaLog[this.PREVIOUS_DATE_TO] = this.timeToDisplay ?? undefined;
      } else {
        this.conditionFilterMediaLog[this.PREVIOUS_DATE_FROM] = this.firstDateOfWeek ?? undefined;
        this.conditionFilterMediaLog[this.PREVIOUS_DATE_TO] = this.endDateOfWeek ?? undefined;
      }
      if (this.period != PERIOD.WEEKLY) {
        while (document.getElementById('span-new')) {
          document.getElementById('span-new').remove();
        }
        return;
      }
      if (document.getElementById('span-new')) {
        return;
      }
      const dp = Array.prototype.slice.call(document.getElementsByClassName('dp-weekdays'));
      if (dp) {
        dp.forEach(e => {
          const element = document.createElement('span');
          element.id = `span-new`;
          element.classList.add('dp-calendar-weekday', 'abc');
          e.insertBefore(element, e.firstElementChild);
        });
      }
    } else {
      while (document.getElementById('span-new')) {
        document.getElementById('span-new').remove();
      }
    }
  }

  /**
   * hide / show col 2 on screen
   */
  public hideShowCol2(): void {
    this.isHideCol2 = !this.isHideCol2;
  }

  /**
   * close detail
   */
  public closeDetail(): void {
    this.mediaLogContentSelected = undefined;
    this.mediaTabProperties = undefined;
  }

  /**
   * clear setting
   * @returns
   */
  private clearSetting(): void {
    if (this.isActiveContentReportTab) {
      if (!this.conditionFilterMediaLog?.id) {
        this.conditionFilterMediaLog = new ConditionFilterMediaLog(this.userId as number, this.period);
        this.setConditionFilterMediaLogDefault();
        this.resetTabContentReport();
        return;
      }
      this.conditionFilterMediaLogService.deleteConditionFilterMediaLog(this.conditionFilterMediaLog).subscribe(
        data => {
          this.conditionFilterMediaLog = new ConditionFilterMediaLog(this.userId as number, this.period);
          this.setConditionFilterMediaLogDefault();
          this.resetTabContentReport();
        },
        () => this.handleShowMessage(Constant.ERROR_TITLE, Constant.COMMON_ERROR)
      );
    } else {
      this.pauseCallCurrentStatusSubject.next();
      this.intervalCurrentStatusReport?.unsubscribe();
      this.subscribeCallCustomAPICurrentStatus?.unsubscribe();
      if (!this.conditionFilterDeviceLog?.id) {
        this.setConditionFilterDeviceLogDefault();
        this.resetTabDeviceMonitor();
        return;
      }
      this.conditionFilterDeviceLogService.deleteConditionFilterDeviceLog(this.conditionFilterDeviceLog).subscribe(
        () => {
          this.setConditionFilterDeviceLogDefault();
          this.resetTabDeviceMonitor();
        },
        () => this.handleShowMessage(Constant.ERROR_TITLE, Constant.COMMON_ERROR)
      );
    }
  }

  /**
   * reset tab content report
   */
  private resetTabContentReport(): void {
    this.period = PERIOD.DAILY;
    this.conditionFilterMediaLog.period = PERIOD.DAILY;
    this.resetTimePeriod();
    setTimeout(() => {
      this.updateConfig(this.period);
    });
    this.mediaLogContents = [];
    this.mediaTabProperties = undefined;
    this.mediaLogContentSelected = undefined;
    this.dateAndNumberPlays = [];
    this.isChangedTimeFromContentReport = false;
    this.isChangedTimeToContentReport = false;
    this.mediaNameFilters = undefined;
    this.isChangedGrandTotal = false;
    this.mediaLogPerDevicesDisplay = [];
    this.mediaLogPerDevicesFilterDisplay = [];
    this.subTotalPlayPerDay = undefined;
  }

  /**
   * reset tab device monitor
   */
  private resetTabDeviceMonitor(): void {
    this.deviceLogs = [];
    this.deviceLogSelected = undefined;
  }

  /**
   * get information setting device auto refresh
   */
  private getInformationSettingAutoRefresh(): void {
    this.settingDeviceAutoRefreshService.getInformationSettingAutoRefresh().subscribe(
      data => {
        this.settingDeviceMonitorAutoRefresh = data[0];
      },
      () => this.handleShowMessage(Constant.ERROR_TITLE, Constant.COMMON_ERROR)
    );
  }

  /**
   * setting device auto refresh
   */
  public setting(): void {
    if (!this.isActiveDeviceMonitorTab) {
      return;
    }
    this.pauseCallCurrentStatusSubject.next();
    this.dialogService.showDialog(
      DialogSettingDeviceMonitorAutoRefreshComponent,
      {
        data: {
          settingDeviceMonitorAutoRefresh: _.cloneDeep(this.settingDeviceMonitorAutoRefresh)
        }
      },
      result => {
        if (result) {
          this.settingDeviceMonitorAutoRefresh = result;
          this.intervalCurrentStatusReport?.unsubscribe();
          this.subscribeCallCustomAPICurrentStatus?.unsubscribe();
          if (this.settingDeviceMonitorAutoRefresh.autoRefresh) {
            this.callCustomAPICurrentStatus(_.sortBy(this.deviceLogs, ['id']));
          }
        } else {
          this.startCallCurrentStatusSubject.next();
        }
      }
    );
  }

  /**
   * get latest media log
   */
  public getLatestMediaLog(): void {
    if (!this.conditionFilterMediaLog?.registrationIds) {
      return;
    }
    const registrationIds = this.conditionFilterMediaLog.registrationIds.includes(
      this.translateService.instant('dashboard.tab-content-report.all-device')
    )
      ? this.allDevices?.map(data => data.registrationId)
      : this.allDevices
          ?.filter(device => this.conditionFilterMediaLog.registrationIds.includes(device.deviceId))
          ?.map(data => data.registrationId);
    this.apiCustomerService.getLatestMediaLog(registrationIds).subscribe(
      data => {
        let oldDateFrom = this.conditionFilterMediaLog[this.OLD_TIME_FROM];
        let oldDateTo = this.conditionFilterMediaLog[this.OLD_TIME_TO];
        if (!oldDateFrom || !oldDateTo) {
          return;
        }
        this.getLogMedia(oldDateFrom, oldDateTo, this.mediaNameFilters);
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * Handle show message
   *
   * @param title
   * @param messageCode
   */
  private handleShowMessage(title: string, messageCode: string): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant(`dashboard.message.${title}`),
        text: this.translateService.instant(`dashboard.message.${messageCode}`)
      }
    });
  }

  /**
   * select device
   *
   * @param deviceLog
   */
  public selectDevice(deviceLog: DeviceLog, event?): void {
    if (this.deviceLogSelected == deviceLog || event?.target?.id === 'checkBoxDevice') {
      return;
    }
    if (this.deviceLogSelected?.deviceScreenCaptureUrl) {
      this.deviceLogSelected.deviceScreenCaptureUrl = '';
    }
    this.deviceLogSelected = deviceLog;
    this.lastTimeUpdate = null;
    this.timeByImg = null;
  }

  /**
   * show pop up sort filter
   * @param customTagColumn name column custom tag
   * @param event
   */
  public showPopupSortFilter(customTagColumn: string, event): void {
    event.stopPropagation();
    this.isShowPopUpSortFilter = !this.isShowPopUpSortFilter;
    // if is show
    if (this.isShowPopUpSortFilter) {
      this.columnSortFiltering = customTagColumn;
      this.fetchFilterData(customTagColumn);
    }
  }

  /**
   * sort basic
   * @param property property sorted
   * @param type type sort
   */
  public sortProperty(property: string, type: string): void {
    this.listSorted = [[property], [type]];
    this.mediaLogPerDevicesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
    let columnsSorted = this.columns.filter(data => data.isSortBy != '');
    if (columnsSorted.length !== 0) {
      columnsSorted.forEach(element => (element.isSortBy = ''));
    }
    this.columns.find(data => data.property == property).isSortBy = type;
  }

  /**
   * check select all option
   */
  public checkAllOptionFilter(): void {
    this.isCheckAllOptionFilter = !this.isCheckAllOptionFilter;
    this.listFilterDisplayOrigin.forEach(option => {
      option.isChecked = this.isCheckAllOptionFilter;
    });
    this.listFilterDisplay = [...this.listFilterDisplayOrigin];
  }

  /**
   * get array not duplicate value
   * @param array
   * @param property
   */
  public filterBy = (array, property): any => {
    return _.uniqBy(array, property);
  };

  /**
   * fetch data filter to pop up
   * @param property column show popup
   */
  public fetchFilterData(property: string): void {
    // if not last column filter
    if (this.lastColumnFilter != property) {
      this.listFilterDisplay = [];
      let listDeviceGetOptionFilter = this.mediaLogPerDevicesFilterDisplay.filter(
        data => !data['lastFilter'] || data['lastFilter'] == property
      );
      let listDeviceOptionFilter: Device[] = this.filterBy(listDeviceGetOptionFilter, property);
      for (let i = 0; i < listDeviceOptionFilter.length; i++) {
        //get list option filter
        this.listFilterDisplay[i] = {
          isChecked: !listDeviceOptionFilter[i]['isFilter'],
          name: listDeviceOptionFilter[i][property]
        };
      }
      // if is last column filter
    } else {
      this.listFilterDisplay = this.listCurrentFilter[property];
    }
    // get list memorize checked
    this.listFilterDisplayOrigin = this.listFilterDisplay.map(x => Object.assign({}, x));
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * change checked
   * @param index index of option filter
   */
  public checkOptionFilter(index: number): void {
    this.listFilterDisplayOrigin[index].isChecked = !this.listFilterDisplayOrigin[index].isChecked;
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * set lastFilter for device to filter or un filter
   * @param currentFilter list option filter property
   * @param property column filtering
   */
  public getCurrentFilter(currentFilter: listFilter[], property: string): void {
    for (let i = 0; i < currentFilter.length; i++) {
      if (!currentFilter[i].isChecked) {
        let arr = this.mediaLogContentSelected?.mediaLogPerDevices.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          element['isFilter'] = true;
          if (!element['lastFilter']) {
            element['lastFilter'] = property;
          }
        });
      } else {
        let arr = this.mediaLogContentSelected?.mediaLogPerDevices.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          if (element['lastFilter'] == property) {
            element['isFilter'] = false;
            element['lastFilter'] = undefined;
          }
        });
      }
    }
  }

  /**
   * filter device
   * @param property
   */
  public filterDevice(property: string): void {
    // do not filter all
    if (this.listFilterDisplayOrigin.every(data => !data.isChecked)) {
      this.isShowPopUpSortFilter = false;
      return;
    }
    this.isFilter = true;
    this.columns.find(data => data.property == property).isFilterBy = property;
    this.lastColumnFilter = property;
    // if is not clear last column filter
    if (!this.isClear) {
      this.listFilterDisplay = [...this.listFilterDisplayOrigin];
    }
    // if list option filter checked all or clear last column filter
    if (this.listFilterDisplay.findIndex(data => !data.isChecked) < 0) {
      delete this.listCurrentFilter[property];
      this.lastColumnFilter = Object.keys(this.listCurrentFilter)
        .slice(-1)
        .pop();
      this.isClear = false;
      this.isFilter = false;
      this.columns.find(data => data.property == property).isFilterBy = '';
    } else {
      // if filter a column was filtered
      if (
        this.listCurrentFilter[property] &&
        this.lastColumnFilter != Object.keys(this.listCurrentFilter)[Object.keys(this.listCurrentFilter).length - 1]
      ) {
        let keys = Object.keys(this.listCurrentFilter);
        let nextProperTyFilter = keys[keys.indexOf(property) + 1];
        this.mediaLogContentSelected?.mediaLogPerDevices.forEach(element => {
          if (element['lastFilter'] == property) {
            element['lastFilter'] = nextProperTyFilter;
          }
        });
        let listDeviceGetOptionFilter = this.mediaLogContentSelected?.mediaLogPerDevices.filter(
          data => data['lastFilter'] == nextProperTyFilter || !data['lastFilter']
        );
        let listDeviceOptionFilter = this.filterBy(listDeviceGetOptionFilter, nextProperTyFilter);
        let listOptionFilterNew: Array<listFilter> = new Array<listFilter>();
        for (let i = 0; i < listDeviceOptionFilter.length; i++) {
          listOptionFilterNew[i] = {
            isChecked: !listDeviceOptionFilter[i].lastFilter,
            name: listDeviceOptionFilter[i][nextProperTyFilter]
          };
        }
        this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
        delete this.listCurrentFilter[property];
      }
      // set list option filter property
      this.listCurrentFilter[property] = this.listFilterDisplay;
    }
    this.getCurrentFilter(this.listFilterDisplay, property);
    // get list device show up on screen
    this.mediaLogContentSelected?.mediaLogPerDevices.filter(data => data['lastFilter']).map(data => (data[Constant.IS_SELECTED] = false));
    this.mediaLogPerDevicesDisplay = this.mediaLogContentSelected?.mediaLogPerDevices.filter(data => !data['lastFilter']);
    this.mediaLogPerDevicesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.mediaLogPerDevicesFilterDisplay = [...this.mediaLogPerDevicesDisplay];
    this.isShowPopUpSortFilter = false;
    this.controlCheckBoxCheckAllFilter();
    this.calculateSubTotalPerMedia();
  }

  /**
   * clear filter
   * @param property name of column clear filter
   */
  public clearFilter(property: string): void {
    this.columns.find(data => data.property == property).isFilterBy = '';
    this.isFilter = false;
    // set all option in list is true
    this.listCurrentFilter[property]?.forEach(element => {
      element.isChecked = true;
    });
    // if is last column filter
    if (property == this.lastColumnFilter) {
      this.isClear = true;
      this.filterDevice(property);
      // if is not last column filter
    } else {
      let keys = Object.keys(this.listCurrentFilter);
      let nextProperTyFilter = keys[keys.indexOf(property) + 1];
      // set lastFilter is next column filter
      this.mediaLogContentSelected?.mediaLogPerDevices.forEach(element => {
        if (element['lastFilter'] == property) {
          element['isFilter'] = false;
          element['lastFilter'] = nextProperTyFilter;
        }
      });
      // get new list option filter for next property filter in listCurrentFilter
      let listDeviceGetOptionFilter = this.mediaLogContentSelected?.mediaLogPerDevices.filter(
        data => data['lastFilter'] == nextProperTyFilter || !data['lastFilter']
      );
      let listDeviceOptionFilter = this.filterBy(listDeviceGetOptionFilter, nextProperTyFilter);
      let listOptionFilterNew: Array<listFilter> = new Array<listFilter>();
      for (let i = 0; i < listDeviceOptionFilter.length; i++) {
        listOptionFilterNew[i] = {
          isChecked: !listDeviceOptionFilter[i].lastFilter,
          name: listDeviceOptionFilter[i][nextProperTyFilter]
        };
      }
      listOptionFilterNew.forEach(element => {
        for (let i = 0; i < this.listCurrentFilter[nextProperTyFilter]?.length; i++) {
          if (element.name == this.listCurrentFilter[nextProperTyFilter][i].name) {
            element.isChecked = this.listCurrentFilter[nextProperTyFilter][i].isChecked;
          }
        }
      });
      // set new list option filter for next property filter in listCurrentFilter
      this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
      this.isShowPopUpSortFilter = false;
      delete this.listCurrentFilter[property];
      this.controlCheckBoxCheckAllFilter();
    }
  }

  /**
   * control checkBox check all filter when uncheck and checked
   */
  private controlCheckBoxCheckAllFilter(): void {
    this.isCheckAllOptionFilter = this.listFilterDisplayOrigin.every(filter => filter.isChecked);
  }

  /**
   * show custom sort dialog and sort
   */
  public showCustomSort(): void {
    this.isShowPopUpSortFilter = false;
    this.dialogService.showDialog(DialogCustomSortComponent, { data: { list: [this.listSorted, this.columns] } }, result => {
      if (result) {
        this.listSorted = result;
        for (let i = 0; i < this.columns.length; i++) {
          let index = this.listSorted[0].findIndex(column => column == this.columns[i]?.property);
          if (index == -1) {
            this.columns[i].isSortBy = '';
          } else {
            this.columns[i].isSortBy = this.listSorted[1][index];
          }
        }
        // sort
        this.mediaLogPerDevicesDisplay.sort(this.dynamicSortMultiple(result));
      }
    });
  }

  /**
   * sort multiple
   * @param result list properties and sort type sorted
   */
  public dynamicSortMultiple(result: any): any {
    return function(obj1, obj2) {
      let output = 0,
        i = 0;
      while (output == 0 && i < result[0]?.length) {
        let a = obj1[result[0][i]];
        let b = obj2[result[0][i]];
        if (result[1][i] === 'DESC') {
          output = a > b ? -1 : a < b ? 1 : 0;
        } else {
          output = a > b ? 1 : a < b ? -1 : 0;
        }
        i++;
      }
      return output;
    };
  }

  /**
   * reset sort filter
   */
  private resetSortFilter(): void {
    this.columns.forEach(column => {
      column.isSortBy = '';
      column.isFilterBy = '';
    });
    this.listSorted = [];
    this.listCurrentFilter = {};
    this.lastColumnFilter = undefined;
  }

  /**
   * refresh data device log
   */
  public refreshDataDeviceLog(): void {
    if (this.isActiveContentReportTab) {
      return;
    }
    this.callCustomAPICurrentStatus(this.deviceLogs);
  }

  /**
   * calculate sub total per media
   */
  private calculateSubTotalPerMedia(): void {
    const totalPlayMediaDevices = this.mediaLogPerDevicesFilterDisplay.map(item => item.totalPerDevice);
    const totalPlayPerDayDevices = this.mediaLogPerDevicesFilterDisplay.map(item => item.logPerDayOfDevice);
    const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
    let totalPlayPerDays: Array<number> = new Array<number>(totalPlayPerDayDevices[0]?.length).fill(0);
    totalPlayPerDays?.forEach((data, index) => {
      totalPlayPerDayDevices?.forEach(element => {
        totalPlayPerDays[index] += element[index]?.numberPlay;
      });
    });
    this.subTotalPlayPerDay = { subTotal: totalPlayMediaDevices?.reduce(reducer), totalPlayPerDay: totalPlayPerDays };
  }

  /**
   * fetch data media log by condition  to display
   *
   * @param mediaNames
   */
  private fetchMediaLogContentsByMediaNames(mediaNames: Array<string>): void {
    this.mediaLogContents = [];
    mediaNames.forEach(mediaName => {
      this.mediaLogContents.push({
        grandTotal: 0,
        nameMedia: mediaName,
        logPerDay: undefined,
        mediaLogPerDevices: undefined,
        logPlayPerMedias: undefined,
        oldLogPlayPerMedias: undefined
      });
    });
  }

  /**
   * open date picker
   * @param time selected
   * @param isFrom true if open date picker time from
   */
  openDatePicker(picker: DatePickerDirective, time: any) {
    picker.api.open();
    this.addPseudoSpan();
    picker.api.moveCalendarTo(time ?? moment());
  }

  /**
   * Capture screen device
   *
   */
  public captureScreenDevice(): void {
    // call API status to check network controller device
    this.apiCustomerService.getCurrentStatusRequest([this.deviceLogSelected.registrationId]).subscribe(
      data => {
        // update information for device selected
        let deviceCompleted = data[Constant.COMPLETE_STATUS]?.find(
          deviceResponse => this.deviceLogSelected.registrationId === deviceResponse.id
        );
        this.handleAfterCallAPIStatus(deviceCompleted);
        // check network OK/NG
        if (!this.deviceLogSelected.network) {
          return;
        }
        // network OK => call API screen capture
        this.handleCallAPIScreenCapture();
      },
      () => {
        this.handleAfterCallAPIStatus(undefined);
      }
    );
  }

  /**
   * handle after call API status
   * @param deviceCompleted
   */
  private handleAfterCallAPIStatus(deviceCompleted: any) {
    let deviceLog = Helper.convertDataDeviceLog(deviceCompleted ?? undefined, this.deviceLogSelected, this.languageKey);
    deviceLog[Constant.NETWORK_STATUS] = !!deviceCompleted;
    deviceLog.id = this.deviceLogSelected.id;

    let index = this.deviceLogs.findIndex(device => device.registrationId == deviceLog.registrationId);
    if (index != -1) {
      this.deviceLogs[index] = deviceLog;
      this.deviceLogSelected = this.deviceLogs[index];
    }
  }

  /**
   * handle call api screen capture
   */
  private handleCallAPIScreenCapture() {
    const payload = {
      device: this.deviceLogSelected.registrationId
    };
    this.apiCustomerService.captureScreenDevice(payload).subscribe(
      () => {
        this.executingService.executing();
        setTimeout(() => {
          this.deviceService.getDeviceScreenCaptureUrl(this.deviceLogSelected.registrationId).subscribe(
            deviceScreenCaptureUrlObject => {
              this.executingService.executed();
              if (!deviceScreenCaptureUrlObject) {
                return;
              }
              this.timeByImg = deviceScreenCaptureUrlObject['time'];
              this.lastTimeUpdate = Helper.formatString(
                this.translateService.instant(`timetable-operation-manager.last-time-update`),
                Helper.updateLanguageLastTime(this.timeByImg, this.languageKey)
              );
              this.deviceLogSelected.deviceScreenCaptureUrl = deviceScreenCaptureUrlObject[this.URL];
            },
            () => {
              this.executingService.executed();
              this.handleShowMessage(Constant.ERROR_TITLE, Constant.COMMON_ERROR);
            }
          );
        }, 3000);
      },
      () => this.handleShowMessage(Constant.ERROR_TITLE, 'call-screen-failed')
    );
  }

  /**
   * export media log
   * @returns
   */
  async exportMediaLog(): Promise<void> {
    if (this.isActiveDeviceMonitorTab) {
      return;
    }
    if (this.mediaLogContents.length == 0) {
      this.handleShowMessage(Constant.ERROR_TITLE, this.NO_DATA_TO_EXPORT);
      return;
    }
    if (!this.mediaLogContentSelected) {
      this.handleShowMessage(Constant.ERROR_TITLE, this.NO_SELECT_MEDIA);
      return;
    }
    if (!this.mediaLogContentSelected.logPlayPerMedias || this.mediaLogContentSelected.logPlayPerMedias.length == 0) {
      this.handleShowMessage(Constant.ERROR_TITLE, this.NO_DATA_TO_EXPORT);
      return;
    }

    // get header information
    let headers = [
      this.translateService.instant('dashboard.tab-content-report.media-detail.time'),
      this.translateService.instant('dashboard.tab-content-report.media-detail.device-id'),
      this.translateService.instant('dashboard.tab-content-report.media-detail.file-name'),
      this.translateService.instant('dashboard.tab-content-report.media-detail.media-type')
    ];
    let mediaNameJP: string;
    // Check if media is not original
    if (
      this.TYPES.includes(
        this.mediaLogContentSelected.nameMedia.substring(this.mediaLogContentSelected.nameMedia.lastIndexOf('.') + 1).toLocaleLowerCase()
      )
    ) {
      mediaNameJP = this.mediaLogContentSelected.nameMedia.substring(0, this.mediaLogContentSelected.nameMedia.lastIndexOf('.'));
    } else {
      // check if media is original
      mediaNameJP = !this.originalObject
        ? this.mediaLogContentSelected.nameMedia
        : this.originalObject[`${this.mediaLogContentSelected.nameMedia}`].name;
    }

    // get log information
    this.mediaLogContentSelected.logPlayPerMedias.forEach(log => {
      log.fileName = mediaNameJP;
      log.mediaType = Helper.checkTypeMediaByMediaName(this.mediaLogContentSelected.nameMedia);
    });
    this.deviceService.exportMediaLog(this.mediaLogContentSelected.logPlayPerMedias, JSON.stringify(headers)).subscribe(
      response => {
        const fileNameResponse = decodeURIComponent(response.headers.get('content-disposition'));
        const file = new File([response.body], fileNameResponse, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        fileSaver.saveAs(file);
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * export content report
   * @returns
   */
  public exportContentReport(): any {
    if (this.mediaLogContents.length == 0) {
      this.handleShowMessage(Constant.ERROR_TITLE, this.NO_DATA_TO_EXPORT);
      return;
    }
    let listContentReport = new Array<ContentReportExport>();
    this.mediaLogContents.forEach(mediaLog => {
      let mediaName: string;
      // Check if media is not original
      if (this.TYPES.includes(mediaLog.nameMedia.substring(mediaLog.nameMedia.lastIndexOf('.') + 1).toLocaleLowerCase())) {
        mediaName = mediaLog.nameMedia.substring(0, mediaLog.nameMedia.lastIndexOf('.'));
        // check if media is original
      } else {
        mediaName = !this.originalObject ? mediaLog.nameMedia : this.originalObject[`${mediaLog.nameMedia}`].name;
      }
      listContentReport.push(
        new ContentReportExport(
          mediaLog.grandTotal,
          mediaName,
          mediaLog.logPerDay?.map(numberPlayPerDay => numberPlayPerDay.numberPlay)
        )
      );
    });

    const formatDate = this.languageKey == Constant.JP_LANGUAGE ? FormatDateEnum.FORMAT_JP : FormatDateEnum.FORMAT_EN;
    let dateHeader = this.dateAndNumberPlays?.map(dateAndNumberPlay =>
      this.period == PERIOD.DAILY ? this.datePipe.transform(dateAndNumberPlay.date, formatDate) : dateAndNumberPlay.date
    );

    this.deviceService.exportContentReport(listContentReport, JSON.stringify(dateHeader)).subscribe(
      response => {
        const fileNameResponse = decodeURIComponent(response.headers.get('content-disposition'));
        const file = new File([response.body], fileNameResponse, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        fileSaver.saveAs(file);
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * getOriginalContentObject
   * @returns
   */
  private async getOriginalContentObject(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.simpleMediaService.getObjectOriginal().subscribe(
        data => {
          this.originalObject = data;
          resolve();
        },
        error => {
          reject();
          Helper.handleError(error, this.translateService, this.dialogService);
        }
      );
    });
  }

  /**
   * checkRestart
   * @param e
   * @param data
   * @returns
   */
  checkRestart(e, data: DeviceLog) {
    e.stopPropagation();
    if (!data.network) {
      e.preventDefault();
      return;
    }
    data.isChecking = !data.isChecking;
    this.isCheckedAll = this.deviceLogs?.filter(device => device.network).every(device => device.isChecking);
  }

  /**
   * checkAll
   */
  checkAll(event): void {
    if (this.deviceLogs.every(e => !e.network)) {
      event.preventDefault();
      return;
    }
    this.isCheckedAll = !this.isCheckedAll;
    this.deviceLogs?.forEach(device => {
      if (device.network) {
        device.isChecking = this.isCheckedAll;
      }
    });
  }

  /**
   * commandDevice
   * @returns
   */
  commandDevice(): void {
    const checkedDeviceLogs = this.deviceLogs.filter(device => device.isChecking);
    if (checkedDeviceLogs.length < 1) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dashboard.message.no-select-device')
        }
      });
      return;
    }

    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('dashboard.message.confirm-restart'),
          button1: this.translateService.instant('dashboard.message.agree-to-restart'),
          button2: this.translateService.instant('dashboard.message.refuse-to-restart')
        }
      },
      res => {
        if (!res) {
          return;
        }
        const id = (Math.floor(Math.random() * (100 - 1 + 1)) + 1).toString();
        const tenant = this.commonObject?.tenantName.toUpperCase();

        let payloads = {
          id: id,
          accessToken: sessionStorage.getItem('access_token'),
          refreshToken: sessionStorage.getItem('refresh_token'),
          payload: {
            devices: checkedDeviceLogs?.map(device => ({
              registrationId: device.registrationId,
              parent: tenant,
              label: device.deviceId
            })),
            command: 'reboot',
            params: {}
          }
        };

        this.deviceService.commandDevice(payloads).subscribe(
          res => {
            if (res?.status == Constant.REQUEST_SUCCESS) {
              this.handleDataDeviceLogsWhenRestart(checkedDeviceLogs);
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('dashboard.message.success'),
                  text: this.translateService.instant('dashboard.message.restart-success')
                }
              });
            } else if (res?.status == Constant.REQUEST_ERROR) {
              this.handleDataDeviceLogsWhenRestart(checkedDeviceLogs);
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('dialog-error.title'),
                  text: this.translateService.instant('dashboard.message.restart-error')
                }
              });
            }
          },
          error => {
            this.handleDataDeviceLogsWhenRestart(checkedDeviceLogs);
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: this.translateService.instant('dashboard.message.restart-error')
              }
            });
          }
        );
        checkedDeviceLogs.forEach(e => {
          e.isChecking = false;
        });
        this.isCheckedAll = false;
      }
    );
  }

  /**
   * handleDataDeviceLogsWhenRestart
   * @param checkedDevice
   */
  handleDataDeviceLogsWhenRestart(checkedDevice: Array<DeviceLog>): void {
    const checkedDeviceIds = checkedDevice.map(device => device.id);
    this.deviceLogs.forEach(deviceLog => {
      if (checkedDeviceIds.includes(deviceLog.id)) {
        deviceLog.network = false;
        deviceLog.systemTime = undefined;
        deviceLog.firmwareVersion = undefined;
        deviceLog.deviceScreenCaptureUrl = undefined;
        deviceLog.shutdownTime = undefined;
      }
    });
  }
}

/**
 * interface MediaLogContent
 */
export interface MediaLogContent {
  grandTotal: number;
  nameMedia: string;
  logPerDay: Array<NumberPlayPerDay>;
  mediaLogPerDevices: Array<MediaLogPerDevice>;
  logPlayPerMedias: Array<LogPerMedia>;
  oldLogPlayPerMedias: Array<LogPerMedia>;
}

/**
 * interface ContentReportExport
 */
export class ContentReportExport {
  grandTotal: number;
  nameMedia: string;
  logPerDay: Array<Number>;
  constructor(grandTotal?: number, nameMedia?: string, logPerDay?: Array<Number>) {
    this.grandTotal = grandTotal;
    this.nameMedia = nameMedia;
    this.logPerDay = logPerDay;
  }
}

/**
 * interface SubTotalPlayMedia
 */
export interface SubTotalPlayMedia {
  subTotal: number;
  totalPlayPerDay: Array<number>;
}

/**
 * class NumberPlayPerDay
 */
export class NumberPlayPerDay {
  date: string;
  numberPlay: number;
  year: number;
  constructor(date?: string, numberPlay?: number, year?: number) {
    this.date = date;
    this.numberPlay = numberPlay;
    this.year = year;
  }
}

/**
 * interface PlayLog
 */
export interface PlayLog {
  type: string;
  media: string;
  date: string;
  displayId?: string;
  sequence?: string;
}

/**
 * class MediaLogPerDevice
 */
export class MediaLogPerDevice {
  registrationId: string;
  totalPerDevice: number;
  logPerDayOfDevice: Array<NumberPlayPerDay>;
  deviceId: string;

  constructor(registrationId?: string, totalPerDevice?: number, logPerDayOfDevice?: Array<NumberPlayPerDay>) {
    this.registrationId = registrationId;
    this.totalPerDevice = totalPerDevice;
    this.logPerDayOfDevice = logPerDayOfDevice;
  }
  customTag1?: string;
  customTag2?: string;
  customTag3?: string;
}

/**
 * class LogPerMedia
 */
export class LogPerMedia {
  time: string;
  registrationId: string;
  deviceId: string;
  fileName: string;
  mediaType: string;

  constructor(time: string, registrationId: string) {
    this.time = time;
    this.registrationId = registrationId;
  }
}

/**
 * option filter
 */
export interface listFilter {
  isChecked: boolean;
  name: string;
}

/**
 * list of list options filtered
 */
export interface IHash {
  [details: string]: listFilter[];
}
