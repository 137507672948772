import { Component, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Subscription, forkJoin, of } from 'rxjs';
import { MenuActionService } from '../../service/menu-action.service';
import { MODULE_NAME, FIELD_COMPONENT, Constant } from '../../config/constants';
import { DialogService } from '../../service/dialog.service';
import { DialogDeviceManagerComponent } from '../../dialog/dialog-device-manager/dialog-device-manager.component';
import { DialogSelectGroupComponent } from '../../dialog/dialog-select-group/dialog-select-group.component';
import { DialogEditGroupComponent } from '../../dialog/dialog-edit-group/dialog-edit-group.component';
import { Device } from '../../model/entity/device';
import { DeviceService } from '../../service/device.service';
import { DialogMessageComponent } from '../../dialog/dialog-message/dialog-message.component';
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { DialogCustomSortComponent } from '../../dialog/dialog-custom-sort/dialog-custom-sort.component';
import { DialogDisplayDeviceComponent } from '../../dialog/dialog-display-device/dialog-display-device.component';
import { CustomTagService } from '../../service/custom-tag.service';
import { CustomTag } from '../../model/entity/custom-tag';
import { DialogListCustomTagComponent } from '../../dialog/dialog-list-custom-tag/dialog-list-custom-tag.component';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { SaveDeviceManagerStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { CommonService } from 'app/service/common.service';
import { Common } from 'app/model/entity/common';
import { APICustomerService } from 'app/service/api-customer.service';
import { Helper } from 'app/common/helper';
import { DialogSimpleSignageMessageComponent } from 'app/dialog/dialog-simple-signage-message/dialog-simple-signage-message.component';
import _ from 'lodash';

@Component({
  selector: 'app-device-manager',
  templateUrl: './device-manager.component.html',
  styleUrls: ['./device-manager.component.scss']
})
export class DeviceManagerComponent implements OnInit, OnDestroy {
  /**
   * subscription list
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * list all device
   */
  devices: Array<Device> = new Array<Device>();
  /**
   * list devices on display
   */
  devicesDisplay: Array<Device> = new Array<Device>();
  /**
   * list devices after filter
   */
  devicesFilterDisplay: Array<Device> = new Array<Device>();
  /**
   * device selected
   */
  deviceSelected: Device;
  /**
   * name of column show popup
   */
  propertyShowUp: string = Constant.EMPTY;
  /**
   * type sort ASC or DESC
   */
  typeSort: string = 'ASC';
  /**
   * name of last column filter
   */
  lastFiltered: string = Constant.EMPTY;
  /**
   * true if show button show popup sort filter
   */
  isSortAndFilter: boolean = false;
  /**
   * list of list options filtered
   */
  listCurrentFilter: IHash = {};
  /**
   * list properties and sort type sorted send to custom sort popup
   */
  listSorted = [];
  /**
   * true if clear last column filtered
   */
  isClear = false;
  /**
   * list options filter memorize checked
   */
  listFilterDisplayOrigin: Array<listFilter> = new Array<listFilter>();
  /**
   * list options filter
   */
  listFilterDisplay: Array<listFilter> = new Array<listFilter>();
  /**
   * number click outside popup
   */
  numberClickOutSide = 0;
  /**
   * true if show Comment
   */
  hasComment: boolean = true;
  /**
   * name last column showed
   */
  lastColumn: string = Constant.EMPTY;
  /**
   * list custom tag
   */
  listCustomTag = [];
  /**
   * true if selected all device
   */
  isCheckAll: boolean = false;
  /**
   * true if is sorting
   */
  isSort: string = '';
  isFilter: boolean = false;

  isChangedData: boolean = false;
  /**
   * true if selected all filter
   */
  isCheckAllFilter: boolean = true;

  /**
   * true if popup display left
   */
  isCheckPopup: boolean = false;

  /**
   * common object
   */
  private commonObject: Common = new Common();

  /**
   * Constant
   */
  private readonly LENGTH_ACCOUNT_OLD = 5;
  private readonly ACCOUNT_OLD = 'T00000';
  private readonly INDEX_CUSTOM_TAG_SELECTED = 0;
  private readonly INDEX_ELEMENT_SELECTED = 1;
  private readonly INDEX_DEVICE_IDS = 2;

  @ViewChild('table') private table: ElementRef;

  /**
   * fixed-header column
   */
  fixedColumns: any[] = [
    { headerName: 'Registration ID / Date', isChecked: false, property: 'displayIdDate', isSortBy: '', isFilterBy: '' },
    { headerName: 'Device ID', isChecked: false, property: 'deviceId', isSortBy: '', isFilterBy: '' },
    { headerName: 'Type', isChecked: false, property: 'type', isSortBy: '', isFilterBy: '' },
    { headerName: 'Bus ID', isChecked: false, property: 'busId', isSortBy: '', isFilterBy: '' },
    { headerName: 'Model Name', isChecked: false, property: 'modelName', isSortBy: '', isFilterBy: '' },
    { headerName: 'Serial No', isChecked: false, property: 'serialNo', isSortBy: '', isFilterBy: '' },
    { headerName: 'Description', isChecked: false, property: 'comment', isSortBy: '', isFilterBy: '' }
  ];

  /**
   * all columns
   */
  columns: any[] = [];

  stateOfComponent: {
    isChangeLayout: boolean;
    devices: Device[];
    devicesDisplay: Device[];
    devicesFilterDisplay: Device[];
    listCustomTag: CustomTag[];
    listFilterDisplayOrigin: listFilter[];
    listFilterDisplay: listFilter[];
    columns: any[];
    listSorted: any[];
    deviceSelected: Device;
    lastFiltered: string;
    listCurrentFilter: IHash;
    lastColumn: string;
    hasComment: boolean;
    isClear: boolean;
    isSortAndFilter: boolean;
    isCheckAll: boolean;
    isSort: string;
    isFilter: boolean;
    isChangedData: boolean;
  };

  constructor(
    public menuActionService: MenuActionService,
    public dialogService: DialogService,
    private deviceService: DeviceService,
    private customTagService: CustomTagService,
    public readonly store: Store<AppState>,
    private commonService: CommonService,
    private apiCustomerService: APICustomerService
  ) {
    // subscribe for add action
    this.subscriptions.push(
      this.menuActionService.actionAdd.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DeviceManagerComponent]) {
          this.addDevice();
        }
      })
    );
    // subscribe for edit action
    this.subscriptions.push(
      this.menuActionService.actionEdit.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DeviceManagerComponent]) {
          this.editDevice();
        }
      })
    );
    // subscribe for delete action
    this.subscriptions.push(
      this.menuActionService.actionDelete.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DeviceManagerComponent]) {
          this.deleteDevice();
        }
      })
    );
    // subscribe for select custom tag action
    this.subscriptions.push(
      this.menuActionService.actionSelectCustomTag.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DeviceManagerComponent]) {
          this.selectCustomTag();
        }
      })
    );
    // subscribe for edit custom tag action
    this.subscriptions.push(
      this.menuActionService.actionEditCustomTag.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DeviceManagerComponent]) {
          this.editCustomTag();
        }
      })
    );
    // subscribe for sort and filter action
    this.subscriptions.push(
      this.menuActionService.actionSortAndFilter.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DeviceManagerComponent]) {
          this.sortAndFilter();
        }
      })
    );
    // subscribe for add new custom tag action
    this.subscriptions.push(
      this.menuActionService.actionAddNewCustomTag.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DeviceManagerComponent]) {
          this.addNewCustomTag();
        }
      })
    );
    // subscribe for change display device action
    this.subscriptions.push(
      this.menuActionService.actionChangeDisplayDevice.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DeviceManagerComponent]) {
          this.changeDisplayDevice();
        }
      })
    );
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState.deviceManagerState?.stateOfComponent.isChangeLayout,
            devices: componentState.deviceManagerState?.stateOfComponent.devices,
            devicesDisplay: componentState.deviceManagerState?.stateOfComponent.devicesDisplay,
            devicesFilterDisplay: componentState.deviceManagerState?.stateOfComponent.devicesFilterDisplay,
            listCustomTag: componentState.deviceManagerState?.stateOfComponent.listCustomTag,
            listFilterDisplayOrigin: componentState.deviceManagerState?.stateOfComponent.listFilterDisplayOrigin,
            listFilterDisplay: componentState.deviceManagerState?.stateOfComponent.listFilterDisplay,
            columns: componentState.deviceManagerState?.stateOfComponent.columns,
            listSorted: componentState.deviceManagerState?.stateOfComponent.listSorted,
            deviceSelected: componentState.deviceManagerState?.stateOfComponent.deviceSelected,
            lastFiltered: componentState.deviceManagerState?.stateOfComponent.lastFiltered,
            listCurrentFilter: componentState.deviceManagerState?.stateOfComponent.listCurrentFilter,
            lastColumn: componentState.deviceManagerState?.stateOfComponent.lastColumn,
            hasComment: componentState.deviceManagerState?.stateOfComponent.hasComment,
            isClear: componentState.deviceManagerState?.stateOfComponent.isClear,
            isSortAndFilter: componentState.deviceManagerState?.stateOfComponent.isSortAndFilter,
            isCheckAll: componentState.deviceManagerState?.stateOfComponent.isCheckAll,
            isSort: componentState.deviceManagerState?.stateOfComponent.isSort,
            isFilter: componentState.deviceManagerState?.stateOfComponent.isFilter,
            isChangedData: componentState.deviceManagerState?.stateOfComponent.isChangedData
          };
        })
    );
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.commonObject = this.commonService.getCommonObject();
    if (!this.stateOfComponent?.isChangeLayout) {
      this.fetchDeviceData();
    } else {
      this.handleAfterChangeLayout();
    }
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.store.dispatch(
      new SaveDeviceManagerStateAction({
        isChangeLayout: true,
        devices: this.devices,
        devicesDisplay: this.devicesDisplay,
        devicesFilterDisplay: this.devicesFilterDisplay,
        listCustomTag: this.listCustomTag,
        listFilterDisplayOrigin: this.listFilterDisplayOrigin,
        listFilterDisplay: this.listFilterDisplay,
        columns: this.columns,
        listSorted: this.listSorted,
        deviceSelected: this.deviceSelected,
        lastFiltered: this.lastFiltered,
        listCurrentFilter: this.listCurrentFilter,
        lastColumn: this.lastColumn,
        hasComment: this.hasComment,
        isClear: this.isClear,
        isSortAndFilter: this.isSortAndFilter,
        isCheckAll: this.isCheckAll,
        isSort: this.isSort,
        isFilter: this.isFilter,
        isChangedData: this.isChangedData
      })
    );
  }

  private handleAfterChangeLayout() {
    this.devices = this.stateOfComponent.devices;
    this.devicesDisplay = this.stateOfComponent.devicesDisplay;
    this.devicesFilterDisplay = this.stateOfComponent.devicesFilterDisplay;
    this.listCustomTag = this.stateOfComponent.listCustomTag;
    this.listFilterDisplayOrigin = this.stateOfComponent.listFilterDisplayOrigin;
    this.listFilterDisplay = this.stateOfComponent.listFilterDisplay;
    this.columns = this.stateOfComponent.columns;
    this.listSorted = this.stateOfComponent.listSorted;
    this.deviceSelected = this.stateOfComponent.deviceSelected;
    this.lastFiltered = this.stateOfComponent.lastFiltered;
    this.listCurrentFilter = this.stateOfComponent.listCurrentFilter;
    this.lastColumn = this.stateOfComponent.lastColumn;
    this.hasComment = this.stateOfComponent.hasComment;
    this.isClear = this.stateOfComponent.isClear;
    this.isSortAndFilter = this.stateOfComponent.isSortAndFilter;
    this.isCheckAll = this.stateOfComponent.isCheckAll;
    this.isSort = this.stateOfComponent.isSort;
    this.isFilter = this.stateOfComponent.isFilter;
    this.isChangedData = this.stateOfComponent.isChangedData;
  }

  /**
   * fetch device data
   */
  fetchDeviceData(): void {
    this.deviceService.getDevice().subscribe(
      data => {
        this.updateHeader();
        this.devices = data;
        this.creatIdDate();
        this.devicesDisplay = [...this.devices];
        this.devicesFilterDisplay = [...this.devices];
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * update header column with custom tag
   */
  updateHeader(): void {
    this.customTagService.getCustomTag().subscribe(
      data => {
        this.listCustomTag = data;
        this.columns = JSON.parse(JSON.stringify(this.fixedColumns));
        for (let i = 0; i < this.listCustomTag?.length; i++) {
          this.columns.splice(Constant.INDEX_OF_COLUMN_CUSTOM_TAG + i, 0, {
            headerName: this.listCustomTag[i].name,
            isChecked: false,
            property: `customTag${this.listCustomTag[i].indexCustom}`,
            isSortBy: '',
            isFilterBy: ''
          });
        }
      },
      () => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * creat property displayIdDate
   */
  creatIdDate() {
    this.devices.forEach(data => {
      if (data.dateRegistration == undefined) {
        data.displayIdDate = data.registrationId;
      } else {
        let registrationDate = new Date(data.dateRegistration);
        data.displayIdDate = `${registrationDate.getFullYear()}/${(registrationDate.getMonth() + 1 + '').padStart(2, '0')}/${(
          registrationDate.getDate() + ''
        ).padStart(2, '0')}`;
      }
    });
  }

  /**
   * change display device
   */
  changeDisplayDevice() {
    this.dialogService.showDialog(DialogDisplayDeviceComponent, { data: { header: this.columns } }, result => {
      if (result) {
        this.columns = [...result];
        this.hasComment = this.columns.findIndex(data => data.headerName == 'Description' && data.isChecked) < 0;
        for (let i = this.columns.length - 1; i >= 0; i--) {
          if (!this.columns[i].isChecked) {
            this.lastColumn = this.columns[i].headerName;
            return;
          }
        }
      }
    });
  }

  /**
   * check all device
   */
  checkAll() {
    this.isCheckAll = !this.isCheckAll;
    if (this.isCheckAll) {
      this.devicesDisplay.every(data => (data.isSelected = true));
    } else {
      this.devicesDisplay.forEach(data => (data.isSelected = false));
    }
  }

  /**
   * check all Filter
   */
  checkAllFilter() {
    this.isCheckAllFilter = !this.isCheckAllFilter;
    this.listFilterDisplayOrigin.forEach(data => (data.isChecked = this.isCheckAllFilter));
    this.listFilterDisplay = [...this.listFilterDisplayOrigin];
  }

  /**
   * control checkBox check all filter when uncheck and checked
   */
  controlCheckBoxCheckAllFilter() {
    if (this.listFilterDisplayOrigin.some(filter => !filter.isChecked)) {
      this.isCheckAllFilter = false;
      return;
    }
    this.isCheckAllFilter = true;
  }

  /**
   * check device
   * @param device device selected
   */
  changeChecked(device: Device) {
    device.isSelected = !device.isSelected;
    this.checkCheckAll();
  }

  /**
   * check check all device
   */
  checkCheckAll() {
    if (this.devicesDisplay.length == 0) {
      this.isCheckAll = false;
      return;
    }
    this.isCheckAll = this.devicesDisplay.every(device => device.isSelected);
  }

  /**
   * add device
   */
  addDevice() {
    /**
     * show dialog add device
     */
    this.isChangedData = true;
    this.dialogService.showDialog(DialogDeviceManagerComponent, { data: {} }, result => {
      this.isChangedData = false;
      if (result) {
        this.deviceService.addDevice(result).subscribe(
          data => {
            this.devices.push(data);
            this.devicesDisplay.push(data);
            this.creatIdDate();
            this.checkCheckAll();
            this.devicesFilterDisplay = [...this.devices];
            this.deviceSelected = data;
          },
          error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `An error has occurred. Please try again.`
              }
            });
          }
        );
      }
    });
  }

  /**
   * edit device
   */
  editDevice() {
    /**
     * if no device selected
     */
    if (!this.deviceSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Please select device.`
        }
      });
      return;
    }
    /**
     * show dialog edit device
     */
    this.isChangedData = true;
    this.dialogService.showDialog(DialogDeviceManagerComponent, { data: { device: this.deviceSelected } }, result => {
      this.isChangedData = false;
      if (result) {
        this.deviceService.editDevice(result).subscribe(
          data => {
            let index = this.devices.findIndex(deviceData => deviceData.id == data.id);
            if (index != -1) {
              this.devices[index] = data;
            }
            let indexDeviceDisplay = this.devicesDisplay.findIndex(deviceData => deviceData.id == data.id);
            if (indexDeviceDisplay != -1) {
              this.devicesDisplay[indexDeviceDisplay] = data;
            }
            this.deviceSelected = data;
          },
          error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `An error has occurred. Please try again.`
              }
            });
          }
        );
      }
    });
  }

  /**
   * delete device
   */
  deleteDevice(): void {
    /**
     * if no device selected
     */
    let listDeviceDelete = this.devicesDisplay.filter(data => data.isSelected);
    if (listDeviceDelete.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Please select device.`
        }
      });
    } else {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text:
              listDeviceDelete.length > 1
                ? 'Do you want to delete checked devices?'
                : `Do you want to delete ${listDeviceDelete[0]?.deviceId != '' ? listDeviceDelete[0]?.deviceId : 'checked device'}?`,
            button1: `Yes`,
            button2: `No`,
            title: 'Confirmation'
          }
        },
        /**
         * delete device
         */
        async result => {
          if (!result) {
            return;
          }

          let errMessages = [];
          for (let i = 0; i < listDeviceDelete.length; i++) {
            let deviceDelete = listDeviceDelete[i];
            let error = await this.groupDeviceRemove(deviceDelete);

            if (!error) {
              this.deviceService.deleteDevice(deviceDelete).subscribe(
                () => {
                  this.devices = this.devices.filter(device => device.id != deviceDelete.id);
                  this.devicesDisplay = [...this.devices];
                  this.checkCheckAll();
                  this.deviceSelected = this.devices.length ? this.devices[0] : undefined;
                },
                () => {
                  this.dialogService.showDialog(DialogMessageComponent, {
                    data: {
                      title: `Error`,
                      text: `An error has occurred. Please try again.`
                    }
                  });
                  return;
                }
              );
            } else {
              let index = this.devices.findIndex(device => device.id == deviceDelete.id);
              if (index != -1) {
                this.devices[index].isChecked = false;
              }
              this.devicesDisplay = [...this.devices];
              errMessages = _.concat(errMessages, error);
            }
          }

          if (errMessages.length) {
            this.dialogService.showDialog(DialogSimpleSignageMessageComponent, {
              data: {
                title: 'Error',
                texts: errMessages
              }
            });
          }
        }
      );
    }
  }

  /**
   * call API group_device_remove
   * @param device
   * @returns
   */
  private async groupDeviceRemove(device: any): Promise<any> {
    return new Promise<any>(async resolve => {
      if (device.customTag0 || device.customTag1) {
        const promiseCustomtag0 = device.customTag0
          ? this.apiCustomerService
              .groupDeviceRemove(Helper.createPayloadGroupDevice(device.customTag0.groupId, [device.registrationId]))
              .toPromise()
          : Promise.resolve({});
        const promiseCustomtag1 = device.customTag1
          ? this.apiCustomerService
              .groupDeviceRemove(Helper.createPayloadGroupDevice(device.customTag1.groupId, [device.registrationId]))
              .toPromise()
          : Promise.resolve({});
        Promise.all([promiseCustomtag0, promiseCustomtag1])
          .then(() => {
            resolve(undefined);
          })
          .catch(() => {
            resolve(`${device.registrationId}: ${'Group device remove failed.'}`);
          });
      } else {
        resolve(undefined);
      }
    });
  }

  /**
   * show up or hidden button show popup sort filter
   */
  sortAndFilter() {
    this.isSortAndFilter = !this.isSortAndFilter;
    this.isSort = '';
    if (!this.isSortAndFilter) {
      this.fetchDeviceData();
      this.listSorted.length = 0;
    }
  }

  /**
   * add new custom tag
   */
  addNewCustomTag() {
    this.deviceSelected = undefined;
    if (this.listCustomTag.length == 6) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Can't have more than 6 custom tags.`
        }
      });
      return;
    }
    this.isChangedData = true;
    this.dialogService.showDialog(DialogEditGroupComponent, {}, result => {
      this.isChangedData = false;
      this.updateHeader();
    });
  }

  /**
   * edit custom tag device
   */
  editCustomTag(): void {
    this.deviceSelected = undefined;
    if (this.listCustomTag.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Please add a new custom tag.`
        }
      });
      return;
    }
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogListCustomTagComponent,
      {
        data: {
          devices: this.devices,
          accountId: this.getAccountId()
        }
      },
      result => {
        this.isChangedData = false;
        this.fetchDeviceData();
        this.isCheckAll = false;
      }
    );
  }

  /**
   * select custom tag
   */
  selectCustomTag(): void {
    if (this.listCustomTag.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Please add new custom tag.`
        }
      });
      return;
    }
    let devicesSelected = this.devicesDisplay.filter(data => data.isSelected);
    if (!devicesSelected.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Please select devices.`
        }
      });
      return;
    }
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogSelectGroupComponent,
      {
        data: {
          devicesSelected: devicesSelected
        }
      },
      result => {
        this.isChangedData = false;
        if (result) {
          this.devicesDisplay.forEach(data => {
            if (data.isSelected && result[this.INDEX_DEVICE_IDS].includes(data.id)) {
              data[`customTag${result[this.INDEX_CUSTOM_TAG_SELECTED].indexCustom}`] = result[this.INDEX_ELEMENT_SELECTED];
              data.isSelected = false;
              this.deviceService.editDevice(data).subscribe(
                data => {
                  this.checkCheckAll();
                },
                error => {
                  this.dialogService.showDialog(DialogMessageComponent, {
                    data: {
                      title: `Error`,
                      text: `An error has occurred. Please try again.`
                    }
                  });
                }
              );
            } else {
              data.isSelected = false;
            }
          });
        }
      }
    );
  }

  /**
   * select device
   * @param device device selected
   */
  selectDevice(device: Device) {
    this.deviceSelected = device;
  }

  /**
   * sort basic
   * @param propety propety sorted
   * @param type type sort
   */
  sortProperty(propety: string, type: string) {
    if (propety == 'registrationId') {
      propety = 'displayIdDate';
    }
    this.listSorted = [[propety], [type]];
    this.devicesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.propertyShowUp = Constant.EMPTY;
    this.numberClickOutSide = 0;
    let columnsSorted = this.columns.filter(data => data.isSortBy != '');
    if (columnsSorted.length !== 0) {
      columnsSorted.forEach(element => (element.isSortBy = ''));
    }
    this.columns.find(data => data.property == propety).isSortBy = type;
  }

  /**
   * show popup sort filter
   * @param property name column
   */
  showUp(property: string, event) {
    if (this.propertyShowUp == property) {
      this.propertyShowUp = Constant.EMPTY;
      this.numberClickOutSide = 0;
      return;
    }
    this.propertyShowUp = property;
    this.featchFilterData(property);
    this.isCheckPopup = event.clientX + Constant.WIDTH_POPUP > this.table.nativeElement.offsetWidth;
  }

  /**
   * hidden popup when click out side
   */
  hiddenPopup() {
    this.numberClickOutSide++;
    if (this.numberClickOutSide == 2) {
      this.propertyShowUp = Constant.EMPTY;
      this.numberClickOutSide = 0;
    }
  }

  /**
   * get array not duplicate value
   * @param array
   * @param property
   */
  filterBy = function(array, property) {
    var seen = new Set();
    return array.filter(item =>
      property?.startsWith('customTag')
        ? !seen.has(item[property]?.name) && seen.add(item[property]?.name)
        : !seen.has(item[property]) && seen.add(item[property])
    );
  };

  /**
   * featch data filter to pop up
   * @param property column show popup
   */
  featchFilterData(property: string) {
    // if not last column filter
    if (this.lastFiltered != property) {
      this.listFilterDisplay = [];
      let listDeviceGetOptionFilter = this.devicesFilterDisplay.filter(data => data.lastFilter == undefined || data.lastFilter == property);
      let listDeviceOptionFilter: Device[] = this.filterBy(listDeviceGetOptionFilter, property);
      for (let i = 0; i < listDeviceOptionFilter.length; i++) {
        //get list option filter
        this.listFilterDisplay[i] = {
          isChecked: !listDeviceOptionFilter[i].isFilter,
          value: property?.startsWith('customTag') ? listDeviceOptionFilter[i][property]?.name : listDeviceOptionFilter[i][property]
        };
      }
      // if is last column filter
    } else {
      this.listFilterDisplay = this.listCurrentFilter[property];
    }
    // get list memorize checked
    this.listFilterDisplayOrigin = this.listFilterDisplay.map(x => Object.assign({}, x));
  }

  /**
   * change checked
   * @param index index of option filter
   */
  selecteDeviceFilter(index: number) {
    this.listFilterDisplayOrigin[index].isChecked = !this.listFilterDisplayOrigin[index].isChecked;
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * set lastFilter for device to filter or unfilter
   * @param currnetFilter list option filter property
   * @param property column filtering
   */
  getCurrentFilter(currnetFilter: listFilter[], property: string) {
    for (let i = 0; i < currnetFilter.length; i++) {
      if (!currnetFilter[i].isChecked) {
        let arr = this.devices.filter(data =>
          property?.startsWith('customTag') ? data[property]?.name == currnetFilter[i].value : data[property] == currnetFilter[i].value
        );
        arr.forEach(element => {
          element.isFilter = true;
          if (element.lastFilter == undefined) {
            element.lastFilter = property;
          }
        });
      } else {
        let arr = this.devices.filter(data =>
          property?.startsWith('customTag') ? data[property]?.name == currnetFilter[i].value : data[property] == currnetFilter[i].value
        );
        arr.forEach(element => {
          if (element.lastFilter == property) {
            element.isFilter = false;
            element.lastFilter = undefined;
          }
        });
      }
    }
  }

  /**
   * filter device
   * @param property
   */
  filterDevice(property: string) {
    this.numberClickOutSide = 0;
    // do not filter all
    if (this.listFilterDisplayOrigin.findIndex(data => data.isChecked) < 0) {
      this.propertyShowUp = Constant.EMPTY;
      this.isCheckAllFilter = true;
      return;
    }
    this.isFilter = true;
    this.columns.find(data => data.property == property).isFilterBy = property;
    this.lastFiltered = property;
    // if is not clear last column filter
    if (!this.isClear) {
      this.listFilterDisplay = [...this.listFilterDisplayOrigin];
    }
    // if list option filter checked all or clear last column filter
    if (this.listFilterDisplay.findIndex(data => !data.isChecked) < 0) {
      delete this.listCurrentFilter[property];
      this.lastFiltered = Object.keys(this.listCurrentFilter)
        .slice(-1)
        .pop();
      this.isClear = false;
      this.isFilter = false;
      this.columns.find(data => data.property == property).isFilterBy = '';
    } else {
      // if filter a column was filtered
      if (
        this.listCurrentFilter[property] &&
        this.lastFiltered != Object.keys(this.listCurrentFilter)[Object.keys(this.listCurrentFilter).length - 1]
      ) {
        let keys = Object.keys(this.listCurrentFilter);
        let nextProperTyFilter = keys[keys.indexOf(property) + 1];
        this.devices.forEach(element => {
          if (element.lastFilter == property) {
            element.lastFilter = nextProperTyFilter;
          }
        });
        let listDeviceGetOptionFilter = this.devices.filter(data => data.lastFilter == nextProperTyFilter || data.lastFilter == undefined);
        let listDeviceOptionFilter = this.filterBy(listDeviceGetOptionFilter, nextProperTyFilter);
        let listOptionFilterNew: Array<listFilter> = new Array<listFilter>();
        for (let i = 0; i < listDeviceOptionFilter.length; i++) {
          listOptionFilterNew[i] = {
            isChecked: listDeviceOptionFilter[i].lastFilter == undefined,
            value: nextProperTyFilter?.startsWith('customTag')
              ? listDeviceOptionFilter[i][nextProperTyFilter]?.name
              : listDeviceOptionFilter[i][nextProperTyFilter]
          };
        }
        this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
        delete this.listCurrentFilter[property];
      }
      // set list option filter property
      this.listCurrentFilter[property] = this.listFilterDisplay;
    }
    this.getCurrentFilter(this.listFilterDisplay, property);
    // get list device show up on screen
    this.devices.filter(data => data.lastFilter !== undefined).forEach(data => (data.isSelected = false));
    this.devicesDisplay = this.devices.filter(data => data.lastFilter == undefined);
    this.devicesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.devicesFilterDisplay = [...this.devicesDisplay];
    this.propertyShowUp = Constant.EMPTY;
    this.checkCheckAll();
  }

  /**
   * clear filter
   * @param property name of column clear filter
   */
  clearFilter(property: string) {
    this.columns.find(data => data.property == property).isFilterBy = '';
    this.isFilter = false;
    // set all option in list is true
    this.listCurrentFilter[property]?.forEach(element => {
      element.isChecked = true;
    });
    // if is last column filter
    if (property == this.lastFiltered) {
      this.isClear = true;
      this.filterDevice(property);
      // if is not last column filter
    } else {
      let keys = Object.keys(this.listCurrentFilter);
      let nextProperTyFilter = keys[keys.indexOf(property) + 1];
      // set lastFilter is next column filter
      this.devices.forEach(element => {
        if (element.lastFilter == property) {
          element.isFilter = false;
          element.lastFilter = nextProperTyFilter;
        }
      });
      // get new list option filter for next property filter in listCurrentFilter
      let listDeviceGetOptionFilter = this.devices.filter(data => data.lastFilter == nextProperTyFilter || data.lastFilter == undefined);
      let listDeviceOptionFilter = this.filterBy(listDeviceGetOptionFilter, nextProperTyFilter);
      let listOptionFilterNew: Array<listFilter> = new Array<listFilter>();
      for (let i = 0; i < listDeviceOptionFilter.length; i++) {
        listOptionFilterNew[i] = {
          isChecked: listDeviceOptionFilter[i].lastFilter == undefined,
          value: nextProperTyFilter?.startsWith('customTag')
            ? listDeviceOptionFilter[i][nextProperTyFilter]?.name
            : listDeviceOptionFilter[i][nextProperTyFilter]
        };
      }
      listOptionFilterNew.forEach(element => {
        for (let i = 0; i < this.listCurrentFilter[nextProperTyFilter]?.length; i++) {
          if (element.value == this.listCurrentFilter[nextProperTyFilter][i].value) {
            element.isChecked = this.listCurrentFilter[nextProperTyFilter][i].isChecked;
          }
        }
      });
      // set new list option filter for next property filter in listCurrentFilter
      this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
      this.propertyShowUp = Constant.EMPTY;
      this.numberClickOutSide = 0;
      delete this.listCurrentFilter[property];
      this.checkCheckAll();
    }
  }

  /**
   * show custom sort dialog and sort
   */
  showCustomSort() {
    this.propertyShowUp = Constant.EMPTY;
    this.dialogService.showDialog(DialogCustomSortComponent, { data: { list: [this.listSorted, this.columns] } }, result => {
      if (result) {
        this.listSorted = result;
        for (let i = 0; i < this.columns.length; i++) {
          let index = this.listSorted[0].findIndex(column => column == this.columns[i]?.property);
          if (index == -1) {
            this.columns[i].isSortBy = '';
          } else {
            this.columns[i].isSortBy = this.listSorted[1][index];
          }
        }
        // sort
        this.devicesDisplay.sort(this.dynamicSortMultiple(result));
      }
    });
    this.numberClickOutSide = 0;
  }

  /**
   * sort multiple
   * @param result list properties and sort type sorted
   */
  dynamicSortMultiple(result: any) {
    return function(obj1, obj2) {
      let output = 0,
        i = 0;
      while (output == 0 && i < result[0]?.length) {
        let a = obj1[result[0][i]];
        let b = obj2[result[0][i]];
        if (result[1][i] === 'DESC') {
          if (result[0][i]?.startsWith('customTag')) {
            a = obj1[result[0][i]] == null ? '' : obj1[result[0][i]].name;
            b = obj2[result[0][i]] == null ? '' : obj2[result[0][i]].name;
          }
          output = a > b ? -1 : a < b ? 1 : 0;
        } else {
          if (result[0][i]?.startsWith('customTag')) {
            a = obj1[result[0][i]] == null ? '' : obj1[result[0][i]].name;
            b = obj2[result[0][i]] == null ? '' : obj2[result[0][i]].name;
          }
          output = a > b ? 1 : a < b ? -1 : 0;
        }
        i++;
      }
      return output;
    };
  }

  /**
   * get account id
   * @returns
   */
  private getAccountId(): any {
    return this.commonObject.tenantName?.length == this.LENGTH_ACCOUNT_OLD ? this.ACCOUNT_OLD : this.commonObject.tenantName.toUpperCase();
  }
}

/**
 * option filter
 */
export interface listFilter {
  isChecked: boolean;
  value: string;
}

/**
 * list of list options filtered
 */
export interface IHash {
  [details: string]: listFilter[];
}
