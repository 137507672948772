import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Helper } from 'app/common/helper';
import { Constant, ScreenNameEnum } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { ScheduleMergeService } from 'app/service/schedule-merge.service';
import { Color } from '../entity/color';
import { throttleTime } from 'rxjs/operators';
@Component({
  selector: 'app-color-picker-no-hide',
  templateUrl: './color-picker-no-hide.component.html',
  styleUrls: ['./color-picker-no-hide.component.scss']
})
export class ColorPickerNoHideComponent implements AfterViewInit, OnChanges {
  @Input()
  public inputColor: string;
  @Input()
  public isFontColor: boolean;
  @Output()
  public hideColorPicker = new EventEmitter<boolean>();

  public showed = false;
  public color: Color;
  public alpha: string;
  public colorxy: any;

  public presetColors: Array<Color>;
  public recentColors: Array<Color> = new Array<Color>();

  ScreenNameEnum = ScreenNameEnum;
  @Input() public dataHighlight: any;
  constructor(public dataService: DataService, private scheduleMergeService: ScheduleMergeService) {
    // is change color from LCD
    dataService.currentData.subscribe(data => {
      if ((data[0] == Constant.FONT_COLOR && this.isFontColor) || (data[0] == Constant.BACKGROUND_COLOR && !this.isFontColor)) {
        this.updateRecentColors(this.color);
        this.getColorData(data[1]);
        this.inputColor = this.color.toRgbaHex(this.color);
      }
    });
    this.color = new Color(0, 0, 0, '0');
    this.alpha = '1';
    this.initPresetColors();
    this.scheduleMergeService.actionUpdateColor.subscribe(data => {
      if (
        this.inputColor != data.color
        // this.inputColor != data.color &&
        // ((data.colorxy && this.colorxy && this.colorxy != data.colorxy) || !this.colorxy || !data.colorxy)
      ) {
        this.dataHighlight = data;
        this.inputColor = data.color;
        this.color = Color.fromHex(data.color);
        // this.updateRecentColors(this.color);
        this.showed = true;
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.inputColor) {
      this.getColorData(this.inputColor);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputColor']) {
      if (this.inputColor) {
        this.getColorData(this.inputColor);
      }
    }
    if (changes['dataHighlight'] && changes['dataHighlight'].isFirstChange()) {
      if (this.dataHighlight) {
        // if (
        //   this.inputColor != this.dataHighlight.color &&
        //   ((this.dataHighlight.colorxy && this.colorxy && this.colorxy != this.dataHighlight.colorxy) ||
        //     !this.colorxy ||
        //     !this.dataHighlight.colorxy)
        // ) {
        //   this.inputColor = this.dataHighlight.color;
        //   this.color = Color.fromHex(this.dataHighlight.color);
        //   this.showed = true;
        // }
        if (this.inputColor != this.dataHighlight.color) {
          this.inputColor = this.dataHighlight.color;
          this.color = Color.fromHex(this.dataHighlight.color);
          this.showed = true;
        }
      }
    }
  }

  /**
   * toggle picker
   */
  public togglePicker() {
    this.showed = !this.showed;
    this.hideColorPicker.emit(this.showed);
    if (!this.showed) {
      this.updateRecentColors(this.color);
    }
  }

  /**
   * get color data
   * @param colorStr
   */
  private getColorData(colorStr: string) {
    this.color = Color.fromHex(colorStr);
    this.alpha = this.color.a;
  }

  updateColorData(e) {
    if (this.dataHighlight) {
      this.dataHighlight.color = this.color.toRgbaHex(this.color);
      this.dataHighlight.opacity = this.color.a;
      this.dataHighlight.colorxy = this.colorxy;
      this.scheduleMergeService.updateColor(this.dataHighlight);
      // this.showed = false;
    }
  }

  /**
   * hide
   * @param e
   * @returns
   */
  public hide(e: Event) {
    // this.inputColor = this.color.toRgbaHex(this.color);
    this.updateColorData(this.inputColor);
    if (!this.showed) {
      return;
    }
    this.showed = false;
    this.hideColorPicker.emit(this.showed);
    this.updateRecentColors(this.color);
  }

  /**
   * input red
   * @param data
   */
  public inputRed(data) {
    let inputValue = data.target.value;
    let redValue = inputValue != '' ? (Number.isNaN(parseInt(inputValue)) ? 0 : parseInt(inputValue)) : 0;
    if (redValue < 0) redValue = 0;
    if (redValue > 255) redValue = 255;
    this.color.r = redValue;
    this.inputColor = this.color.toRgbaHex(this.color);
    // this.updateColorData(this.inputColor);
    this.updateColorData(this.inputColor);
  }

  /**
   * input green
   * @param data
   */
  public inputGreen(data) {
    let inputValue = data.target.value;
    let greenValue = inputValue != '' ? (Number.isNaN(parseInt(inputValue)) ? 0 : parseInt(inputValue)) : 0;
    if (greenValue < 0) greenValue = 0;
    if (greenValue > 255) greenValue = 255;
    this.color.g = greenValue;
    this.inputColor = this.color.toRgbaHex(this.color);
    this.updateColorData(this.inputColor);
  }

  /**
   * input blue
   * @param data
   */
  public inputBlue(data) {
    let inputValue = data.target.value;
    let blueValue = inputValue != '' ? (Number.isNaN(parseInt(inputValue)) ? 0 : parseInt(inputValue)) : 0;
    if (blueValue < 0) blueValue = 0;
    if (blueValue > 255) blueValue = 255;
    this.color.b = blueValue;
    this.inputColor = this.color.toRgbaHex(this.color);
    this.updateColorData(this.inputColor);
  }

  /**
   * input alpha
   * @param data
   */
  public inputAlpha(data) {
    let inputValue = data.target.value;
    let alphaValue = inputValue != '' ? (Number.isNaN(parseFloat(inputValue)) ? 1 : parseFloat(inputValue)) : 0;
    if (alphaValue < 0) alphaValue = 0;
    if (alphaValue > 1) alphaValue = 1;
    this.alpha = `${alphaValue}`;
    const color = new Color(this.color.r, this.color.g, this.color.b, this.alpha);
    this.inputColor = this.color.toRgbaHex(color);
    this.color = color;
    // Helper.updateColorForArea(color, this.dataService, this.isFontColor);

    this.updateColorData(this.inputColor);
  }

  /**
   * init preset colors
   */
  private initPresetColors() {
    this.presetColors = new Array<Color>();

    this.presetColors.push(Color.fromHex('#c00000ff'));
    this.presetColors.push(Color.fromHex('#ff0000ff'));
    this.presetColors.push(Color.fromHex('#ffc000ff'));
    this.presetColors.push(Color.fromHex('#ffff00ff'));
    this.presetColors.push(Color.fromHex('#92d050ff'));
    this.presetColors.push(Color.fromHex('#00b050ff'));
    this.presetColors.push(Color.fromHex('#00b0f0ff'));
    this.presetColors.push(Color.fromHex('#0070c0ff'));
    this.presetColors.push(Color.fromHex('#002060ff'));
    this.presetColors.push(Color.fromHex('#7030a0ff'));
  }

  /**
   * choose preset
   * @param preset
   */
  public choosePreset(preset: Color) {
    this.color = preset;
    this.inputColor = this.color.toRgbaHex(this.color);
    this.updateColorData(this.inputColor);
  }

  /**
   * update recent colors
   * @param color
   */
  private updateRecentColors(color: Color) {
    if (this.recentColors.findIndex(recentColor => recentColor.equal(color)) < 0) {
      this.recentColors.push(color);
    }
    if (this.recentColors.length > 9) {
      this.recentColors = this.recentColors.slice(this.recentColors.length - 10);
    }
  }
}
