import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbDate, NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEvent } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model';
import { CustomMultipleDatePickerComponentI18n } from './custom-multiple-date-picker-i18n';

@Component({
  selector: 'custom-multiple-date-picker',
  templateUrl: './custom-multiple-date-picker.component.html',
  styleUrls: ['./custom-multiple-date-picker.component.scss'],
  providers: [{ provide: NgbDatepickerI18n, useClass: CustomMultipleDatePickerComponentI18n }]
})
export class CustomMultipleDatePickerComponent implements OnInit {
  @Input() public isClear: boolean;
  @Input() public enableActivateWeekdays: any;
  @Input() public isEnableOn: any;
  @Input() public dates: Array<string> = new Array<string>();
  @Input() public minYear: number;

  enableActivateWeekdaysDisplay: any;
  @Output() public multipleDates = new EventEmitter<any>();
  currentDate = new Date();
  selectedMonth: number;
  lastMinute: number;
  lastHour: number;
  @ViewChild('datePicker') datePicker: any;

  isOpen: boolean;
  WEEKDAYS = { sunday: 0, monday: 1, tuesday: 2, wednesday: 3, thursday: 4, friday: 5, saturday: 6 };
  date;
  readonly FORMAT_TIME = 'HH:mm';
  config: any = {
    format: this.FORMAT_TIME,
    unSelectOnClick: false,
    hideOnOutsideClick: true,
    showTwentyFourHours: true,
    minutesInterval: 10,
    hours24Format: 'HH'
  };

  constructor() {}

  ngOnInit() {
    if (!this.dates) {
      this.dates = new Array<string>();
    }
    this.enableActivateWeekdays = this.enableActivateWeekdays ?? [];
    this.currentDate = !this.dates || !this.dates.length ? new Date() : new Date(this.dates[0]);
    this.selectedMonth = this.currentDate.getMonth();
    this.date = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
    this.enableActivateWeekdaysDisplay = this.isEnableOn
      ? this.enableActivateWeekdays
      : this.getDisableActivateWeekdays(this.enableActivateWeekdays);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isClear']) {
      if (this.isClear) {
        this.currentDate = new Date();
        this.dates = new Array<string>();
        this.multipleDates.emit(this.dates);
      }
    }
  }

  // Phương thức để xác định xem một ngày có trong mảng dates hay không
  isDateSelected(event: NgbDate): boolean {
    if (!this.dates || !this.dates?.length) {
      return false;
    }
    const day = event.day.toString().padStart(2, '0');
    const month = event.month.toString().padStart(2, '0');
    const year = event.year.toString().padStart(2, '0');
    const date = `${year}-${month}-${day}`;
    return this.dates.includes(date);
  }

  /**
   * select
   * @param event
   */
  select(event: any) {
    if (event.month != this.selectedMonth) {
      return;
    }
    const day = event.day.toString().padStart(2, '0');
    const month = event.month.toString().padStart(2, '0');
    const year = event.year.toString().padStart(2, '0');
    const date = `${year}-${month}-${day}`;
    const index = this.dates.findIndex(e => e == date);
    if (index < 0) {
      this.dates.push(date);
    } else {
      this.dates.splice(index, 1);
    }
    this.dates.sort((a, b) => a.localeCompare(b));
    this.multipleDates.emit(this.dates);
  }

  /**
   * openCalendar
   */
  openCalendar() {
    this.isOpen = !this.isOpen;
    this.isEnableOn;
  }

  /**
   * isDifferentMonth
   * @param date
   * @returns
   */
  isDifferentMonth(date: NgbDateStruct): boolean {
    return date.month !== this.selectedMonth;
  }

  /**
   * onNavigate
   * @param event
   * @returns
   */
  onNavigate(event: NavigationEvent) {
    setTimeout(() => {
      if (!event) {
        return;
      }
      this.selectedMonth = event['next'].month;
      this.currentDate = new Date(event['next'].year, event['next'].month - 1);
    }, 100);
  }

  /**
   * getDisableActivateWeekdays
   * @param enableActivateWeekdays
   * @returns
   */
  getDisableActivateWeekdays(enableActivateWeekdays: any) {
    const DAY_OF_WEEK = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    if (!enableActivateWeekdays || !enableActivateWeekdays.length) {
      return DAY_OF_WEEK;
    }
    return DAY_OF_WEEK.filter(e => !enableActivateWeekdays.includes(e));
  }

  /**
   * markDisabled
   * @param date
   * @returns
   */
  markDisabled = (date: NgbDateStruct) => {
    const dayOfWeek = this.enableActivateWeekdaysDisplay.map(weekday => this.WEEKDAYS[weekday]);
    if (date.month == this.selectedMonth) {
      let day = new Date(date.year, date.month - 1, date.day).getDay();
      return dayOfWeek.includes(day);
    }
    return false;
  };

  /**
   * isDisabledDateSelected
   * @param event
   * @returns
   */
  isDisabledDateSelected(event: NgbDate) {
    return this.isDateSelected(event) && this.markDisabled(event);
  }
}
