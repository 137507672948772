import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  KeyValueDiffers,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Common } from 'app/model/entity/common';
import { SaveBusInfoDisplayEditorStateAction, SaveMainStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { BusStopService } from 'app/service/bus-stop.service';
import { CommonService } from 'app/service/common.service';
import { ExecutingService } from 'app/service/executing.service';
import { AppState } from 'app/store/app.state';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Helper } from '../../common/helper';
import {
  Constant,
  FIELD_COMPONENT,
  LinkDataPictureEnum,
  LinkDataTextEnum,
  MODULE_NAME,
  OutputOptionEnum,
  PreviewControlEnum,
  ScreenNameEnum,
  TimingOffEnum,
  TimingOnEnum,
  TypeMediaFileEnum
} from '../../config/constants';
import { DialogChangeTemplateComponent } from '../../dialog/dialog-change-template/dialog-change-template.component';
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from '../../dialog/dialog-message/dialog-message.component';
import { DialogPublishDataBusInformationComponent } from '../../dialog/dialog-publish-data-bus-information/dialog-publish-data.component-bus-information';
import { Area } from '../../model/entity/area';
import { BusStop } from '../../model/entity/bus-stop';
import { EventBusStop } from '../../model/entity/event-bus-stop';
import { Media } from '../../model/entity/media';
import { PictureArea } from '../../model/entity/picture-area';
import { Route } from '../../model/entity/route';
import { Template } from '../../model/entity/template';
import { TextArea } from '../../model/entity/text-area';
import { DataService } from '../../service/data.service';
import { DialogService } from '../../service/dialog.service';
import { DrawService } from '../../service/draw.service';
import { MenuActionService } from '../../service/menu-action.service';
import { RouteService } from '../../service/route.service';
@Component({
  selector: 'app-bus-information-display-editor',
  templateUrl: './bus-information-display-editor.component.html',
  styleUrls: ['./bus-information-display-editor.component.scss']
})
export class BusInformationDisplayEditorComponent implements OnInit, OnDestroy {
  /**
   * constants
   */
  OutputOptionEnum = OutputOptionEnum;
  TypeMediaFileEnum = TypeMediaFileEnum;
  PreviewControlEnum = PreviewControlEnum;
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  Helper = Helper;

  readonly TAG_LI_DISPLAY_1_ID = 'li-display-1';
  readonly TAG_LI_DISPLAY_2_ID = 'li-display-2';

  /**
   * list route
   */
  routes: Array<Route>;
  /**
   * route selected
   */
  routeSelected: Route;
  /**
   * true if all route are checked, false if there is at least 1 route unchecked
   */
  isCheckedAll: boolean;
  /**
   * true if select display 2
   */
  isDisplay2: boolean;
  /**
   * area of template 1
   */
  areasDisplay1: Array<Area>;
  /**
   * area of template 2
   */
  areasDisplay2: Array<Area>;
  /**
   * list of action subscriptions
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * event selected
   */
  eventSelected: EventBusStop;
  /**
   * index
   */
  index = 0;
  /**
   * busStop selected
   */
  busStopSelected: BusStop;
  /**
   * true if open setting event
   */
  isSetting: boolean = true;
  /**
   * true if open preview
   */
  isEnlargeScreen: boolean;
  /**
   * true if areaDisplay1 or areaDisplay2 is TextArea
   */
  isTextArea: boolean;
  /**
   * canvasContainerDisplay1
   */
  @ViewChild('canvasContainerDisplay1', { static: false })
  canvasContainerDisplay1: ElementRef;
  /**
   * canvasContainerDisplay2
   */
  @ViewChild('canvasContainerDisplay2', { static: false })
  canvasContainerDisplay2: ElementRef;
  /**
   * project name
   */
  projectName: string;
  /**
   * project to bus information
   */
  projectToBusInformation = Constant.EMPTY;
  /**
   * data project to bus information
   */
  dataProjectToBusInformation: Route;
  /**
   * true if playing
   */
  isPlay: boolean;
  /**
   * Constant
   */
  Constant = Constant;
  /**
   * project id
   */
  projectId: Number;
  /**
   * true if change template
   */
  isChangeTemplate: boolean;
  /**
   * differ
   */
  differ: any;
  /**
   * true if data changed
   */
  isChangedData: boolean;
  /**
   * true if show vertical
   */
  isShowVertical: boolean = true;
  /**
   * save data success
   */
  @Output() saveDataSuccess = new EventEmitter<boolean>();
  /**
   * stateOfComponent
   */
  stateOfComponent: {
    isChangeLayout: boolean;
    routes: Route[];
    routeSelected: Route;
    isCheckedAll: boolean;
    areasDisplay1: Area[];
    areasDisplay2: Area[];
    eventSelected: EventBusStop;
    index: any;
    busStopSelected: BusStop;
    isSetting: boolean;
    isEnlargeScreen: boolean;
    isTextArea: boolean;
    isChangedData: boolean;
    isPlay: boolean;
    isShowVertical: boolean;
  };
  /**
   * common object
   */
  commonObject: Common;
  constructor(
    private routeService: RouteService,
    private busStopService: BusStopService,
    private actionService: MenuActionService,
    private toast: ToastrService,
    private dialogService: DialogService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private drawService: DrawService,
    private dataService: DataService,
    private differs: KeyValueDiffers,
    public readonly store: Store<AppState>,
    private commonService: CommonService,
    public translateService: TranslateService,
    private executingService: ExecutingService
  ) {
    this.differ = this.differs.find({}).create();

    this.subscriptions.push(
      this.actionService.actionSave.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.BusInformationDisplayEditorComponent]) {
          this.saveEventsRoute(false);
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionDelete.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.BusInformationDisplayEditorComponent]) {
          this.deleteEvent();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionChangeTemplate.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.BusInformationDisplayEditorComponent]) {
          this.changeTemplate();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionChangeDisplay.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.BusInformationDisplayEditorComponent]) {
          this.changeDisplay();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionPublishData.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.BusInformationDisplayEditorComponent]) {
          this.publishData();
        }
      })
    );
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.BUS_INFORMATION_NAME) {
        this.projectToBusInformation = data[0];
        this.dataProjectToBusInformation = data[1];
      }
    });
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState.busInfoDisplayEditorState?.stateOfComponent.isChangeLayout,
            routes: componentState.busInfoDisplayEditorState?.stateOfComponent.routes,
            routeSelected: componentState.busInfoDisplayEditorState?.stateOfComponent.routeSelected,
            isCheckedAll: componentState.busInfoDisplayEditorState?.stateOfComponent.isCheckedAll,
            areasDisplay1: componentState.busInfoDisplayEditorState?.stateOfComponent.areasDisplay1,
            areasDisplay2: componentState.busInfoDisplayEditorState?.stateOfComponent.areasDisplay2,
            eventSelected: componentState.busInfoDisplayEditorState?.stateOfComponent.eventSelected,
            index: componentState.busInfoDisplayEditorState?.stateOfComponent.index,
            busStopSelected: componentState.busInfoDisplayEditorState?.stateOfComponent.busStopSelected,
            isSetting: componentState.busInfoDisplayEditorState?.stateOfComponent.isSetting,
            isEnlargeScreen: componentState.busInfoDisplayEditorState?.stateOfComponent.isEnlargeScreen,
            isTextArea: componentState.busInfoDisplayEditorState?.stateOfComponent.isTextArea,
            isChangedData: componentState.busInfoDisplayEditorState?.stateOfComponent.isChangedData,
            isPlay: componentState.busInfoDisplayEditorState?.stateOfComponent.isPlay,
            isShowVertical: componentState.busInfoDisplayEditorState?.stateOfComponent.isShowVertical
          };
        })
    );
    this.commonObject = commonService.getCommonObject();
    this.projectName = this.commonObject.projectName;
  }

  async ngOnInit() {
    this.isDisplay2 = this.commonObject.isDisPlay2OnBus;
    this.projectId = this.commonObject.projectId;
    if (this.stateOfComponent?.isChangeLayout) {
      this.handleAfterChangeLayout();
    } else {
      this.executingService.executing();
      await Helper.loadFontsToPreview(this.store, this.commonObject, this.translateService, this.dialogService);
      this.executingService.executed();
      if (this.projectToBusInformation == Constant.BUS_INFORMATION_NAME) {
        this.getRoutes(this.projectToBusInformation);
      } else {
        this.getRoutes();
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.drawService.setIsPlay(false);
    this.clearAllCanvasAreaOfRoute(this.routeSelected);
    this.store.dispatch(
      new SaveBusInfoDisplayEditorStateAction({
        isChangeLayout: true,
        routes: this.routes,
        routeSelected: this.routeSelected,
        isCheckedAll: this.isCheckedAll,
        areasDisplay1: this.areasDisplay1,
        areasDisplay2: this.areasDisplay2,
        eventSelected: this.eventSelected,
        index: this.index,
        busStopSelected: this.busStopSelected,
        isSetting: this.isSetting,
        isEnlargeScreen: this.isEnlargeScreen,
        isTextArea: this.isTextArea,
        isChangedData: this.isChangedData,
        isPlay: this.isPlay,
        isShowVertical: this.isShowVertical
      })
    );
  }

  async handleAfterChangeLayout() {
    this.routes = this.stateOfComponent.routes;
    this.routeSelected = this.stateOfComponent.routeSelected;
    this.isCheckedAll = this.stateOfComponent.isCheckedAll;
    this.areasDisplay1 = this.stateOfComponent.areasDisplay1;
    this.areasDisplay2 = this.stateOfComponent.areasDisplay2;
    this.eventSelected = this.stateOfComponent.eventSelected;
    this.index = this.stateOfComponent.index;
    this.busStopSelected = this.stateOfComponent.busStopSelected;
    this.isSetting = this.stateOfComponent.isSetting;
    this.isEnlargeScreen = this.stateOfComponent.isEnlargeScreen;
    this.isTextArea = this.stateOfComponent.isTextArea;
    this.isChangedData = this.stateOfComponent.isChangedData;
    this.isPlay = this.stateOfComponent.isPlay;
    this.isShowVertical = this.stateOfComponent.isShowVertical;
    // get element
    this.changeDetectorRef.detectChanges();

    // clear node child on preview:
    Helper.clearNodeChild(this.canvasContainerDisplay1?.nativeElement);
    Helper.clearNodeChild(this.canvasContainerDisplay2?.nativeElement);

    if (!this.areasDisplay1 && !this.areasDisplay2) {
      return;
    }
    this.clearAllCanvasAreaOfRoute(this.routeSelected);
    if (this.routeSelected.display1) {
      this.drawService.clearAllThreadDrawTemplate(this.routeSelected.display1);
      this.clearConfigLinkDataPosition(this.routeSelected.display1);
      // draw
      Helper.createCanvasTemplate(this.routeSelected.display1, this.canvasContainerDisplay1, this.renderer);
      Helper.createAllCanvasAreaTemplate(this.routeSelected.display1, this.canvasContainerDisplay1, this.renderer, false);
      // scale canvas display 1
      const divDisplay1 = document.getElementById(this.TAG_LI_DISPLAY_1_ID);
      this.calculateScaleTransformCanvas(this.canvasContainerDisplay1, this.routeSelected.display1, divDisplay1);
      if (this.busStopSelected) {
        this.getPositionEventAreas(this.routeSelected.display1, false);
        this.drawService.drawStartPreview(this.routeSelected.display1, false);
      }
    }
    if (this.routeSelected.display2 && this.isDisplay2) {
      this.drawService.clearAllThreadDrawTemplate(this.routeSelected.display2);
      this.clearConfigLinkDataPosition(this.routeSelected.display2);
      // draw
      Helper.createCanvasTemplate(this.routeSelected.display2, this.canvasContainerDisplay2, this.renderer);
      Helper.createAllCanvasAreaTemplate(this.routeSelected.display2, this.canvasContainerDisplay2, this.renderer, false);
      // scale canvas display 2
      const divDisplay2 = document.getElementById(this.TAG_LI_DISPLAY_2_ID);
      this.calculateScaleTransformCanvas(this.canvasContainerDisplay2, this.routeSelected.display2, divDisplay2);
      if (this.busStopSelected) {
        this.getPositionEventAreas(this.routeSelected.display2, true);
        this.drawService.drawStartPreview(this.routeSelected.display2, true);
      }
    }
    if (this.isPlay) {
      this.drawService.setIsPlay(this.isPlay);
    }
  }

  /**
   * get list route
   * @param value
   */
  private getRoutes(value?: string): void {
    this.routeService.getRoutesForBusInfoDisplayComponentByProjectId(this.projectId).subscribe(
      routesData => {
        this.routes = routesData.map(routeData => {
          return Helper.convertDataRoute(routeData);
        });
        if (value == this.projectToBusInformation) {
          let indexRoute = this.routes.findIndex(route => route.id == this.dataProjectToBusInformation.id);
          this.selectRoute(this.routes[indexRoute], false, null);
        } else {
          this.selectRoute(this.routes[0], false, null);
        }
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * clear all canvas area of route
   * @param route
   */
  private clearAllCanvasAreaOfRoute(route: Route): void {
    if (!route) {
      return;
    }
    var areas = Helper.getAllAreaTemplate(route.display1);
    if (this.isDisplay2) {
      areas = [...areas, ...Helper.getAllAreaTemplate(route.display2)];
    }
    areas.forEach(area => {
      if (area.checkTypeTextArea()) {
        this.drawService.clearCanvasArea(area as TextArea);
      } else {
        this.drawService.stopAVideo(area as PictureArea);
        this.stopAudioPictureArea(area as PictureArea);
      }
    });
  }

  /**
   * stop audio picture area
   * @param areaPicture PictureArea
   */
  private stopAudioPictureArea(areaPicture: PictureArea): void {
    if (areaPicture.getArea().attribute == LinkDataPictureEnum.POSITION_DRIVEN) {
      this.drawService.stopSound(areaPicture);
    } else {
      this.drawService.stopAudio(areaPicture);
    }
  }

  /**
   * save events route
   * @param isSelectedRouteOther true if save changes before selecting route other
   */
  async saveEventsRoute(isSelectedRouteOther: boolean) {
    const MAX_TEXT_LENGTH = 256;
    const routeUpdate = _.cloneDeep(this.routeSelected);
    const busStopInvalid = _.find(routeUpdate?.busStops, busStop => {
      return _.find(busStop.events, eventBusStop => {
        return eventBusStop.text?.length > MAX_TEXT_LENGTH;
      });
    });
    if (busStopInvalid) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Some values have more than ${MAX_TEXT_LENGTH} characters.`
        }
      });
      this.saveDataSuccess.emit(false);
      return;
    }
    const indexEventError = routeUpdate?.busStops?.findIndex(busStop => {
      busStop.events = busStop.events.map(eventBusStop => {
        return Helper.convertDataEventBusStopForward(eventBusStop);
      });
      const indexEventError = busStop.events.findIndex(
        event => event.outputOption == OutputOptionEnum.SECOND && (!event.duration || event.duration <= 0 || event.duration > 99)
      );
      return indexEventError > -1;
    });
    if (indexEventError > -1) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: 'Duration must be greater than 0 and must be less than or equal to 99'
        }
      });
      this.saveDataSuccess.emit(false);
      return;
    }
    delete routeUpdate?.display1?.layers;
    delete routeUpdate?.display2?.layers;
    this.busStopService.updateEvents(routeUpdate).subscribe(
      () => {
        this.toast.success(Constant.SAVE_SUCCESS, '');
        this.isChangedData = false;
        this.saveDataSuccess.emit(true);
        if (!isSelectedRouteOther) {
          this.selectRoute(
            this.routes.find(r => r.id == routeUpdate.id),
            true,
            null
          );
        }
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: 'An error has occurred'
          }
        });
        this.saveDataSuccess.emit(false);
      }
    );
  }

  /**
   * key code map
   */
  keyCodeMap = {
    17: false, // Ctrl
    18: false, // Alt
    83: false // S
  };

  /**
   * key down
   * @param e
   */
  @HostListener('document:keydown', ['$event'])
  keyDown(e) {
    if (e.keyCode in this.keyCodeMap) {
      this.keyCodeMap[e.keyCode] = true;
      // save data
      if (this.keyCodeMap[17] && this.keyCodeMap[18] && this.keyCodeMap[83]) {
        this.saveEventsRoute(false);
      }
    }
  }

  /**
   * key up
   * @param e
   */
  @HostListener('document:keyup', ['$event'])
  keyUp(e) {
    if (e.keyCode == 17) {
      this.keyCodeMap[17] = false;
    }
    if (e.keyCode == 18) {
      this.keyCodeMap[18] = false;
    }
    if (e.keyCode == 83) {
      this.keyCodeMap[83] = false;
    }
  }

  /**
   * save change Template
   * @param routes list route
   */
  async saveChangeTemplate(routes: Route[]) {
    routes.forEach(route => {
      route.busStops.forEach(busStop => {
        busStop.events = busStop.events.map(eventBusStop => {
          return Helper.convertDataEventBusStopForward(eventBusStop);
        });
      });
    });

    const promise = (route: Route) => {
      return new Promise(resolve => {
        delete route.display1?.layers;
        delete route.display2?.layers;
        this.busStopService.updateEvents(route).subscribe(
          () => {
            route.busStops.forEach(busStop => {
              busStop.events = busStop.events.map(eventBusStop => {
                return Helper.convertDataEventBusStop(eventBusStop, route);
              });
            });
            resolve('ok');
          },
          error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `An error has occurred. Please try again.`
              }
            });
            return;
          }
        );
      });
    };
    const promises = () => {
      return Promise.all(routes.map(route => promise(route)));
    };
    promises().then(ok => {
      routes = routes.map(route => {
        return Helper.convertDataRouteForward(route);
      });
      this.routeService.changeTemplateOfRoutes(routes).subscribe(
        routesData => {
          routesData.forEach(routeData => {
            let routeConverted = Helper.convertDataRoute(routeData);
            let routeIndex = this.routes.findIndex(route => route.id == routeConverted.id);
            this.routes[routeIndex] = routeConverted;
            if (this.routes[routeIndex].id == this.routeSelected.id) {
              this.selectRoute(this.routes[routeIndex], true, null);
            } else {
              this.selectRoute(this.routeSelected, true, null);
            }
          });
          this.isChangeTemplate = false;
        },
        error => {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: `Error`,
              text: `An error has occurred. Please try again.`
            }
          });
        }
      );
    });
  }

  /**
   * show column text when select route
   * @param route Route
   */
  private showColumnText(route: Route): void {
    route.busStops.forEach(busStop => {
      if (this.isDisplay2) {
        busStop.events
          .filter(eventBusStop => eventBusStop?.areaDisplay1 instanceof TextArea || eventBusStop?.areaDisplay2 instanceof TextArea)
          .forEach(event => {
            event.isText = true;
          });
        if (busStop.events.findIndex(event => event.isText) != -1) {
          this.isTextArea = true;
          return;
        }
      } else {
        busStop.events
          .filter(eventBusStop => eventBusStop?.areaDisplay1 instanceof TextArea)
          .forEach(event => {
            event.isText = true;
          });
        if (busStop.events.findIndex(event => event.isText) != -1) {
          this.isTextArea = true;
          return;
        }
      }
    });
  }

  /**
   * select route
   * @param route
   * @param isUpdate
   * @param event
   */
  selectRoute(route: Route, isUpdate: boolean, event): void {
    if (event?.target?.id === 'checkBoxRoute' || (this.routeSelected?.id == route?.id && !isUpdate)) {
      return;
    }
    if (this.isChangedData) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: `Do you want to save changes before selecting another route?`,
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.saveEventsRoute(true);
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.selectRoute(route, true, null);
              }
            });
          } else {
            this.isChangedData = false;
            this.selectRoute(route, true, null);
          }
        }
      );
    } else {
      if (this.routeSelected) {
        this.clearAllCanvasAreaOfRoute(this.routeSelected);
        this.drawService.clearAllThreadDrawTemplate(this.routeSelected.display1);
        if (this.isDisplay2) {
          this.drawService.clearAllThreadDrawTemplate(this.routeSelected.display2);
        }
      }
      // reset play
      if (this.isPlay) {
        this.isPlay = false;
        this.drawService.setIsPlay(false);
      }
      // get route selected with full data
      this.routeService.getRouteWithFullDataTemplateById(route.id).subscribe(routeData => {
        const isChecked = route.isChecked;
        route = Helper.convertDataRoute(routeData);
        route.isChecked = isChecked;
        // select route
        this.routeSelected = route;
        const routeIndex = this.routes.findIndex(route => route.id === this.routeSelected.id);
        this.routes[routeIndex] = this.routeSelected;
        // get bus stop of route
        this.busStopService.getBusStopsForBusInfoDisplayComponentByRouteId(this.routeSelected.id).subscribe(
          busStopsData => {
            this.routeSelected.busStops = Helper.convertDataBusStopsBID(busStopsData, this.routeSelected);
            this.busStopSelected = this.routeSelected.busStops[0];
            this.routeSelected.busStops.forEach(busStop => {
              if (busStop.events.length == 0) {
                busStop.events.push(new EventBusStop(route.id, busStop.id, route?.display1?.id, route?.display2?.id, busStop.orderNo));
              }
            });
            this.handleDataWhenRouteSelected();
          },
          error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `An error has occurred. Please try again.`
              }
            });
          }
        );
      });
    }
  }

  /**
   * handle data when select route
   */
  private handleDataWhenRouteSelected(): void {
    // get area from display 1 or display 2
    if (this.routeSelected.display1) {
      // get list area display 1 (link text or link picture: position driven)
      this.areasDisplay1 = Helper.getAllAreaTemplate(this.routeSelected.display1).filter(
        area =>
          area &&
          !area.isFix &&
          (area.getArea().attribute == LinkDataPictureEnum.POSITION_DRIVEN ||
            area.getArea().linkReferenceData == LinkDataTextEnum.POSITION_DRIVEN)
      );
    } else {
      this.areasDisplay1 = undefined;
    }
    if (this.routeSelected.display2) {
      // get list area display 2 (link text or link picture: position driven)
      this.areasDisplay2 = Helper.getAllAreaTemplate(this.routeSelected.display2).filter(
        area =>
          area &&
          !area.isFix &&
          (area.getArea().attribute == LinkDataPictureEnum.POSITION_DRIVEN ||
            area.getArea().linkReferenceData == LinkDataTextEnum.POSITION_DRIVEN)
      );
    } else {
      this.areasDisplay2 = undefined;
    }

    // reset a variable isTextArea
    this.isTextArea = false;
    // reset a variable isText of eventBusStop
    this.routeSelected.busStops.forEach(busStop => {
      busStop.events.forEach(eventBusStop => {
        if (this.areasDisplay1 && this.areasDisplay1.findIndex(area => area.id == eventBusStop.areaDisplay1?.id) == -1) {
          eventBusStop.areaDisplay1 = null;
        }
        if (this.areasDisplay2 && this.areasDisplay2.findIndex(area => area.id == eventBusStop.areaDisplay2?.id) == -1) {
          eventBusStop.areaDisplay2 = null;
        }
        eventBusStop.isText = false;
      });
    });
    this.routeSelected.busStops?.forEach(busStop => {
      busStop.areaDisplayList1 = _.cloneDeep(this.areasDisplay1);
      busStop.areaDisplayList2 = _.cloneDeep(this.areasDisplay2);
      let idAreaList1 = [];
      let idAreaList2 = [];
      busStop.events?.forEach(eventBusStop => {
        if (eventBusStop.areaDisplay1 !== null) {
          idAreaList1.push(eventBusStop?.areaDisplay1?.id);
        }
        if (eventBusStop.areaDisplay2 !== null) {
          idAreaList2.push(eventBusStop?.areaDisplay2?.id);
        }
      });
      busStop.areaDisplayList1?.forEach(area1 => {
        if (idAreaList1.indexOf(area1.id) !== -1) {
          area1.isActive = false;
        }
      });
      busStop.areaDisplayList2?.forEach(area2 => {
        if (idAreaList2.indexOf(area2.id) !== -1) {
          area2.isActive = false;
        }
      });
      busStop.events.forEach(event => {
        if (
          busStop.areaDisplayList1?.every(area => area.id != event.areaDisplay1?.id) &&
          busStop.areaDisplayList2?.every(area => area.id != event.areaDisplay2?.id)
        ) {
          event.medias = [];
        }
      });
    });

    // check display column text
    this.showColumnText(this.routeSelected);

    // reset value event selected
    this.eventSelected = undefined;

    // get element
    this.changeDetectorRef.detectChanges();

    // clear node child on preview:
    Helper.clearNodeChild(this.canvasContainerDisplay1?.nativeElement);
    Helper.clearNodeChild(this.canvasContainerDisplay2?.nativeElement);

    if (!this.areasDisplay1 && !this.areasDisplay2) {
      return;
    }

    this.clearAllCanvasAreaOfRoute(this.routeSelected);
    this.drawService.clearAllThreadDrawTemplate(this.routeSelected.display1);
    this.drawService.clearAllThreadDrawTemplate(this.routeSelected.display2);
    if (this.routeSelected.display1) {
      // scale canvas display 1
      const divDisplay1 = document.getElementById(this.TAG_LI_DISPLAY_1_ID);
      this.calculateScaleTransformCanvas(this.canvasContainerDisplay1, this.routeSelected.display1, divDisplay1);
      // draw
      Helper.createCanvasTemplate(this.routeSelected.display1, this.canvasContainerDisplay1, this.renderer);
      Helper.createAllCanvasAreaTemplate(this.routeSelected.display1, this.canvasContainerDisplay1, this.renderer, false);
      this.handleStart(this.routeSelected.display1, false);
    }
    if (this.routeSelected.display2 && this.isDisplay2) {
      // scale canvas display 2
      const divDisplay2 = document.getElementById(this.TAG_LI_DISPLAY_2_ID);
      this.calculateScaleTransformCanvas(this.canvasContainerDisplay2, this.routeSelected.display2, divDisplay2);
      // draw
      Helper.createCanvasTemplate(this.routeSelected.display2, this.canvasContainerDisplay2, this.renderer);
      Helper.createAllCanvasAreaTemplate(this.routeSelected.display2, this.canvasContainerDisplay2, this.renderer, false);
      this.handleStart(this.routeSelected.display2, true);
    }
  }

  /**
   * change template
   */
  changeTemplate(): void {
    if (this.isPlay) {
      return;
    }
    var routesSelected = this.routes.filter(route => route.isChecked);
    if (routesSelected.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select route.' } });
    } else {
      let display1 = undefined;
      let display2 = undefined;
      if (routesSelected.findIndex(route => !route.display1) == -1) {
        let display1Ids = routesSelected.map(route => route.display1.id);
        if (display1Ids.every(routeId => routeId == display1Ids[0])) {
          display1 = routesSelected[0].display1;
        }
      }
      if (this.isDisplay2) {
        if (routesSelected.findIndex(route => !route.display2) == -1) {
          let display2Ids = routesSelected.map(route => route.display2.id);
          if (display2Ids.every(routeId => routeId == display2Ids[0])) {
            display2 = routesSelected[0].display2;
          }
        }
      }

      this.isChangedData = true;
      this.dialogService.showDialog(
        DialogChangeTemplateComponent,
        {
          data: this.isDisplay2
            ? {
                display1: display1,
                display2: display2,
                isDisplay2: this.isDisplay2,
                projectId: this.projectId,
                screen: ScreenNameEnum.ON_BUS_DISPLAY
              }
            : {
                display1: display1,
                isDisplay2: this.isDisplay2,
                projectId: this.projectId,
                screen: ScreenNameEnum.ON_BUS_DISPLAY
              }
        },
        async result => {
          this.isChangedData = false;
          if (result) {
            let display1Old = this.routeSelected.display1;
            routesSelected.forEach(route => {
              if (this.isDisplay2) {
                let display2Old = this.routeSelected.display2;
                route.display1 = result[0]?.id < 0 ? null : result[0];
                route.display2 = result[1]?.id < 0 ? null : result[1];
                route.busStops.forEach(busStop => {
                  if (display2Old?.id != route.display2?.id) {
                    busStop.events.forEach(eventBusStop => {
                      eventBusStop.areaDisplay2 = null;
                    });
                  }
                  if (display1Old?.id != route.display1?.id) {
                    busStop.events.forEach(eventBusStop => {
                      eventBusStop.areaDisplay1 = null;
                    });
                  }
                  if (display1Old?.id != route.display1?.id && display2Old?.id != route.display2?.id) {
                    busStop.events.forEach(eventBusStop => {
                      eventBusStop.medias = [];
                      eventBusStop.isText = false;
                      eventBusStop.text = '';
                    });
                  }
                });
              } else {
                route.display1 = result?.id < 0 ? null : result;
                route.busStops.forEach(busStop => {
                  if (display1Old?.id != route.display1?.id) {
                    busStop.events.forEach(eventBusStop => {
                      eventBusStop.areaDisplay1 = null;
                      eventBusStop.medias = [];
                      eventBusStop.isText = false;
                      eventBusStop.text = '';
                    });
                  }
                });
              }
              route.busStops.forEach(busStop => {
                busStop.events.forEach(eventBusStop => {
                  eventBusStop.templateId = route.display1?.id;
                  eventBusStop.templateId2 = route.display2?.id;
                });
              });
            });
            this.isChangeTemplate = true;
            await this.saveChangeTemplate(routesSelected);
            this.isCheckedAll = true;
            this.checkAll();
          }
        }
      );
    }
  }

  /**
   * change display
   */
  changeDisplay(): void {
    this.isDisplay2 = !this.isDisplay2;
    this.commonObject.isDisPlay2OnBus = this.isDisplay2;
    this.store.dispatch(
      new SaveMainStateAction({
        common: this.commonObject
      })
    );
    if (!this.routeSelected) {
      return;
    }
    // check display column text
    if (!this.isDisplay2) {
      this.isTextArea = this.routeSelected.busStops.some(busStop => {
        return !!busStop.events.find(eventOfBusStop => eventOfBusStop?.areaDisplay1 instanceof TextArea);
      });
    } else {
      this.routeSelected.busStops.forEach(busStop => {
        busStop.events
          .filter(eventOfBusStop => eventOfBusStop?.areaDisplay1 instanceof TextArea || eventOfBusStop?.areaDisplay2 instanceof TextArea)
          .forEach(event => {
            event.isText = true;
          });
        if (busStop.events.findIndex(event => event.isText) > -1) {
          this.isTextArea = true;
        }
      });
    }

    this.busStopSelected = this.routeSelected.busStops[0];
    // reset play
    if (this.isPlay) {
      this.isPlay = false;
      this.drawService.setIsPlay(false);
    }

    // get element
    this.changeDetectorRef.detectChanges();

    // clear node child on preview:
    Helper.clearNodeChild(this.canvasContainerDisplay1?.nativeElement);
    Helper.clearNodeChild(this.canvasContainerDisplay2?.nativeElement);

    if (!this.areasDisplay1 && !this.areasDisplay2) {
      return;
    }
    this.clearAllCanvasAreaOfRoute(this.routeSelected);
    if (
      Helper.isVideo(_.get(this.eventSelected, 'medias[0]', undefined)) ||
      Helper.isSound(_.get(this.eventSelected, 'medias[0]', undefined))
    ) {
      _.set(this.eventSelected, 'outputOption', OutputOptionEnum.ONCE);
    } else {
      _.set(this.eventSelected, 'outputOption', OutputOptionEnum.ONE_STOP);
    }
    this.drawService.clearAllThreadDrawTemplate(this.routeSelected.display1);
    this.drawService.clearAllThreadDrawTemplate(this.routeSelected.display2);
    this.clearConfigLinkDataPosition(this.routeSelected.display1);
    this.clearConfigLinkDataPosition(this.routeSelected.display2);
    if (this.routeSelected.display1) {
      // scale canvas display 1
      const divDisplay1 = document.getElementById(this.TAG_LI_DISPLAY_1_ID);
      this.calculateScaleTransformCanvas(this.canvasContainerDisplay1, this.routeSelected.display1, divDisplay1);
      // draw
      Helper.createCanvasTemplate(this.routeSelected.display1, this.canvasContainerDisplay1, this.renderer);
      Helper.createAllCanvasAreaTemplate(this.routeSelected.display1, this.canvasContainerDisplay1, this.renderer, false);
      this.handleStart(this.routeSelected.display1, false);
    }
    if (this.routeSelected.display2 && this.isDisplay2) {
      // scale canvas display 2
      const divDisplay2 = document.getElementById(this.TAG_LI_DISPLAY_2_ID);
      this.calculateScaleTransformCanvas(this.canvasContainerDisplay2, this.routeSelected.display2, divDisplay2);
      // draw
      Helper.createCanvasTemplate(this.routeSelected.display2, this.canvasContainerDisplay2, this.renderer);
      Helper.createAllCanvasAreaTemplate(this.routeSelected.display2, this.canvasContainerDisplay2, this.renderer, false);
      this.handleStart(this.routeSelected.display2, true);
    }
  }

  /**
   * handle publish data
   */
  publishData(): void {
    var routesSelected = this.routes.filter(route => route.isChecked);
    if (routesSelected.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select route.' } });
      return;
    }
    if (routesSelected.some(route => !route.display1 && !route.display2)) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'There is a route with no template set.' } });
      return;
    }
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogPublishDataBusInformationComponent,
      { data: { routes: routesSelected, projectName: this.projectName } },
      result => {
        this.isChangedData = false;
      }
    );
  }

  /**
   * check or uncheck a route
   * @param index index of check-changed route
   * @param e
   */
  changeChecked(index: number, e): void {
    e.stopPropagation();
    this.routes[index].isChecked = !this.routes[index].isChecked;
    this.isCheckedAll = this.routes.every(route => route.isChecked);
  }

  /**
   * check or uncheck all route
   */
  checkAll(): void {
    this.isCheckedAll = !this.isCheckedAll;
    this.routes.forEach(route => (route.isChecked = this.isCheckedAll));
  }

  /**
   * add event
   * @param busStop BusStop
   */
  addEvent(busStop: BusStop): void {
    if (this.isPlay) {
      return;
    }
    let check1 = false;
    let check2 = false;
    let idAreaList1 = [];
    let idAreaList2 = [];
    busStop.events?.forEach(eventBusStop => {
      if (eventBusStop.areaDisplay1 !== null) {
        idAreaList1.push(eventBusStop?.areaDisplay1?.id);
      }
      if (eventBusStop.areaDisplay2 !== null) {
        idAreaList2.push(eventBusStop?.areaDisplay2?.id);
      }
    });
    busStop.areaDisplayList1?.forEach(area1 => {
      if (idAreaList1.indexOf(area1.id) !== -1) {
        area1.isActive = false;
      }
      if (area1.isActive) {
        check1 = true;
      }
    });
    busStop.areaDisplayList2?.forEach(area2 => {
      if (idAreaList2.indexOf(area2.id) !== -1) {
        area2.isActive = false;
      }
      if (area2.isActive) {
        check2 = true;
      }
    });
    if (!check1 && !check2) {
      return;
    }
    this.busStopSelected = busStop;
    if (busStop.events.length >= _.max([this.areasDisplay1?.length, this.isDisplay2 ? this.areasDisplay2?.length : 0])) {
      return;
    }
    this.isChangedData = true;
    if (this.routeSelected.display1 && !this.routeSelected.display2) {
      busStop.events.push(
        new EventBusStop(
          this.routeSelected.id,
          this.busStopSelected.id,
          this.routeSelected.display1.id,
          undefined,
          this.busStopSelected.orderNo
        )
      );
    }
    if (this.routeSelected.display1 && this.routeSelected.display2) {
      busStop.events.push(
        new EventBusStop(
          this.routeSelected.id,
          this.busStopSelected.id,
          this.routeSelected.display1.id,
          this.routeSelected.display2.id,
          this.busStopSelected.orderNo
        )
      );
    }
  }

  /**
   * select event
   * @param event Event
   * @param busStop BusStop
   */
  selectEvent(event: EventBusStop, busStop: BusStop): void {
    if (this.isPlay) {
      return;
    }
    this.busStopSelected = busStop;
    _.set(this.routeSelected?.display1, 'count', busStop.orderNo);
    _.set(this.routeSelected?.display2, 'count', busStop.orderNo);
    this.eventSelected = event;
  }

  /**
   * delete event
   */
  deleteEvent(): void {
    if (this.isPlay) {
      return;
    }
    // true if all bus stop haven't events
    const checkExistEvents = this.routeSelected?.busStops?.every(busStop => {
      return busStop?.events?.length == 0;
    });
    if (checkExistEvents) {
      return;
    }
    // check selected event
    const selectedIndex = this.busStopSelected?.events?.findIndex(eventBusStop => eventBusStop.symbol == this.eventSelected?.symbol);
    if (!this.eventSelected || selectedIndex < 0) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select a bus stop event.' } });
      return;
    }
    // show dialog
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: `Do you want to delete the selected row?`,
          button1: 'Yes',
          button2: 'No',
          title: 'Confirmation'
        }
      },
      result => {
        if (result) {
          this.isChangedData = true;
          // delete event when click Yes
          const eventBusStop = new EventBusStop(this.routeSelected.id, this.busStopSelected.id);
          eventBusStop.id = this.busStopSelected.events[selectedIndex].id;
          this.busStopSelected.events.splice(selectedIndex, 1);
          if (this.busStopSelected.events.length == 0) {
            this.busStopSelected.events.push(eventBusStop);
          }
          _.set(
            this.busStopSelected?.areaDisplayList1?.find(area => area.id == _.get(this.eventSelected, 'areaDisplay1.id', undefined)),
            'isActive',
            true
          );
          _.set(
            this.busStopSelected?.areaDisplayList2?.find(area => area.id == _.get(this.eventSelected, 'areaDisplay2.id', undefined)),
            'isActive',
            true
          );
          const areaDisplay1 = _.find(Helper.getAllAreaTemplate(this.routeSelected?.display1), area => {
            return area.id == _.get(this.eventSelected, 'areaDisplay1.id', undefined);
          });
          areaDisplay1?.['subscription']?.unsubscribe();
          const areaDisplay2 = _.find(Helper.getAllAreaTemplate(this.routeSelected?.display2), area => {
            return area.id == _.get(this.eventSelected, 'areaDisplay2.id', undefined);
          });
          areaDisplay2?.['subscription']?.unsubscribe();
          this.eventSelected = undefined;
          this.busStopSelected = undefined;
          this.changeStatePreview(PreviewControlEnum.RE_PREVIEW);
          // check area select is TextArea to display input text
          this.isTextArea = !this.routeSelected.busStops.every(busStop => {
            return !!busStop.events.find(eventOfBusStop => eventOfBusStop?.areaDisplay1 instanceof TextArea);
          });
          if (this.isDisplay2) {
            // check area select is TextArea to display input text
            this.isTextArea = !this.routeSelected.busStops.every(busStop => {
              return !!busStop.events.find(eventOfBusStop => eventOfBusStop?.areaDisplay2 instanceof TextArea);
            });
          }
        }
      }
    );
  }

  /**
   * validate media when dropping
   * @param media media validate
   */
  private validateDragDropMedia(media: Media): boolean {
    if (!this.isDisplay2) {
      if (!this.eventSelected?.areaDisplay1) {
        this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select an area.' } });
        return false;
      }
    } else if (!this.eventSelected?.areaDisplay1 && !this.eventSelected?.areaDisplay2) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select an area.' } });
      return false;
    }
    if (
      ((this.eventSelected?.areaDisplay1 && !this.eventSelected.areaDisplay1.checkTypeTextArea()) ||
        (this.eventSelected?.areaDisplay2 && !this.eventSelected.areaDisplay2.checkTypeTextArea())) &&
      Helper.isText(media)
    ) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: { title: 'Error', text: 'Error while dragging file text. Please select another area.' }
      });
      return false;
    }
    // check area, if area is link text => error
    if (
      (this.eventSelected?.areaDisplay1?.checkTypeTextArea() || this.eventSelected?.areaDisplay2?.checkTypeTextArea()) &&
      !Helper.isText(media)
    ) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: { title: 'Error', text: 'Error while dragging file media. Please select another area.' }
      });
      return false;
    }
    return true;
  }

  /**
   * Drop media from Media Manager to Bus Information Display
   * @param e event
   * @param eventBusStop event Bus Stop drop
   */
  dropMedia(e, eventBusStop: EventBusStop): void {
    if (
      JSON.parse(e.dataTransfer.getData(Constant.IS_MEDIA_IN_STATION_CONTENT_FOLDER)) ||
      JSON.parse(e.dataTransfer.getData(Constant.IS_MEDIA_IN_LCD_LAYOUT_EDITOR)) ||
      JSON.parse(e.dataTransfer.getData(Constant.FOLDER_INDEX_WORD_EDITOR))
    ) {
      return;
    }
    e.preventDefault();
    this.eventSelected = eventBusStop;
    this.isChangedData = true;
    // convert data media
    const mediaData = JSON.parse(e.dataTransfer.getData(Constant.MEDIA_VALUE));
    const media = Helper.convertMediaData(mediaData);
    if (!this.validateDragDropMedia(media)) {
      return;
    }
    // case drag drop image + sound
    if (
      Helper.isSound(media) &&
      Helper.isImage(_.get(this.eventSelected, `medias[${Constant.MEDIA_TYPE_IMG}]`)) &&
      !Helper.isSound(_.get(this.eventSelected, `medias[${Constant.MEDIA_TYPE_MP3}]`))
    ) {
      this.eventSelected.medias[Constant.MEDIA_TYPE_MP3] = media;
      this.eventSelected.outputOption = OutputOptionEnum.ONE_STOP;
      // case other
    } else {
      // clear sound
      _.set(this.eventSelected.medias, `${[Constant.MEDIA_TYPE_MP3]}`, undefined);
      if (Helper.isText(media)) {
        this.eventSelected.text = undefined;
      }
      //convert media data
      if (this.eventSelected.medias) {
        this.eventSelected.medias[Constant.MEDIA_TYPE_IMG] = media;
      } else {
        this.eventSelected.medias = [media];
      }
      if (Helper.isVideo(media) || Helper.isSound(media)) {
        this.eventSelected.outputOption = OutputOptionEnum.ONCE;
      } else {
        this.eventSelected.outputOption = OutputOptionEnum.ONE_STOP;
      }
    }
  }

  /**
   * Allow Drop media
   * @param e event
   */
  allowDrop(e): void {
    if (this.isPlay) {
      return;
    }
    e.preventDefault();
  }

  /**
   * change area display 1
   * @param areaId id area
   * @param eventBusStop EventBusStop
   * @param busStop BusStop
   * @param index
   */
  changeAreaDisplay1(areaId: Number, eventBusStop: EventBusStop, busStop: BusStop, index: number): void {
    var area = this.areasDisplay1.find(area => area.id == areaId);
    if (!this.isDisplay2) {
      if (area) {
        if (area.checkTypeTextArea() != eventBusStop.areaDisplay1?.checkTypeTextArea()) {
          eventBusStop.outputOption = 0;
        }
        if (area instanceof TextArea) {
          eventBusStop.isText = true;
          eventBusStop.medias = new Array<Media>();
        } else {
          eventBusStop.text = '';
          eventBusStop.isText = false;
          if (Helper.isText(_.get(eventBusStop, `medias[${Constant.MEDIA_TYPE_IMG}]`, undefined))) {
            eventBusStop.medias = new Array<Media>();
          }
        }
      } else {
        eventBusStop.outputOption = undefined;
        if (!this.busStopSelected.events[index].areaDisplay2) {
          eventBusStop.text = '';
          eventBusStop.medias = new Array<Media>();
        }
        eventBusStop.isText = false;
      }
    } else {
      if (area) {
        if (!eventBusStop.areaDisplay2 && area.checkTypeTextArea() != eventBusStop.areaDisplay1?.checkTypeTextArea()) {
          eventBusStop.outputOption = 0;
        }
        if (area instanceof TextArea) {
          if (!(this.busStopSelected?.events[index]?.areaDisplay2 instanceof TextArea)) {
            eventBusStop.isText = true;
            eventBusStop.medias = new Array<Media>();
          }
        } else {
          eventBusStop.text = '';
          eventBusStop.isText = false;
          if (Helper.isText(_.get(eventBusStop, `medias[${Constant.MEDIA_TYPE_IMG}]`, undefined))) {
            eventBusStop.medias = new Array<Media>();
          }
        }
      } else {
        if (!this.busStopSelected.events[index].areaDisplay2) {
          eventBusStop.outputOption = undefined;
          eventBusStop.isText = false;
          eventBusStop.text = '';
          eventBusStop.medias = new Array<Media>();
        }
      }
    }
    this.handleDataChangeAreaDisplay(index, area, 'areaDisplay1', busStop, areaId);
  }

  /**
   * change area display 2
   * @param areaId id area
   * @param eventBusStop EventBusStop
   * @param busStop BusStop
   * @param index
   */
  changeAreaDisplay2(areaId: Number, eventBusStop: EventBusStop, busStop: BusStop, index: number): void {
    busStop.areaDisplayList2.forEach(area => {
      if (area.id == areaId) {
        area.isActive = false;
      }
    });
    // check change area
    var area = this.areasDisplay2.find(area => area.id == areaId);
    if (area) {
      if (!eventBusStop.areaDisplay1 && area.checkTypeTextArea() != eventBusStop.areaDisplay2?.checkTypeTextArea()) {
        eventBusStop.outputOption = 0;
      }
      if (area instanceof TextArea) {
        if (!(this.busStopSelected?.events[index]?.areaDisplay1 instanceof TextArea)) {
          eventBusStop.isText = true;
          eventBusStop.medias = new Array<Media>();
        }
      } else {
        eventBusStop.text = '';
        eventBusStop.isText = false;
        if (Helper.isText(_.get(eventBusStop, `medias[${Constant.MEDIA_TYPE_IMG}]`, undefined))) {
          eventBusStop.medias = new Array<Media>();
        }
      }
    } else {
      if (!this.busStopSelected.events[index].areaDisplay1) {
        eventBusStop.outputOption = undefined;
        eventBusStop.isText = false;
        eventBusStop.text = '';
        eventBusStop.medias = new Array<Media>();
      }
    }
    this.handleDataChangeAreaDisplay(index, area, 'areaDisplay2', busStop, areaId);
  }

  /**
   * handle data change area display
   * @param index
   * @param area Area
   * @param areaDisplayType
   * @param busStop
   * @param areaId
   */
  handleDataChangeAreaDisplay(index: number, area: Area, areaDisplayType: string, busStop: BusStop, areaId: Number) {
    // assign value
    this.busStopSelected.events[index][areaDisplayType] = area;
    // check variable isTextArea
    this.isTextArea = this.isSelectTextArea();
    let idEventList = [];
    busStop.events.forEach(eventBusStop => {
      if (eventBusStop[areaDisplayType] !== null && eventBusStop[areaDisplayType] !== undefined) {
        idEventList.push(eventBusStop[areaDisplayType].id);
      }
    });
    let areaDisplayListType = areaDisplayType == 'areaDisplay1' ? 'areaDisplayList1' : 'areaDisplayList2';
    busStop[areaDisplayListType].forEach(area => {
      if (idEventList.indexOf(area.id) == -1) {
        area.isActive = true;
      }
      if (area.id == areaId) {
        area.isActive = false;
      }
    });
    this.isChangedData = true;
  }

  /**
   * check select areaDisplay1 and areaDisplay2
   */
  private isSelectTextArea(): boolean {
    // check area selected of display 2
    var checkAreaDisplay2 = !this.routeSelected.busStops.every(busStop => {
      return busStop.events.findIndex(eventData => eventData.areaDisplay2 instanceof TextArea) < 0;
    });
    // check area selected of display 2
    var checkAreaDisplay1 = !this.routeSelected.busStops.every(busStop => {
      return busStop.events.findIndex(eventData => eventData.areaDisplay1 instanceof TextArea) < 0;
    });

    // return result
    if (!this.isDisplay2) {
      return checkAreaDisplay1;
    } else {
      return checkAreaDisplay1 || checkAreaDisplay2;
    }
  }

  /**
   * show setting event
   */
  showSetting(): void {
    this.isSetting = !this.isSetting;
  }

  /**
   * calculate scale transform canvas
   * @param canvasDisplayNode
   * @param display Template object
   * @param divDisplay
   */
  private calculateScaleTransformCanvas(canvasDisplay, display: Template, divDisplay): any {
    if (!divDisplay || !display) {
      return;
    }
    // constant
    const DIV_SHOW_NAME_DISPLAY_1_ID = 'show-name-display-1';
    const DIV_SHOW_NAME_DISPLAY_2_ID = 'show-name-display-2';
    const DIV_CANVAS_DISPLAY_1_ID = 'div-canvas-display-1';
    const DIV_CANVAS_DISPLAY_2_ID = 'div-canvas-display-2';

    this.changeDetectorRef.detectChanges();
    let h3Height = this.routeSelected?.display1
      ? document.getElementById(DIV_SHOW_NAME_DISPLAY_1_ID)
      : document.getElementById(DIV_SHOW_NAME_DISPLAY_2_ID);
    let boxHeight = this.routeSelected?.display1
      ? document.getElementById(DIV_CANVAS_DISPLAY_1_ID)
      : document.getElementById(DIV_CANVAS_DISPLAY_2_ID);

    let divDisplay1Height = divDisplay.clientHeight - h3Height?.clientHeight - boxHeight?.clientHeight;
    let divDisplay1Width = divDisplay.clientWidth;
    let scaleTransform = { scaleX: 1, scaleY: 1 };
    if (display.width > divDisplay1Width) {
      scaleTransform.scaleX = divDisplay1Width / display.width;
    }
    if (display.height > divDisplay1Height) {
      scaleTransform.scaleY = divDisplay1Height / display.height;
    }
    let scale = Math.min(scaleTransform.scaleX, scaleTransform.scaleY);
    this.renderer.setStyle(canvasDisplay.nativeElement, 'transform', 'scale(' + scale + ')');
    canvasDisplay.nativeElement.style.left = (divDisplay1Width - display.width * scale) / 2 + 'px';
    canvasDisplay.nativeElement.style.top = (divDisplay1Height - display.height * scale) / 2 + 'px';
    return scale;
  }

  /**
   * enlarge the screen
   */
  enlargeScreen(): void {
    this.isEnlargeScreen = !this.isEnlargeScreen;
    // draw canvas
    if (this.routeSelected.display1) {
      // scale canvas display 1
      const divDisplay1 = document.getElementById(this.TAG_LI_DISPLAY_1_ID);
      this.calculateScaleTransformCanvas(this.canvasContainerDisplay1, this.routeSelected.display1, divDisplay1);
    }
    if (this.routeSelected.display2 && this.isDisplay2) {
      // scale canvas display 2
      const divDisplay2 = document.getElementById(this.TAG_LI_DISPLAY_2_ID);
      this.calculateScaleTransformCanvas(this.canvasContainerDisplay2, this.routeSelected.display2, divDisplay2);
    }
  }

  /**
   * change outputOption
   * @param value outputOption
   */
  changeOutputOption(value: OutputOptionEnum): void {
    this.eventSelected.outputOption = +value;
    if (this.eventSelected.outputOption == OutputOptionEnum.SECOND) {
      this.eventSelected.duration = 5;
    }
    this.isChangedData = true;
  }

  /**
   * change state preview when click buttons
   * @param key PreviewControlEnum
   */
  public async changeStatePreview(key: PreviewControlEnum) {
    if (!this.isPlay && this.busStopSelected) {
      return;
    }
    switch (key) {
      case PreviewControlEnum.RE_PREVIEW:
        this.isPlay = false;
        this.drawService.setIsPlay(false);
        this.drawService.clearAllThreadDrawTemplate(this.routeSelected.display1);
        this.drawService.clearAllThreadDrawTemplate(this.routeSelected.display2);
        this.clearAllCanvasAreaOfRoute(this.routeSelected);
        this.busStopSelected = _.get(this.routeSelected.busStops, '[0]', undefined);
        _.set(this.routeSelected?.display1, 'count', 0);
        _.set(this.routeSelected?.display2, 'count', 0);
        // handle draw display 1
        if (this.routeSelected.display1) {
          this.clearConfigLinkDataPosition(this.routeSelected.display1);
          this.handleStart(this.routeSelected.display1, false);
        }
        // handle draw display 2
        if (this.isDisplay2 && this.routeSelected.display2) {
          this.clearConfigLinkDataPosition(this.routeSelected.display2);
          this.handleStart(this.routeSelected.display2, true);
        }
        break;
      case PreviewControlEnum.PREV:
        // handle draw display 1
        if (this.routeSelected.display1) {
          await this.handlePrevious(this.routeSelected.display1, false);
        }
        // handle draw display 2
        if (this.isDisplay2 && this.routeSelected.display2) {
          await this.handlePrevious(this.routeSelected.display2, true);
        }
        break;
      case PreviewControlEnum.NEXT:
        if (this.routeSelected.display1) {
          await this.handleNext(this.routeSelected.display1, false);
        }
        if (this.isDisplay2 && this.routeSelected.display2) {
          await this.handleNext(this.routeSelected.display2, true);
        }
        break;
      case PreviewControlEnum.DOOR_CLOSE:
        if (!this.busStopSelected) {
          return;
        }
        // handle draw display 1
        if (this.routeSelected.display1) {
          await this.handleEvent(this.routeSelected.display1, TimingOnEnum.DOOR_CLOSE, TimingOffEnum.DOOR_CLOSE, false);
        }
        // handle draw display 2
        if (this.isDisplay2 && this.routeSelected.display2) {
          await this.handleEvent(this.routeSelected.display2, TimingOnEnum.DOOR_CLOSE, TimingOffEnum.DOOR_CLOSE, true);
        }
        break;
      case PreviewControlEnum.DOOR_OPEN:
        if (!this.busStopSelected) {
          return;
        }
        // handle draw display 1
        if (this.routeSelected.display1) {
          await this.handleEvent(this.routeSelected.display1, TimingOnEnum.ARRIVAL, TimingOffEnum.ARRIVAL, false);
        }
        // handle draw display 2
        if (this.isDisplay2 && this.routeSelected.display2) {
          await this.handleEvent(this.routeSelected.display2, TimingOnEnum.ARRIVAL, TimingOffEnum.ARRIVAL, true);
        }
        break;
      case PreviewControlEnum.STOP_REQUEST:
        if (!this.busStopSelected) {
          return;
        }
        // handle draw display 1
        if (this.routeSelected.display1) {
          await this.handleEvent(this.routeSelected.display1, TimingOnEnum.STOP_REQUEST, TimingOffEnum.STOP_REQUEST, false);
        }
        // handle draw display 2
        if (this.isDisplay2 && this.routeSelected.display2) {
          await this.handleEvent(this.routeSelected.display2, TimingOnEnum.STOP_REQUEST, TimingOffEnum.STOP_REQUEST, true);
        }
        break;
      default:
        break;
    }
  }

  /**
   * visible Area Based On Timing
   * @param areas area list
   * @param display template
   */
  private visibleAreaBasedOnTiming(areas: Area[], display: Template): void {
    areas.forEach(area => {
      switch (area.timingOn) {
        case TimingOnEnum.FROM_THE_BEGINNING:
          if (display.count == 0) {
            area.isOn = true;
            if (area.isOffEvent) {
              area.isOffEvent = false;
            }
          }
          break;
        case TimingOnEnum.LAST_STOP:
          if (display.count < this.routeSelected.busStops.length - 1) {
            area.isOn = false;
          }
          if (area.isOffEvent && display.count == this.routeSelected.busStops.length - 1) {
            area.isOn = true;
            area.isOffEvent = false;
          }
          if (display.count == this.routeSelected.busStops.length - 1) {
            area.isOn = true;
          }
          break;
        case TimingOnEnum.LAST_1_STOP:
          if (display.count < this.routeSelected.busStops.length - 2) {
            area.isOn = false;
          }
          if (area.isOffEvent && display.count == this.routeSelected.busStops.length - 2) {
            area.isOn = true;
            area.isOffEvent = false;
          }
          if (display.count == this.routeSelected.busStops.length - 2) {
            area.isOn = true;
          }
          break;
        case TimingOnEnum.LAST_2_STOP:
          if (display.count < this.routeSelected.busStops.length - 3) {
            area.isOn = false;
          }
          if (area.isOffEvent && display.count == this.routeSelected.busStops.length - 3) {
            area.isOn = true;
            area.isOffEvent = false;
          }
          if (display.count == this.routeSelected.busStops.length - 3) {
            area.isOn = true;
          }
          break;
        case TimingOnEnum.LAST_3_STOP:
          if (display.count < this.routeSelected.busStops.length - 4) {
            area.isOn = false;
          }
          if (area.isOffEvent && display.count == this.routeSelected.busStops.length - 4) {
            area.isOn = true;
            area.isOffEvent = false;
          }
          if (display.count == this.routeSelected.busStops.length - 4) {
            area.isOn = true;
          }
          break;
        default:
          break;
      }
    });
  }

  /**
   * handle when click button next
   * @param display Template
   * @param isDisplay2
   * @param count
   */
  private async handleNext(display: Template, isDisplay2: boolean, count?: number) {
    var areas = Helper.getAllAreaTemplate(display);
    if (_.isNaN(count) || _.isNil(count)) {
      display.count++;
    } else {
      display.count = count;
    }
    if (display.count > this.routeSelected.busStops.length) {
      return;
    }
    if (display.count == this.routeSelected.busStops.length) {
      this.busStopSelected = undefined;
      this.isPlay = false;
      this.drawService.setIsPlay(false);
      this.clearAllCanvasAreaOfRoute(this.routeSelected);
      areas.forEach(area => {
        area.isOn = false;
        this.drawService.clearCanvas(area.canvas);
      });
      this.drawService.clearAllThreadDrawTemplate(display);
      return;
    }
    this.busStopSelected = this.routeSelected.busStops[display.count];

    this.visibleAreaBasedOnTiming(areas, display);
    this.getPositionEventAreas(display, isDisplay2);
    this.drawService.controlPreview(display, true, isDisplay2);
  }

  /**
   * handle when click previous
   * @param display template
   * @param isDisplay2
   * @param count
   */
  private async handlePrevious(display: Template, isDisplay2: boolean, count?: number) {
    if (display.count > this.routeSelected.busStops.length - 1 || display.count == 0) {
      return;
    }
    this.clearConfigLinkDataPosition(display);
    var areas = Helper.getAllAreaTemplate(display);
    if (_.isNaN(count) || _.isNil(count)) {
      display.count--;
    } else {
      display.count = count;
    }
    this.busStopSelected = this.routeSelected.busStops[display.count];
    this.visibleAreaBasedOnTiming(areas, display);
    this.getPositionEventAreas(display, isDisplay2);
    this.drawService.controlPreview(display, false, isDisplay2);
  }

  /**
   * handle when click button close
   * @param display Template
   * @param handleTypeOn TimingOnEnum
   * @param handleTypeOff TimingOffEnum
   * @param isDisplay2
   */
  private async handleEvent(display: Template, handleTypeOn: TimingOnEnum, handleTypeOff: TimingOffEnum, isDisplay2: boolean) {
    var areaList = new Array<Area>();
    if (this.routeSelected && display) {
      var areas = Helper.getAllAreaTemplate(display);
      areas.forEach(area => {
        if (area.timingOn == handleTypeOn) {
          area.isOn = true;
          area.isOffEvent = false;
          area['isHandleEvent'] = true;
          areaList.push(area);
        } else if (area.timingOff == handleTypeOff) {
          area.isOn = false;
          area.isOffEvent = true;
          area['isHandleEvent'] = true;
          areaList.push(area);
        }
      });
    }
    this.drawService.handleEventOn(display, isDisplay2, areaList);
  }

  /**
   * handle when click button reset
   * @param display Template
   * @param isDisplay2
   */
  private handleStart(display: Template, isDisplay2: boolean) {
    if (this.routeSelected && display) {
      const areas = Helper.getAllAreaTemplate(display);
      areas.forEach(area => {
        if (area['isHandleEvent']) {
          area.isOn = false;
          area.isOffEvent = true;
          delete area['isHandleEvent'];
        }
        area.isOn = area.timingOn == TimingOnEnum.FROM_THE_BEGINNING;
        area.isOffEvent = area.timingOn != TimingOnEnum.FROM_THE_BEGINNING;
      });
      this.visibleAreaBasedOnTiming(areas, display);
    }
    this.getPositionEventAreas(display, isDisplay2);
    this.drawService.rePreview(display, this.routeSelected, isDisplay2);
  }

  /**
   * change state button play/pause
   */
  changeStateButton() {
    this.isPlay = !this.isPlay;
    if (!this.busStopSelected && this.isPlay) {
      this.busStopSelected = _.get(this.routeSelected.busStops, '[0]', undefined);
      _.set(this.routeSelected?.display1, 'count', 0);
      _.set(this.routeSelected?.display2, 'count', 0);
      this.drawService.clearAllThreadDrawTemplate(this.routeSelected.display1);
      this.drawService.clearAllThreadDrawTemplate(this.routeSelected.display2);
      this.clearAllCanvasAreaOfRoute(this.routeSelected);
      // handle draw display 1
      if (this.routeSelected.display1) {
        this.clearConfigLinkDataPosition(this.routeSelected.display1);
        this.handleStart(this.routeSelected.display1, false);
      }
      // handle draw display 2
      if (this.isDisplay2 && this.routeSelected.display2) {
        this.clearConfigLinkDataPosition(this.routeSelected.display2);
        this.handleStart(this.routeSelected.display2, true);
      }
    }
    this.drawService.setIsPlay(this.isPlay);
  }

  /**
   * preview bus stop
   * @param busStop busStop
   */
  async previewBusStop(busStop: BusStop) {
    if (busStop === this.busStopSelected) {
      return;
    }
    const deffOrderNo = busStop.orderNo - this.busStopSelected?.orderNo;
    const countDisplay1 = this.routeSelected.display1?.count >= this.routeSelected.busStops.length ? 0 : this.routeSelected.display1?.count;
    if (deffOrderNo > 0) {
      if (this.routeSelected.display1) {
        await this.handleNext(this.routeSelected.display1, false, countDisplay1 + deffOrderNo);
      }
      if (this.isDisplay2 && this.routeSelected.display2) {
        await this.handleNext(this.routeSelected.display2, true, countDisplay1 + deffOrderNo);
      }
    } else {
      // handle draw display 1
      if (this.routeSelected.display1) {
        await this.handlePrevious(this.routeSelected.display1, false, countDisplay1 + deffOrderNo);
      }
      // handle draw display 2
      if (this.isDisplay2 && this.routeSelected.display2) {
        await this.handlePrevious(this.routeSelected.display2, true, countDisplay1 + deffOrderNo);
      }
    }
    this.isPlay = true;
    this.drawService.setIsPlay(this.isPlay);
  }

  /**
   * clear config link data position
   * @param display Template
   */
  private clearConfigLinkDataPosition(display: Template) {
    var areas = Helper.getAllAreaTemplate(display).filter(
      area =>
        area.getArea().linkReferenceData == LinkDataTextEnum.POSITION_DRIVEN ||
        area.getArea().attribute == LinkDataPictureEnum.POSITION_DRIVEN
    );
    areas.forEach(area => {
      this.drawService.clearCanvas(area.canvas);
      delete area.getArea()['indexOri'];
      delete area.getArea()['numberOfDraw'];
      delete area.getArea()['isChangeDataEvent'];
      delete area.getArea()['eventBusStopId'];
      delete area.getArea()['isDrawing'];
      delete area['isHandleEvent'];
      area.isOn = false;
      area.isOffEvent = true;
      if (area.checkTypeTextArea()) {
        area.getArea().text = '';
      } else {
        area.getArea().media = undefined;
      }
    });
  }

  /**
   * insert event bus stop to area preview
   * @param display Template
   * @param isDisplay2
   */
  private getPositionEventAreas(display: Template, isDisplay2: boolean) {
    const areas = Helper.getAllAreaTemplate(display).filter(
      area =>
        area.getArea().linkReferenceData == LinkDataTextEnum.POSITION_DRIVEN ||
        area.getArea().attribute == LinkDataPictureEnum.POSITION_DRIVEN
    );
    const areaIdPreviewList = Array<Number>();
    const events = new Array<EventBusStop>();
    const areaDisplay = isDisplay2 ? 'areaDisplay2' : 'areaDisplay1';
    this.routeSelected.busStops?.forEach(busStop => {
      busStop.events?.forEach(eventBusStop => {
        if (!eventBusStop[areaDisplay]) {
          return;
        }
        if (eventBusStop[areaDisplay] && busStop.orderNo <= display.count) {
          eventBusStop.orderNoBusStop = busStop.orderNo;
          events.push(eventBusStop);
        } else {
          const area = areas.find(area => {
            return area.id == eventBusStop[areaDisplay]?.id && events.findIndex(e => e[areaDisplay].id == area.id) < 0;
          });
          this.clearPreviewArea(area);
        }
      });
    });
    events.forEach(eventBusStop => {
      const area = areas.find(area => area.id == eventBusStop[areaDisplay].id);
      if (!area) {
        return;
      }
      areaIdPreviewList.push(area.id);
      area.isOn = true;
      area.isOffEvent = false;
      if (eventBusStop.id != area['eventBusStopId']) {
        delete area['isDrawing'];
      }
      area['eventBusStopId'] = eventBusStop.id;
      if (area.checkTypeTextArea()) {
        area.getArea().text = Helper.readTextFile(_.get(eventBusStop, `medias[${Constant.MEDIA_TYPE_IMG}]url`, '')) ?? eventBusStop.text;
      } else {
        area.getArea().media = _.get(eventBusStop, `medias[${Constant.MEDIA_TYPE_IMG}]`, undefined);
      }
      var isOn =
        eventBusStop.outputOption == OutputOptionEnum.ONE_STOP ||
        eventBusStop.outputOption == OutputOptionEnum.ONCE ||
        eventBusStop.outputOption == OutputOptionEnum.REPEAT ||
        eventBusStop.outputOption == OutputOptionEnum.SECOND;
      area.getArea()['indexOri'] = isOn ? eventBusStop.orderNoBusStop : undefined;
    });
    // clears the preview area without preview
    areas.forEach(area => {
      if (!areaIdPreviewList.find(id => id == area.id)) {
        this.clearPreviewArea(area);
      }
    });
  }

  /**
   * clears the preview area without preview
   * @param area area clear preview
   */
  private clearPreviewArea(area: Area) {
    if (!area) {
      return;
    }
    area.isOn = false;
    area.isOffEvent = true;
    delete area.getArea()['indexOri'];
    area['subscription']?.unsubscribe();
    delete area['subscription'];
  }

  /**
   * switch show horizontal/vertical
   */
  switchShowHorizontalVertical() {
    this.isShowVertical = !this.isShowVertical;
    const isPlaying = this.isPlay;
    if (!this.routeSelected) {
      return;
    }
    // reset play
    if (this.isPlay) {
      this.isPlay = false;
      this.drawService.setIsPlay(false);
    }

    this.changeDetectorRef.detectChanges();

    // clear node child on preview:
    Helper.clearNodeChild(this.canvasContainerDisplay1?.nativeElement);
    Helper.clearNodeChild(this.canvasContainerDisplay2?.nativeElement);

    if (!this.areasDisplay1 && !this.areasDisplay2) {
      return;
    }
    this.clearAllCanvasAreaOfRoute(this.routeSelected);
    // clear all thread and config
    this.drawService.clearAllThreadDrawTemplate(this.routeSelected.display1);
    this.drawService.clearAllThreadDrawTemplate(this.routeSelected.display2);
    this.clearConfigLinkDataPosition(this.routeSelected.display1);
    this.clearConfigLinkDataPosition(this.routeSelected.display2);
    // draw display 1
    if (this.routeSelected.display1) {
      // scale canvas display 1
      const divDisplay1 = document.getElementById(this.TAG_LI_DISPLAY_1_ID);
      this.calculateScaleTransformCanvas(this.canvasContainerDisplay1, this.routeSelected.display1, divDisplay1);
      // draw
      Helper.createCanvasTemplate(this.routeSelected.display1, this.canvasContainerDisplay1, this.renderer);
      Helper.createAllCanvasAreaTemplate(this.routeSelected.display1, this.canvasContainerDisplay1, this.renderer, false);
      this.handleStart(this.routeSelected.display1, false);
    }
    // draw display 2
    if (this.routeSelected.display2) {
      // scale canvas display 2
      const divDisplay2 = document.getElementById(this.TAG_LI_DISPLAY_2_ID);
      this.calculateScaleTransformCanvas(this.canvasContainerDisplay2, this.routeSelected.display2, divDisplay2);
      // draw
      Helper.createCanvasTemplate(this.routeSelected.display2, this.canvasContainerDisplay2, this.renderer);
      Helper.createAllCanvasAreaTemplate(this.routeSelected.display2, this.canvasContainerDisplay2, this.renderer, false);
      this.handleStart(this.routeSelected.display2, true);
    }

    if (isPlaying) {
      this.isPlay = isPlaying;
      this.drawService.setIsPlay(isPlaying);
    }
  }
}
