import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Helper } from 'app/common/helper';
import { ScreenNameEnum } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { Color } from '../entity/color';

@Component({
  selector: 'app-color-palette-330',
  templateUrl: './color-palette-330.component.html',
  styleUrls: ['./color-palette-330.component.scss']
})
export class ColorPalette330Component implements AfterViewInit, OnChanges {
  @Output()
  color: EventEmitter<Color> = new EventEmitter(true);
  @Output()
  colorxy: EventEmitter<any> = new EventEmitter(true);

  @Input()
  colorxyInput: any;

  @Input()
  alpha: string;

  @Input()
  inputColor: string;

  @Input()
  isFontColor: boolean;

  @Input()
  screen: string;

  ScreenNameEnum = ScreenNameEnum;

  @ViewChild('canvas', { static: false })
  canvas: ElementRef<HTMLCanvasElement>;

  private ctx: CanvasRenderingContext2D;

  private mousedown: boolean = false;

  public selectedPosition: { x: number; y: number };

  constructor(public dataService: DataService) {}

  ngAfterViewInit() {
    if (this.inputColor) {
      let currentColor = Color.fromHex(this.inputColor);
      currentColor.toHsl();
      let initialX = (currentColor.h / 360) * this.canvas.nativeElement.width;
      let initialY = this.getVerticalPosition(currentColor.l, this.canvas.nativeElement.height);
      this.selectedPosition = {
        x: initialX > 1 ? initialX : 2,
        y: initialY > 1 ? initialY : 2
      };
    }
    this.draw();
  }

  draw() {
    if (!this.ctx) {
      this.ctx = this.canvas.nativeElement.getContext('2d');
    }
    const width = this.canvas.nativeElement.width;
    const height = this.canvas.nativeElement.height;

    const colorGrad = this.ctx.createLinearGradient(0, 0, width, 0);
    colorGrad.addColorStop(0, 'rgba(255, 0, 0, 1)');
    colorGrad.addColorStop(0.17, 'rgba(255, 255, 0, 1)');
    colorGrad.addColorStop(0.33, 'rgba(0, 255, 0, 1)');
    colorGrad.addColorStop(0.5, 'rgba(0, 255, 255, 1)');
    colorGrad.addColorStop(0.67, 'rgba(0, 0, 255, 1)');
    colorGrad.addColorStop(0.83, 'rgba(255, 0, 255, 1)');
    colorGrad.addColorStop(1, 'rgba(255, 0, 0, 1)');

    this.ctx.fillStyle = colorGrad;
    this.ctx.fillRect(0, 0, width, height);

    const whiteGrad = this.ctx.createLinearGradient(0, 0, 0, 0.25 * height);
    whiteGrad.addColorStop(0, 'rgba(255,255,255,1)');
    whiteGrad.addColorStop(0.05, 'rgba(255,255,255,1)');
    whiteGrad.addColorStop(1, 'rgba(255,255,255,0)');

    this.ctx.fillStyle = whiteGrad;
    this.ctx.fillRect(0, 0, width, 0.25 * height);

    const blackGrad = this.ctx.createLinearGradient(0, 0.75 * height, 0, height);
    blackGrad.addColorStop(0, 'rgba(0,0,0,0)');
    blackGrad.addColorStop(0.95, 'rgba(0,0,0,1)');
    blackGrad.addColorStop(1, 'rgba(0,0,0,1)');

    this.ctx.fillStyle = blackGrad;
    this.ctx.fillRect(0, 0.75 * height, width, 0.25 * height);

    this.ctx.strokeStyle = 'grey';
    this.ctx.lineWidth = 1;
    this.ctx.strokeRect(0, 0, width, height);

    if (this.selectedPosition) {
      this.ctx.strokeStyle = 'black';
      this.ctx.fillStyle = 'black';
      this.ctx.beginPath();
      this.ctx.arc(this.selectedPosition.x, this.selectedPosition.y, 4, 0, 2 * Math.PI);
      this.ctx.lineWidth = 2;
      this.ctx.stroke();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['alpha']) {
      const pos = this.selectedPosition;
      if (pos) {
        this.color.emit(this.getColorAtPosition(pos.x, pos.y));
        this.colorxy.emit({ x: pos.x, y: pos.y });
      }
      // } else if (changes['inputColor'] && (!this.colorxyInput || !this.selectedPosition || this.colorxyInput.x != this.selectedPosition.x)) {
    } else if (changes['inputColor']) {
      let currentColor = Color.fromHex(this.inputColor);
      currentColor.toHsl();
      let initialX = (currentColor.h / 360) * this.canvas.nativeElement.width;
      let initialY = this.getVerticalPosition(currentColor.l, this.canvas.nativeElement.height);
      this.selectedPosition = {
        x: initialX > 0 ? initialX : 1,
        y: initialY > 0 ? initialY : 1
      };
      this.draw();
    }
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseUp(evt: MouseEvent) {
    this.mousedown = false;
  }

  onMouseDown(evt: MouseEvent) {
    this.mousedown = true;
    this.selectedPosition = { x: evt.offsetX > 0 ? evt.offsetX : 1, y: this.getMousePositionY(evt) };
    this.draw();
    this.emitColor(this.selectedPosition.x, this.selectedPosition.y);
  }

  onMouseMove(evt: MouseEvent) {
    if (this.mousedown) {
      this.selectedPosition = { x: evt.offsetX > 0 ? evt.offsetX : 1, y: this.getMousePositionY(evt) };
      this.draw();
      this.emitColor(this.selectedPosition.x, this.selectedPosition.y);
    }
  }

  emitColor(x: number, y: number) {
    const rgbaColor = this.getColorAtPosition(x, y);
    this.color.emit(rgbaColor);
    this.colorxy.emit({ x, y });
    if (this.screen == ScreenNameEnum.LCD) {
      Helper.updateColorForArea(rgbaColor, this.dataService, this.isFontColor);
    }
  }

  getColorAtPosition(x: number, y: number) {
    const imageData = this.ctx.getImageData(x, y, 1, 1).data;
    return new Color(imageData[0], imageData[1], imageData[2], this.alpha || '1');
  }

  getVerticalPosition(lightness: number, maxheight: number): number {
    if (lightness > 50) {
      return maxheight / 2 - (lightness * maxheight) / 200;
    } else if (lightness < 50 && lightness > 0) {
      return maxheight - (lightness * maxheight) / 200;
    } else if (lightness == 50) {
      return maxheight / 2;
    } else {
      return maxheight - 2;
    }
  }

  getMousePositionY(evt: MouseEvent): number {
    return evt.offsetY > 0 ? (evt.offsetY < this.canvas.nativeElement.height - 1 ? evt.offsetY : this.canvas.nativeElement.height - 2) : 1;
  }
}
