import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationFormService } from '../../../service/validation-form.service';
@Component({
  selector: 'control-messages',
  templateUrl: './control-messages.component.html',
  styleUrls: ['./control-messages.component.scss']
})
export class ControlMessagesComponent {
  @Input() control: FormControl;
  @Input() propertyName: string;
  constructor(private validationFormService: ValidationFormService) { }

  get errorMessage() {
    for (let validatorName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(validatorName)) {
        return this.validationFormService.getValidatorErrorMessage(this.propertyName, validatorName, this.control.errors[validatorName],);
      }
    }
    return null;
  }
}
