import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DataService } from 'app/service/data.service';
import _ from 'lodash';

@Component({
  selector: 'custom-select-box',
  templateUrl: './custom-select-box.component.html',
  styleUrls: ['./custom-select-box.component.scss']
})
export class CustomSelectBoxComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('boxSelectOption') selectOptionElement: ElementRef;
  @ViewChild('divContainSelectOption') divContainSelectOption: ElementRef;

  @Input() public optionList: any;

  @Input() public widthInput;

  @Input() public hasBlankOption;

  @Input() public propertyFirst;

  @Input() public propertySecond;

  @Input() public optionDefault;

  @Input() public disabledOptionList: any;

  @Input() public selectBoxChangeSourceId;

  @Output() public valueChoose = new EventEmitter<any>();

  @Output() public heightOfRow2 = new EventEmitter<any>();

  @Input() set selection(value: any) {
    this.currentValueChoose = value;
  }

  isShowPullDown: boolean = false;

  isUpPullDown: boolean = false;

  currentValueChoose: any;

  ulWidth: string = '100%';

  constructor(private dataService: DataService) {
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'clearSelectBox' && this.selectBoxChangeSourceId == data[1]) {
        this.currentValueChoose = null;
      }
    });
  }

  ngOnInit(): void {
    this.isShowPullDown = false;
    this.widthInput = this.widthInput ?? 145;
    if (this.optionDefault) {
      this.currentValueChoose = this.optionDefault;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.optionList) {
      this.optionList = changes.optionList?.currentValue;
    } else {
      if (_.get(this.optionList, '[0]')) {
        this.optionList = [undefined, ...this.optionList];
        if (this.optionDefault) {
          this.currentValueChoose = this.optionDefault;
        }
      } else if (this.optionList.length == 0) {
        this.optionList = [undefined];
      }
    }
  }

  ngOnDestroy(): void {}

  chooseOption(value: any) {
    this.isShowPullDown = false;
    if (this.currentValueChoose?.id == value?.id) {
      return;
    }
    this.currentValueChoose = value;
    this.selection = value;
    this.valueChoose.next(this.currentValueChoose?.id);
  }

  showPullDown(event: any) {
    this.isShowPullDown = !this.isShowPullDown;
    if (!this.isShowPullDown) {
      return;
    }
    setTimeout(() => {
      const index = this.optionList.findIndex(option => option?.id == this.optionDefault?.id);
      if (index === -1) {
        return;
      }
      const optionElement = _.get(this.selectOptionElement, `nativeElement.children[${index}]`, this.selectOptionElement);
      optionElement?.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
    }, 10);
  }

  changeScroll() {
    this.ulWidth = `calc(100% + ${this.divContainSelectOption.nativeElement.scrollLeft}px)`;
  }

  disableOption(option: any) {
    if (!this.disabledOptionList || !option) {
      return false;
    }
    if (this.disabledOptionList.findIndex(op => op && op.id == option.id) != -1) {
      return true;
    }
    return false;
  }
}
