import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Helper } from 'app/common/helper';
import {
  Constant,
  DelimitersDataEnum,
  DelimitersEnum,
  DisplayCanvasIdEnum,
  DisplayIndexEnum,
  DisplayModelEnum,
  DisplaysEnum,
  ErrorCodeDestination,
  FIELD_COMPONENT,
  FontTypeEnum,
  LinkDataPictureLEDEnum,
  LinkDataTextLEDEnum,
  MODULE_NAME,
  ReferencePositionColumnEDSEnum,
  ScreenCanvasIdEnum
} from 'app/config/constants';
import { DialogChangeTemplateForDestinationComponent } from 'app/dialog/dialog-change-template-for-destination/dialog-change-template-for-destination.component';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogDeliveryDestinationComponent } from 'app/dialog/dialog-delivery-destination/dialog-delivery-destination.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { BusStopRoute } from 'app/model/entity/destination/bus-stop-route';
import { RouteDestination } from 'app/model/entity/destination/route-destination';
import { TemplateLED } from 'app/model/entity/destination/template-led';
import { IndexWord } from 'app/model/entity/index-word';
import { AreaLED } from 'app/model/entity/led/area-led';
import { DataResponseArea } from 'app/model/entity/led/data-response-area';
import { FontCharacterBitmap } from 'app/model/entity/led/font-character-bitmap';
import { PictureAreaLED } from 'app/model/entity/led/picture-area-led';
import { TextAreaLED } from 'app/model/entity/led/text-area-led';
import { SaveDestinationSignEditorStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { EdsRouteListService } from 'app/service/eds/eds-route-list.service';
import { FontService } from 'app/service/font.service';
import { IndexWordService } from 'app/service/index-word.service';
import { AreaLEDService } from 'app/service/led/area-led.service';
import { DrawLEDService } from 'app/service/led/draw-led.service';
import { MediaService } from 'app/service/media.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { PublishDestinationService } from 'app/service/publish-destination.service';
import { PublishTimetableService } from 'app/service/publish-timetable.service';
import { AppState } from 'app/store/app.state';
import * as fileSaver from 'file-saver';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { interval, Subject, Subscription } from 'rxjs';
import { repeatWhen, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-destination-sign-editor',
  templateUrl: './destination-sign-editor.component.html',
  styleUrls: ['./destination-sign-editor.component.scss']
})
export class DestinationSignEditorComponent implements OnInit {
  /**
   * save data success
   */
  @Output() saveDataSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * List subscriptions action
   */
  private subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * Pause preview subject
   */
  private readonly pausePreviewSubject = new Subject<void>();
  /**
   * Start preview subject
   */
  private readonly startPreviewSubject = new Subject<void>();

  /**
   * Change over bus stop display subject
   */
  private startPreviewChangeOverBusStopDisplay = new Subject<void>();
  private clearPreviewChangeOverBusStopDisplay = new Subject<void>();
  private pausePreviewChangeOverBusStopDisplay = new Subject<void>();
  private previewChangeOverBusStopDisplayInterval: any;
  /**
   * Timeouts display
   */
  private timeoutsDisplay: any[] = [];
  /**
   * Index bus stop preview
   */
  public indexBusStopPreview: number = -1;
  /**
   * List font bitmap
   */
  private listFontFolderBmp: [];
  /**
   * Path angle double right
   */
  public PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  /**
   * route selected
   */
  public routeSelected: RouteDestination;
  /**
   * True if all route is checked
   */
  public isCheckedAllRoute: boolean;
  /**
   * True if all bus stop is checked
   */
  public isCheckedAllBusStop: boolean;
  /**
   * Eds routes
   */
  public edsRoutes: Array<RouteDestination> = new Array<RouteDestination>();
  /**
   * True if is playing preview
   */
  public isPlayPreview: boolean;
  /**
   * Bus stop selected
   */
  public busStopSelected: BusStopRoute;
  /**
   * List canvas display id
   */
  public readonly canvasDisplay1Id = `${ScreenCanvasIdEnum.DESTINATION_SIGN_EDITOR}${DisplayCanvasIdEnum.DISPLAY_1}`;
  public readonly canvasDisplay2Id = `${ScreenCanvasIdEnum.DESTINATION_SIGN_EDITOR}${DisplayCanvasIdEnum.DISPLAY_2}`;
  public readonly canvasDisplay3Id = `${ScreenCanvasIdEnum.DESTINATION_SIGN_EDITOR}${DisplayCanvasIdEnum.DISPLAY_3}`;
  public readonly canvasDisplay4Id = `${ScreenCanvasIdEnum.DESTINATION_SIGN_EDITOR}${DisplayCanvasIdEnum.DISPLAY_4}`;
  public readonly canvasDisplay5Id = `${ScreenCanvasIdEnum.DESTINATION_SIGN_EDITOR}${DisplayCanvasIdEnum.DISPLAY_5}`;
  public readonly canvasDisplay6Id = `${ScreenCanvasIdEnum.DESTINATION_SIGN_EDITOR}${DisplayCanvasIdEnum.DISPLAY_6}`;
  /**
   * adjusted route no edit
   */
  public adjustedRouteNoEdit: string;
  /**
   * adjusted route name edit
   */
  public adjustedRouteNameEdit: string;
  /**
   * adjusted bus stop name edit
   */
  public adjustedBusStopNameEdit: string;
  /**
   * True if choose replace in popup confirm
   */
  public isReplaceData: boolean;
  /**
   * is change bus stop checked
   */
  public isChangeBusStopChecked: boolean;
  /**
   * True if edit data route mode is active
   */
  public isEditDataRoute: boolean;
  /**
   * True if edit data bus stop is active
   */
  public isEditDataBusStop: boolean;
  /**
   * True if edit data in screen change
   */
  public isChangeData: boolean;
  /**
   * True if preview area is enlarge
   */
  public isEnlargePreview: boolean = true;
  /**
   * True if bus stop drawn
   */
  private isDrawnBusStop: boolean;
  /**
   * True if is playing preview
   */
  public isStopPreviewBusStop: boolean;
  /**
   * Old bus stops
   */
  private oldBusStops: Array<BusStopRoute>;
  /**
   * Screen Id
   */
  private readonly SCREEN_ID = 'destination-sign-editor';
  /**
   * First element
   */
  private readonly FIRST_ELEMENT = 0;
  /**
   * xlsx file extensions
   */
  private readonly XLSX_EXTENSIONS = 'xlsx';
  /**
   * Import file element
   */
  private readonly IMPORT_FILE_ELEMENT = 'importedFileDestination';
  /**
   * Default bus stop index
   */
  private readonly DEFAULT_BUS_STOP_INDEX = -1;
  //#region data validate
  private readonly ROUTE_NO_MIN_LENGTH = 1;
  private readonly ROUTE_NAME_MIN_LENGTH = 1;
  private readonly BUS_STOP_NAME_MIN_LENGTH = 1;
  private readonly ROUTE_NO_MAX_LENGTH = 5;
  private readonly ROUTE_NAME_MAX_LENGTH = 48;
  private readonly BUS_STOP_NAME_MAX_LENGTH = 48;
  //#endregion data validate

  //#region canvas
  @ViewChild('divContainCanvas1', { static: false })
  public divContainCanvas1: ElementRef;

  @ViewChild('divContainCanvas2', { static: false })
  public divContainCanvas2: ElementRef;

  @ViewChild('divContainCanvas3', { static: false })
  public divContainCanvas3: ElementRef;

  @ViewChild('divContainCanvas4', { static: false })
  public divContainCanvas4: ElementRef;

  @ViewChild('divContainCanvas5', { static: false })
  public divContainCanvas5: ElementRef;

  @ViewChild('divContainCanvas6', { static: false })
  public divContainCanvas6: ElementRef;

  //#endregion canvas

  /**
   * State of component
   */
  private stateOfComponent: {
    isCheckedAllBusStop: boolean;
    isCheckedAllRoute: boolean;
    isPlayPreview: boolean;
    isChangeBusStopChecked: boolean;
    isEditDataRoute: boolean;
    isEditDataBusStop: boolean;
    isChangeData: boolean;
    isEnlargePreview: boolean;
    isDrawnBusStop: boolean;
    isChangeLayout: boolean;
    indexBusStopPreview: number;
    listFontFolderBmp: [];
    routeSelected: RouteDestination;
    edsRoutes: Array<RouteDestination>;
    busStopSelected: BusStopRoute;
    adjustedRouteNoEdit: string;
    adjustedRouteNameEdit: string;
    adjustedBusStopNameEdit: string;
    oldBusStops: Array<BusStopRoute>;
    isStopPreviewBusStop: boolean;
  };

  constructor(
    private edsRouteListService: EdsRouteListService,
    private actionService: MenuActionService,
    private dialogService: DialogService,
    private toast: ToastrService,
    private renderer: Renderer2,
    private areaLEDService: AreaLEDService,
    private drawLEDService: DrawLEDService,
    private dataService: DataService,
    private indexWordService: IndexWordService,
    private changeDetectorRef: ChangeDetectorRef,
    private fontService: FontService,
    private mediaService: MediaService,
    public readonly store: Store<AppState>,
    private publishDestinationService: PublishDestinationService,
    private publishTimetableService: PublishTimetableService
  ) {
    // reflect data from Route List Editor
    this.subscriptions.push(
      this.actionService.actionReflectDataFromRouteListEditor.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DestinationSignEditorComponent]) {
          this.reflectDataFromRouteListEditor();
        }
      })
    );
    // Import route list
    this.subscriptions.push(
      this.actionService.actionImportRouteList.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DestinationSignEditorComponent]) {
          this.importRouteList();
        }
      })
    );
    // Export route list
    this.subscriptions.push(
      this.actionService.actionExportRouteList.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DestinationSignEditorComponent]) {
          this.exportRouteList();
        }
      })
    );
    // Edit route list
    this.subscriptions.push(
      this.actionService.actionEditDataDestination.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DestinationSignEditorComponent]) {
          this.editDataDestinationSign();
        }
      })
    );
    // Delete route checked
    this.subscriptions.push(
      this.actionService.actionDeleteRouteDestination.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DestinationSignEditorComponent]) {
          this.deleteRouteList();
        }
      })
    );
    // change template route
    this.subscriptions.push(
      this.actionService.actionChangeTemplateRouteDestination.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DestinationSignEditorComponent]) {
          this.changeTemplateRoutes();
        }
      })
    );
    // delivery
    this.subscriptions.push(
      this.actionService.actionDelivery.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DestinationSignEditorComponent]) {
          this.saveChangeDataToPublish(true);
        }
      })
    );
    // download publish file
    this.subscriptions.push(
      this.actionService.actionDownloadPublishFile.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.DestinationSignEditorComponent]) {
          this.saveChangeDataToPublish();
        }
      })
    );
    // save data after leave
    this.subscriptions.push(
      this.actionService.actionSave.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.DestinationSignEditorComponent]) {
          if (this.isEditDataRoute || this.isEditDataBusStop || this.isChangeBusStopChecked) {
            this.saveDataAfterLeave();
          }
        }
      })
    );
    // state
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isCheckedAllBusStop: componentState?.destinationSignEditorState?.stateOfComponent.isCheckedAllBusStop,
            isCheckedAllRoute: componentState?.destinationSignEditorState?.stateOfComponent.isCheckedAllRoute,
            isPlayPreview: componentState?.destinationSignEditorState?.stateOfComponent.isPlayPreview,
            isChangeBusStopChecked: componentState?.destinationSignEditorState?.stateOfComponent.isChangeBusStopChecked,
            isEditDataRoute: componentState?.destinationSignEditorState?.stateOfComponent.isEditDataRoute,
            isEditDataBusStop: componentState?.destinationSignEditorState?.stateOfComponent.isEditDataBusStop,
            isChangeData: componentState?.destinationSignEditorState?.stateOfComponent.isChangeData,
            isEnlargePreview: componentState?.destinationSignEditorState?.stateOfComponent.isEnlargePreview,
            isDrawnBusStop: componentState?.destinationSignEditorState?.stateOfComponent.isDrawnBusStop,
            isChangeLayout: componentState?.destinationSignEditorState?.stateOfComponent.isChangeLayout,
            indexBusStopPreview: componentState?.destinationSignEditorState?.stateOfComponent.indexBusStopPreview,
            listFontFolderBmp: componentState?.destinationSignEditorState?.stateOfComponent.listFontFolderBmp,
            routeSelected: componentState?.destinationSignEditorState?.stateOfComponent.routeSelected,
            edsRoutes: componentState?.destinationSignEditorState?.stateOfComponent.edsRoutes,
            busStopSelected: componentState?.destinationSignEditorState?.stateOfComponent.busStopSelected,
            adjustedRouteNoEdit: componentState?.destinationSignEditorState?.stateOfComponent.adjustedRouteNoEdit,
            adjustedRouteNameEdit: componentState?.destinationSignEditorState?.stateOfComponent.adjustedRouteNameEdit,
            adjustedBusStopNameEdit: componentState?.destinationSignEditorState?.stateOfComponent.adjustedBusStopNameEdit,
            oldBusStops: componentState?.destinationSignEditorState?.stateOfComponent.oldBusStops,
            isStopPreviewBusStop: componentState?.destinationSignEditorState?.stateOfComponent.isStopPreviewBusStop
          };
        })
    );
  }

  /**
   * Ng on init
   */
  ngOnInit(): void {
    if (!this.stateOfComponent?.isChangeLayout) {
      this.fetchAllData();
    } else {
      this.handleAfterChangeLayout();
    }
  }

  /**
   * Ng on destroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.clearAllBeforeLeave();
    this.store.dispatch(
      new SaveDestinationSignEditorStateAction({
        isCheckedAllBusStop: this.isCheckedAllBusStop,
        isCheckedAllRoute: this.isCheckedAllRoute,
        isPlayPreview: this.isPlayPreview,
        isChangeBusStopChecked: this.isChangeBusStopChecked,
        isEditDataRoute: this.isEditDataRoute,
        isEditDataBusStop: this.isEditDataBusStop,
        isChangeData: this.isChangeData,
        isEnlargePreview: this.isEnlargePreview,
        isDrawnBusStop: this.isDrawnBusStop,
        isChangeLayout: true,
        indexBusStopPreview: this.indexBusStopPreview,
        listFontFolderBmp: this.listFontFolderBmp,
        routeSelected: this.routeSelected,
        edsRoutes: this.edsRoutes,
        busStopSelected: this.busStopSelected,
        adjustedRouteNoEdit: this.adjustedRouteNoEdit,
        adjustedRouteNameEdit: this.adjustedRouteNameEdit,
        adjustedBusStopNameEdit: this.adjustedBusStopNameEdit,
        oldBusStops: this.oldBusStops,
        isStopPreviewBusStop: this.isStopPreviewBusStop
      })
    );
  }

  /**
   * Clear all before leave
   */
  private clearAllBeforeLeave(): void {
    this.isPlayPreview = false;
    this.clearTimeoutDisplay(this.timeoutsDisplay);
    this.drawLEDService.changeStatePlayPause(this.isPlayPreview);
    this.clearAllThreadDisplay();
    this.clearAllAreaBusStop();
  }

  /**
   * Handle after change layout
   */
  private handleAfterChangeLayout(): void {
    this.isCheckedAllBusStop = this.stateOfComponent?.isCheckedAllBusStop;
    this.isCheckedAllRoute = this.stateOfComponent?.isCheckedAllRoute;
    this.isPlayPreview = this.stateOfComponent?.isPlayPreview;
    this.isChangeBusStopChecked = this.stateOfComponent?.isChangeBusStopChecked;
    this.isEditDataRoute = this.stateOfComponent?.isEditDataRoute;
    this.isEditDataBusStop = this.stateOfComponent?.isEditDataBusStop;
    this.isChangeData = this.stateOfComponent?.isChangeData;
    this.isEnlargePreview = this.stateOfComponent?.isEnlargePreview;
    this.isDrawnBusStop = this.stateOfComponent?.isDrawnBusStop;
    this.indexBusStopPreview = this.stateOfComponent?.indexBusStopPreview;
    this.listFontFolderBmp = this.stateOfComponent?.listFontFolderBmp;
    this.routeSelected = this.stateOfComponent?.routeSelected;
    this.edsRoutes = this.stateOfComponent?.edsRoutes;
    this.busStopSelected = this.stateOfComponent?.busStopSelected;
    this.adjustedRouteNoEdit = this.stateOfComponent?.adjustedRouteNoEdit;
    this.adjustedRouteNameEdit = this.stateOfComponent?.adjustedRouteNameEdit;
    this.adjustedBusStopNameEdit = this.stateOfComponent?.adjustedBusStopNameEdit;
    this.oldBusStops = this.stateOfComponent?.oldBusStops;
    this.isStopPreviewBusStop = this.stateOfComponent?.isStopPreviewBusStop;
    this.dataService.sendData([Constant.IS_PLAY_PREVIEW, this.isPlayPreview]);
    this.dataService.sendData([Constant.IS_EDIT_DATA, this.isEditDataRoute]);
    if (this.busStopSelected) {
      this.dataService.sendData([Constant.IS_EDIT_DATA, this.isEditDataBusStop]);
    }
    // draw preview
    this.changeDetectorRef.detectChanges();
    this.drawLEDService.changeStartState(true);
    this.previewTemplate(true);
  }

  /**
   * Fetch all data
   */
  private fetchAllData(): void {
    this.edsRouteListService.getAllDataRouteList().subscribe(
      routesData => {
        this.edsRoutes = Helper.convertEdsListRouteListFromServer(routesData);
        if (this.edsRoutes.length > 0) {
          this.selectRoute(this.edsRoutes[0], null);
        }
      },
      () => this.handleErrorMessage('An error has occurred. Please try again.')
    );
  }

  /**
   * Save data after leave
   */
  private saveDataAfterLeave(): void {
    if (this.routeSelected) {
      if (this.isEditDataRoute) {
        this.saveEdsRouteList();
        return;
      }
      if (this.isEditDataBusStop) {
        this.saveEdsBusStop();
        return;
      }
      if (this.isChangeBusStopChecked) {
        this.edsRouteListService.saveEdsRouteList(Helper.convertEdsRouteToBackward(this.routeSelected)).subscribe(() => {
          this.saveDataSuccess.emit(true);
        });
      }
    }
  }

  /**
   * Enlarge preview
   */
  public enlargePreview(): void {
    this.isEnlargePreview = !this.isEnlargePreview;
    this.reDrawPreview();
  }

  /**
   * Redraw preview after enlarge preview
   * @returns
   */
  private reDrawPreview(): void {
    if (!this.isPlayPreview) {
      return;
    }
    this.drawLEDService.changeStatePlayPause(this.isEnlargePreview);
    this.drawLEDService.changeStartState(this.isEnlargePreview);
    if (this.isEnlargePreview) {
      this.handleStatePreview();
    } else {
      this.pausePreviewSubject.next();
      this.pausePreviewChangeOverBusStopDisplay.next();
      this.clearTimeoutDisplay(this.timeoutsDisplay);
    }
  }

  /**
   * Handle state preview
   */
  private handleStatePreview(): void {
    this.startPreviewSubject.next();
    this.startPreviewChangeOverBusStopDisplay.next();
    if (!this.isDrawnBusStop) {
      this.isDrawnBusStop = true;
      this.clearPreviewChangeOverBusStopDisplay.next();
      this.previewBusStop();
    }
  }

  /**
   * Change State preview
   *
   * @param isPlay
   */
  public changeStatePreview(isPlay: boolean): void {
    if (this.isEditDataBusStop || this.isEditDataRoute || !this.routeSelected?.templateNameShowInGUI) {
      return;
    }
    this.isPlayPreview = isPlay;
    this.dataService.sendData([Constant.IS_PLAY_PREVIEW, this.isPlayPreview]);
    this.drawLEDService.changeStatePlayPause(this.isPlayPreview);
    this.drawLEDService.changeStartState(false);
    if (this.isPlayPreview) {
      this.handleStatePreview();
    } else {
      this.pausePreviewSubject.next();
      this.pausePreviewChangeOverBusStopDisplay.next();
    }
    this.changeDetectorRef.detectChanges();
    if (this.isPlayPreview) {
      if (this.isChangeData) {
        this.isChangeData = false;
        this.previewTemplate(true);
        this.drawLEDService.changeStartState(true);
      }
    } else {
      this.clearTimeoutDisplay(this.timeoutsDisplay);
    }
  }

  /**
   * Handle preview bus stop
   *
   * @param isNext
   * @returns
   */
  public handlePreviewBusStop(isNext: boolean): void {
    const busStopsChecked = this.routeSelected?.busStops.filter(busStop => busStop.isChecked);
    if (!this.isPlayPreview || busStopsChecked?.length == 0) {
      return;
    }
    this.indexBusStopPreview = this.getIndexBusStopPreview(busStopsChecked, isNext, this.indexBusStopPreview);
    if (this.indexBusStopPreview == -1) {
      return;
    }
    if (this.indexBusStopPreview == +busStopsChecked[busStopsChecked.length - 1].index + 1) {
      this.busStopSelected = undefined;
      this.indexBusStopPreview = this.indexBusStopPreview;
      this.isStopPreviewBusStop = true;
      this.clearAllAreaBusStop();
      return;
    }
    this.selectBusStop(
      busStopsChecked.find(data => data.index == this.indexBusStopPreview),
      null
    );
  }

  /**
   * Clear all area bus  stop
   */
  private async clearAllAreaBusStop(): Promise<void> {
    const areasClear = this.getAllAreaBusStop();
    if (this.previewChangeOverBusStopDisplayInterval) {
      this.previewChangeOverBusStopDisplayInterval.unsubscribe();
    }
    this.clearPreviewChangeOverBusStopDisplay.next();
    await Promise.all(
      areasClear.map(area => {
        if (!area.canvas) {
          return;
        }
        this.drawLEDService.clearIntervalBusStopChangeOver(area);
        this.clearAreaCanvas(area);
      })
    );
  }

  /**
   * Get all area bus stop
   *
   * @returns
   */
  private getAllAreaBusStop(): AreaLED[] {
    let areasBusStop = [];
    if (!this.routeSelected) {
      return areasBusStop;
    }
    for (let index = 1; index <= DisplayIndexEnum.DISPLAY_6; index++) {
      if (this.routeSelected[`display${index}`]) {
        areasBusStop = areasBusStop.concat(this.getAllAreaBusStopByTemplate(this.routeSelected[`display${index}`]));
      }
    }
    return areasBusStop;
  }

  /**
   * Get all area bus stop by template
   *
   * @param template
   * @returns
   */
  private getAllAreaBusStopByTemplate(template: TemplateLED): AreaLED[] {
    if (!template?.areas) {
      return [];
    }
    let areasBusStop = [];
    template.areas.forEach(area => {
      switch (area.getArea().linkReferenceData) {
        case LinkDataTextLEDEnum.ADJUSTED_BUS_STOP_NAME:
        case LinkDataTextLEDEnum.BUS_STOP_NAME:
          areasBusStop.push(area);
          break;
        case LinkDataPictureLEDEnum.INDEX_WORD:
        case LinkDataTextLEDEnum.INDEX_WORD:
          if (
            area.getArea().referencePositionColumn == ReferencePositionColumnEDSEnum.BUS_STOP_NAME ||
            area.getArea().referencePositionColumn == ReferencePositionColumnEDSEnum.ADJUSTED_BUS_STOP_NAME
          ) {
            areasBusStop.push(area);
          }
          break;
        default:
          break;
      }
    });
    return areasBusStop;
  }

  /**
   * Get index bus stop preview
   *
   * @param busStopsChecked
   * @param isNext
   * @param indexBusStopPreview
   * @returns
   */
  private getIndexBusStopPreview(busStopsChecked: BusStopRoute[], isNext: boolean, indexBusStopPreview: number): number {
    if (this.isStopPreviewBusStop) {
      return this.DEFAULT_BUS_STOP_INDEX;
    }
    switch (indexBusStopPreview) {
      case this.DEFAULT_BUS_STOP_INDEX:
        return isNext ? +busStopsChecked[0].index : indexBusStopPreview;
      case +busStopsChecked[busStopsChecked.length - 1].index:
        return isNext ? +busStopsChecked[busStopsChecked.length - 1].index + 1 : +busStopsChecked[busStopsChecked.length - 2].index;
      default:
        let indexBusStop = busStopsChecked.findIndex(data => data.index == indexBusStopPreview) ?? 0;
        return isNext
          ? +busStopsChecked[indexBusStop + 1].index
          : +busStopsChecked[indexBusStop].index == +busStopsChecked[0].index
          ? +busStopsChecked[0].index
          : +busStopsChecked[indexBusStop - 1].index;
    }
  }

  /**
   * Reset preview
   */
  public resetPreview(): void {
    if (!this.routeSelected?.templateNameShowInGUI) {
      return;
    }
    const busStopsChecked = this.routeSelected?.busStops.filter(busStop => busStop.isChecked);
    if (busStopsChecked?.length == 0) {
      return;
    }
    this.indexBusStopPreview = 0;
    this.isStopPreviewBusStop = false;
    this.busStopSelected = undefined;
    this.selectBusStop(busStopsChecked[this.indexBusStopPreview], null);
    this.isPlayPreview = false;
    this.dataService.sendData([Constant.IS_PLAY_PREVIEW, this.isPlayPreview]);
    this.pausePreviewSubject.next();
    this.drawLEDService.pausePreview();
  }

  /**
   * pause preview
   */
  private pausePreview(): void {
    this.isPlayPreview = false;
    this.dataService.sendData([Constant.IS_PLAY_PREVIEW, this.isPlayPreview]);
    this.pausePreviewSubject.next();
    this.clearTimeoutDisplay(this.timeoutsDisplay);
    this.clearAllThreadDisplay();
    this.drawLEDService.pausePreview();
  }

  /**
   * Clear all thread display
   */
  private clearAllThreadDisplay(): void {
    if (!this.routeSelected) {
      return;
    }
    for (let index = 1; index <= DisplayIndexEnum.DISPLAY_6; index++) {
      this.drawLEDService.clearAllThreadDrawTemplate(this.routeSelected[`display${index}`]);
    }
  }

  /**
   * Preview template
   *
   * @param isPlayPreview
   */
  private previewTemplate(isPlayPreview?: boolean): void {
    if (!isPlayPreview) {
      this.isPlayPreview = false;
    }
    this.clearTimeoutDisplay(this.timeoutsDisplay);
    this.drawPreview();
    this.drawLEDService.changeStatePlayPause(this.isPlayPreview);
  }

  /**
   * clear all time out
   *
   * @param timeouts
   */
  private clearTimeoutDisplay(timeouts: any[]): void {
    for (let i = 0; i < timeouts.length; i++) {
      clearTimeout(timeouts[i]);
    }
    timeouts = [];
  }

  /**
   * Select route
   * @param route
   * @param event
   */
  public selectRoute(route: any, event: any): void {
    // check click button checkbox
    if (
      event?.target?.id === 'checkBoxDestination' ||
      route?.id == this.routeSelected?.id ||
      this.isEditDataBusStop ||
      this.isEditDataRoute
    ) {
      return;
    }
    if (this.isChangeBusStopChecked) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: 'There are edited data. Do you want to save?',
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.edsRouteListService.saveEdsRouteList(Helper.convertEdsRouteToBackward(this.routeSelected)).subscribe(
              () => {
                this.handleDataWhenSelectRoute(route);
              },
              () => this.handleErrorMessage('An error has occurred. Please try again.')
            );
          } else {
            this.routeSelected.busStops = this.oldBusStops;
            this.handleDataWhenSelectRoute(route);
          }
          this.isChangeBusStopChecked = false;
        }
      );
    } else {
      this.handleDataWhenSelectRoute(route);
    }
  }

  /**
   * Handle data when select route
   *
   * @param route
   */
  private handleDataWhenSelectRoute(route: RouteDestination): void {
    this.busStopSelected = undefined;
    this.indexBusStopPreview = this.DEFAULT_BUS_STOP_INDEX;
    this.pausePreview();
    this.routeSelected = route;
    this.oldBusStops = _.cloneDeep(this.routeSelected.busStops);
    this.isCheckedAllBusStop = this.routeSelected.busStops.length > 0 && this.routeSelected.busStops.every(busStop => busStop.isChecked);
    this.adjustedRouteNoEdit = this.routeSelected.adjustedRouteNo;
    this.adjustedRouteNameEdit = this.routeSelected.adjustedRouteName;
    const templateIds = [route.idTemplate1, route.idTemplate2, route.idTemplate3, route.idTemplate4, route.idTemplate5, route.idTemplate6];
    if (templateIds.length == 0) {
      return;
    }
    //Get data areas for preview
    this.handleDrawTemplateForRoute(templateIds);
  }

  /**
   * Handle data areas for route
   *
   * @param areasResponse
   */
  private handleDataAreasForRoute(areasResponse: DataResponseArea[]): void {
    for (let index = 1; index <= DisplayIndexEnum.DISPLAY_6; index++) {
      if (this.routeSelected[`display${index}`]) {
        this.routeSelected[`display${index}`].areas = Helper.convertDataAreasLED(
          areasResponse[index - 1],
          this.routeSelected[`display${index}`].displayModel
        );
      }
    }
  }

  /**
   * Draw preview
   */
  private async drawPreview(): Promise<void> {
    await this.clearAllAreaBusStop();
    this.clearAllThreadDisplay();
    //display 1
    this.drawTemplate(this.routeSelected?.display1, this.divContainCanvas1, DisplaysEnum.DISPLAY_1);
    //display 2
    this.drawTemplate(this.routeSelected?.display2, this.divContainCanvas2, DisplaysEnum.DISPLAY_2);
    //display 3
    this.drawTemplate(this.routeSelected?.display3, this.divContainCanvas3, DisplaysEnum.DISPLAY_3);
    //display 4
    this.drawTemplate(this.routeSelected?.display4, this.divContainCanvas4, DisplaysEnum.DISPLAY_4);
    //display 5
    this.drawTemplate(this.routeSelected?.display5, this.divContainCanvas5, DisplaysEnum.DISPLAY_5);
    //display 6
    this.drawTemplate(this.routeSelected?.display6, this.divContainCanvas6, DisplaysEnum.DISPLAY_6);
  }

  /**
   * Draw template
   *
   * @param template
   * @param divContainCanvas
   * @param displayEnum
   * @returns
   */
  private drawTemplate(template: TemplateLED, divContainCanvas: ElementRef, displayEnum: DisplaysEnum): void {
    this.changeDetectorRef.detectChanges();
    Helper.clearNodeChild(divContainCanvas?.nativeElement);
    this.drawLEDService.clearAllThreadDrawTemplate(template);
    if (!template) {
      return;
    }
    this.createCanvasTemplate(template, divContainCanvas, displayEnum);
    this.createCanvasAllArea(template, divContainCanvas, displayEnum);
    this.drawPreviewTemplate(template, this.renderer, template.displayModel);
    const indexWordAreas = this.getIndexWordAreas(template);
    this.drawIndexWordArea(indexWordAreas, this.renderer, template.displayModel);
    const linkAreas = template.areas.filter(area => area.getArea().linkReferenceData != LinkDataTextLEDEnum.INDEX_WORD && !area.isFix);
    this.drawLinkArea(linkAreas, this.renderer, template.displayModel);
    if (this.busStopSelected) {
      this.previewBusStopByTemplate(template, this.renderer);
      this.isDrawnBusStop = true;
    }
  }

  /**
   * Get index word areas
   *
   * @param template
   * @returns
   */
  private getIndexWordAreas(template: TemplateLED): AreaLED[] {
    return template.areas.filter(
      area =>
        ((area.getArea().linkReferenceData == LinkDataPictureLEDEnum.INDEX_WORD && !area.checkTypeTextArea()) ||
          (area.getArea().linkReferenceData == LinkDataTextLEDEnum.INDEX_WORD && area.checkTypeTextArea())) &&
        !area.isFix
    );
  }

  /**
   * Create canvas all area
   *
   * @param template
   * @param canvasContainerDisplay
   * @param displayEnum
   */
  private createCanvasAllArea(template: TemplateLED, canvasContainerDisplay: any, displayEnum: DisplaysEnum): void {
    if (!template) {
      return;
    }
    template.areas.forEach(area => {
      this.createCanvasArea(area, canvasContainerDisplay, displayEnum);
    });
  }

  /**
   * Create canvas area
   *
   * @param area
   * @param canvasContainerDisplay
   * @param display
   */
  private createCanvasArea(area: AreaLED, canvasContainerDisplay: any, display: DisplaysEnum): void {
    const canvas = this.renderer.createElement('canvas');
    canvas.id = `${this.SCREEN_ID}-previewCanvas${display}-${area.id}`;
    canvas.style.position = 'absolute';
    canvas.style.zIndex = area.index;
    canvas.style.left = area.posX * Constant.DESTINATION_SCALE + 'px';
    canvas.style.top = area.posY * Constant.DESTINATION_SCALE + 'px';
    canvas.style.width = area.width * Constant.DESTINATION_SCALE + 'px';
    canvas.style.height = area.height * Constant.DESTINATION_SCALE + 'px';
    canvas.width = area.width * Constant.DESTINATION_SCALE;
    canvas.height = area.height * Constant.DESTINATION_SCALE;
    this.renderer.appendChild(canvasContainerDisplay.nativeElement, canvas);
    area.canvas = canvas;
  }

  /**
   * create canvas template
   *
   * @param template
   * @param canvasContainerDisplay
   * @param displayEnum
   */
  private createCanvasTemplate(template: TemplateLED, canvasContainerDisplay: any, displayEnum: DisplaysEnum): void {
    if (!template) {
      return;
    }
    const canvas = this.renderer.createElement('canvas');
    canvas.id = this.getCanvasId(displayEnum, this.SCREEN_ID);
    canvas.style.position = 'absolute';
    canvas.style.background = '#000';
    canvas.style.width = template.width * Constant.DESTINATION_SCALE + 'px';
    canvas.style.height = template.height * Constant.DESTINATION_SCALE + 'px';
    canvas.width = template.width * Constant.DESTINATION_SCALE;
    canvas.height = template.height * Constant.DESTINATION_SCALE;
    this.renderer.appendChild(canvasContainerDisplay.nativeElement, canvas);
  }

  /**
   * Draw preview
   *
   * @param template
   * @param renderer
   * @param displayModel
   * @returns
   */
  private drawPreviewTemplate(template: TemplateLED, renderer: Renderer2, displayModel: DisplayModelEnum): void {
    if (!template) {
      return;
    }
    Promise.all(
      template.areas.map(async area => {
        if (!area.canvas) {
          return;
        }
        if (area.checkTypeTextArea()) {
          var textArea = area as TextAreaLED;
          if (textArea.isFix) {
            await this.drawAreaTextOnCanvas(textArea, renderer, displayModel);
          }
        } else {
          var areaPicture = area as PictureAreaLED;
          if (areaPicture.isFix) {
            await this.drawLEDService.drawAreaPictureOnDestination(areaPicture, renderer);
          }
        }
      })
    );
  }

  /**
   * Draw area tex on canvas
   *
   * @param textArea
   * @param renderer
   * @param displayModel
   */
  private async drawAreaTextOnCanvas(textArea: TextAreaLED, renderer: Renderer2, displayModel: DisplayModelEnum): Promise<void> {
    renderer.setStyle(textArea.canvas, 'visibility', 'visible');
    if (textArea.text?.length > 0) {
      let canvas = textArea.canvas;
      if (!canvas) {
        return;
      }
      let ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (textArea.fontType == FontTypeEnum.PC_FONT) {
        this.drawLEDService.handleDrawAreaText(textArea, [], true, displayModel);
      } else {
        let index = this.listFontFolderBmp?.findIndex(
          font => font['fontName'] == textArea.getArea().fontName && font['fontSize'] == textArea.getArea().fontSize
        );
        if (index != -1 && this.listFontFolderBmp?.length) {
          this.drawLEDService.handleDrawAreaText(textArea, this.listFontFolderBmp, true, displayModel);
        } else {
          this.fontService
            .getFontsBitmapForAreas([new FontCharacterBitmap(textArea.getArea().fontName, `${textArea.getArea().fontSize}`)])
            .subscribe(fonts => {
              this.listFontFolderBmp = this.listFontFolderBmp ? (this.listFontFolderBmp.concat(fonts as []) as []) : (fonts as []);
              this.drawLEDService.handleDrawAreaText(textArea, this.listFontFolderBmp, true, displayModel);
            });
        }
      }
    }
  }

  /**
   * Draw link area
   *
   * @param linkArea
   * @param renderer
   * @param displayModel
   * @returns
   */
  private drawLinkArea(linkArea: AreaLED[], renderer: Renderer2, displayModel: DisplayModelEnum): void {
    if (!linkArea?.length) {
      return;
    }
    Promise.all(
      linkArea.map(async area => {
        if (!area.canvas) {
          return;
        }
        if (area.checkTypeTextArea()) {
          let textArea = area as TextAreaLED;
          if (
            textArea.getArea().linkReferenceData == LinkDataTextLEDEnum.BUS_STOP_NAME ||
            textArea.getArea().linkReferenceData == LinkDataTextLEDEnum.ADJUSTED_BUS_STOP_NAME
          ) {
            this.drawChangeOverBusStop(textArea, renderer, displayModel);
          } else {
            let textArea = area as TextAreaLED;
            textArea.text = this.getTextForLinkArea(textArea);
            if (textArea.text) {
              this.drawLEDService.clearIntervalBusStopChangeOver(textArea);
              await this.drawAreaTextOnCanvas(textArea, renderer, displayModel);
            }
          }
        }
      })
    );
  }

  /**
   * Get text for link area
   *
   * @param textArea
   * @returns
   */
  private getTextForLinkArea(textArea: TextAreaLED): string {
    switch (textArea.getArea().linkReferenceData) {
      case LinkDataTextLEDEnum.ROUTE_NO:
        return this.routeSelected.routeNo;
      case LinkDataTextLEDEnum.ROUTE_NAME:
        return this.routeSelected.routeName;
      case LinkDataTextLEDEnum.ADJUSTED_ROUTE_NO:
        return this.routeSelected.adjustedRouteNo;
      case LinkDataTextLEDEnum.ADJUSTED_ROUTE_NAME:
        return this.routeSelected.adjustedRouteName;
      default:
        return null;
    }
  }

  /**
   * Draw index word area
   *
   * @param indexWordAreas
   * @param renderer
   * @param displayModel
   * @returns
   */
  private drawIndexWordArea(indexWordAreas: AreaLED[], renderer: Renderer2, displayModel: DisplayModelEnum): void {
    if (!indexWordAreas?.length) {
      return;
    }
    const groupIds = new Set(
      indexWordAreas.map(indexWord => {
        return indexWord.getArea().indexWordGroupId;
      })
    );
    if (groupIds?.size > 0) {
      this.indexWordService.getIndexWordsByGroupIds([...groupIds].filter(data => data)).subscribe(indexWords => {
        Promise.all(
          indexWordAreas.map(async area => {
            if (!area.canvas) {
              return;
            }
            // get index
            let index = -1;
            switch (area.getArea().referencePositionColumn) {
              case ReferencePositionColumnEDSEnum.ROUTE_NO:
                index = this.getIndexOfIndexWord(indexWords, area, this.routeSelected.routeNo);
                break;
              case ReferencePositionColumnEDSEnum.ROUTE_NAME:
                index = this.getIndexOfIndexWord(indexWords, area, this.routeSelected.routeName);
                break;
              case ReferencePositionColumnEDSEnum.ADJUSTED_ROUTE_NO:
                index = this.getIndexOfIndexWord(indexWords, area, this.routeSelected.adjustedRouteNo);
                break;
              case ReferencePositionColumnEDSEnum.ADJUSTED_ROUTE_NAME:
                index = this.getIndexOfIndexWord(indexWords, area, this.routeSelected.adjustedRouteName);
                break;
              default:
                break;
            }
            if (index == -1) {
              return;
            }
            this.handleDrawAreaIndexWord(area, indexWords, index, renderer, displayModel);
          })
        );
      });
    }
  }

  /**
   * Handle draw area index word
   *
   * @param area
   * @param indexWords
   * @param index
   * @param renderer
   * @param displayModel
   */
  private async handleDrawAreaIndexWord(
    area: AreaLED,
    indexWords: IndexWord[],
    index: number,
    renderer: Renderer2,
    displayModel: DisplayModelEnum
  ): Promise<void> {
    // draw area
    if (area.checkTypeTextArea()) {
      let textArea = area as TextAreaLED;
      textArea.text = indexWords[index].text;
      await this.drawAreaTextOnCanvas(textArea, renderer, displayModel);
    } else {
      // validate index word media
      this.mediaService.validateImageBitmap(+indexWords[index].media.id).subscribe(async data => {
        const valueCheck = +data;
        if (
          (valueCheck != 1 && valueCheck != 8) ||
          (valueCheck == 1 && displayModel == DisplayModelEnum.FULL_COLOR) ||
          (valueCheck == 8 && displayModel != DisplayModelEnum.FULL_COLOR)
        ) {
          return;
        }
        let pictureArea = area as PictureAreaLED;
        pictureArea.media = indexWords[index].media;
        await this.drawLEDService.drawAreaPictureOnDestination(pictureArea, renderer);
      });
    }
  }

  /**
   * Get index of index word
   *
   * @param indexWords
   * @param area
   * @param dataCompare
   * @returns
   */
  private getIndexOfIndexWord(indexWords: IndexWord[], area: AreaLED, dataCompare: string): number {
    return indexWords.findIndex(indexWord => indexWord.name == dataCompare && indexWord.groupId == area.getArea().indexWordGroupId);
  }

  /**
   * Get canvas id
   *
   * @param displayEnum
   * @param screenId
   * @returns
   */
  private getCanvasId(displayEnum: DisplaysEnum, screenId: string): string {
    return `${screenId}-previewCanvas${displayEnum + 1}`;
  }

  /**
   * check or uncheck a route destination
   *
   * @param id
   * @param event
   */
  public changeRouteChecked(id: Number, event: any): void {
    event.stopPropagation();
    if (this.isPlayPreview) {
      event.preventDefault();
      return;
    }
    let index = this.edsRoutes.findIndex(route => route.id === id);
    this.edsRoutes[index].isChecked = !this.edsRoutes[index].isChecked;
    this.isCheckedAllRoute = this.edsRoutes.every(route => route.isChecked);
  }

  /**
   * check or uncheck all route
   * @param event
   */
  public checkAllRoute(event: any): void {
    if (this.isPlayPreview) {
      event.preventDefault();
      return;
    }
    this.isCheckedAllRoute = !this.isCheckedAllRoute;
    if (this.edsRoutes?.length > 0) {
      this.edsRoutes.forEach(route => (route.isChecked = this.isCheckedAllRoute));
    }
  }

  /**
   * check or uncheck a bus stop destination
   *
   * @param id
   * @param event
   */
  public changeBusStopChecked(id: Number, event: any): void {
    if (this.isPlayPreview) {
      event.preventDefault();
      return;
    }
    this.isChangeBusStopChecked = true;
    let index = this.routeSelected.busStops.findIndex(busStop => busStop.index === id);
    this.routeSelected.busStops[index].isChecked = !this.routeSelected.busStops[index].isChecked;
    this.isCheckedAllBusStop = this.routeSelected.busStops.every(busStop => busStop.isChecked);
    if (this.busStopSelected?.index == id) {
      this.handleDataBusStop();
    }
  }

  /**
   * check or uncheck all bus stop of route
   *
   * @param event
   */
  public checkAllBusStop(event: any): void {
    event.stopPropagation();
    if (this.isPlayPreview) {
      event.preventDefault();
      return;
    }
    this.isCheckedAllBusStop = !this.isCheckedAllBusStop;
    this.isChangeBusStopChecked = true;
    if (this.routeSelected?.busStops?.length > 0) {
      this.routeSelected.busStops.forEach(busStop => (busStop.isChecked = this.isCheckedAllBusStop));
    }
    if (this.busStopSelected && !this.isCheckedAllBusStop) {
      this.handleDataBusStop();
    }
  }

  /**
   * Handle data bus stop
   */
  private handleDataBusStop(): void {
    this.busStopSelected = undefined;
    this.adjustedBusStopNameEdit = undefined;
    this.indexBusStopPreview = this.DEFAULT_BUS_STOP_INDEX;
    this.clearAllAreaBusStop();
  }

  /**
   * Select bus stop
   *
   * @param busStop
   * @param event
   */
  public selectBusStop(busStop: BusStopRoute, event: any): void {
    if (
      event?.target?.id === 'checkBoxBusStopDestination' ||
      busStop.index == this.busStopSelected?.index ||
      this.isEditDataBusStop ||
      this.isEditDataRoute ||
      !busStop.isChecked
    ) {
      return;
    }
    this.busStopSelected = busStop;
    this.indexBusStopPreview = +this.busStopSelected.index;
    this.adjustedBusStopNameEdit = this.busStopSelected.adjustedName;
    if (this.previewChangeOverBusStopDisplayInterval) {
      this.previewChangeOverBusStopDisplayInterval.unsubscribe();
    }
    this.clearPreviewChangeOverBusStopDisplay.next();
    this.previewBusStop();
    this.isDrawnBusStop = true;
  }

  /**
   * Preview bus stop
   */
  private previewBusStop(): void {
    for (let index = 1; index <= DisplayIndexEnum.DISPLAY_6; index++) {
      if (this.routeSelected[`display${index}`]) {
        this.previewBusStopByTemplate(this.routeSelected[`display${index}`], this.renderer);
      }
    }
  }

  /**
   * Preview bus stop by template
   *
   * @param template
   * @param renderer
   */
  private previewBusStopByTemplate(template: TemplateLED, renderer: Renderer2): void {
    if (!template) {
      return;
    }
    const indexWordAreas = this.getIndexWordAreas(template);
    if (indexWordAreas.length > 0) {
      const groupIds = new Set(
        indexWordAreas.map(indexWord => {
          return indexWord.getArea().indexWordGroupId;
        })
      );
      if (groupIds.size > 0) {
        this.indexWordService.getIndexWordsByGroupIds([...groupIds].filter(data => data)).subscribe(indexWords => {
          Promise.all(
            indexWordAreas.map(async area => {
              if (!area.canvas) {
                return;
              }
              switch (area.getArea().referencePositionColumn) {
                case ReferencePositionColumnEDSEnum.BUS_STOP_NAME:
                  this.drawIndexWordForBusStop(indexWords, area, false, renderer, template.displayModel);
                  break;
                case ReferencePositionColumnEDSEnum.ADJUSTED_BUS_STOP_NAME:
                  this.drawIndexWordForBusStop(indexWords, area, true, renderer, template.displayModel);
                  break;
                default:
                  break;
              }
            })
          );
        });
      }
    }
    const linkArea = template.areas.filter(
      area =>
        (area.getArea().linkReferenceData == LinkDataTextLEDEnum.BUS_STOP_NAME ||
          area.getArea().linkReferenceData == LinkDataTextLEDEnum.ADJUSTED_BUS_STOP_NAME) &&
        !area.isFix
    );
    this.drawLinkArea(linkArea, renderer, template.displayModel);
  }

  /**
   * Draw change over bus stop
   *
   * @param areaLED
   * @param renderer
   * @param displayModel
   * @returns
   */
  private async drawChangeOverBusStop(areaLED: TextAreaLED, renderer: Renderer2, displayModel: DisplayModelEnum): Promise<void> {
    const listBusStop = this.routeSelected.busStops.filter(busStop => busStop.isChecked);
    if (listBusStop.length == 0) {
      return;
    }
    let index = listBusStop.findIndex(busStop => busStop.index == this.busStopSelected?.index);
    if (index == -1) {
      return;
    }
    if (areaLED.changeover) {
      this.drawLEDService.clearIntervalBusStopChangeOver(areaLED);
      areaLED.text =
        areaLED.linkReferenceData == LinkDataTextLEDEnum.BUS_STOP_NAME ? listBusStop[index].name : listBusStop[index].adjustedName;
      await this.drawAreaTextOnCanvas(areaLED, renderer, displayModel);
      const observable = interval(areaLED.duration * 1000);
      this.previewChangeOverBusStopDisplayInterval = observable
        .pipe(
          takeUntil(this.clearPreviewChangeOverBusStopDisplay),
          takeUntil(this.pausePreviewChangeOverBusStopDisplay),
          repeatWhen(() => this.startPreviewChangeOverBusStopDisplay)
        )
        .subscribe(async () => {
          this.drawLEDService.clearIntervalBusStopChangeOver(areaLED);
          index++;
          if (index > listBusStop.length - 1) {
            index = 0;
          }
          areaLED.text =
            areaLED.linkReferenceData == LinkDataTextLEDEnum.BUS_STOP_NAME ? listBusStop[index].name : listBusStop[index].adjustedName;
          await this.drawAreaTextOnCanvas(areaLED, renderer, displayModel);
        });
      if (!this.isPlayPreview) {
        this.pausePreviewChangeOverBusStopDisplay.next();
        this.clearPreviewChangeOverBusStopDisplay.next();
        return;
      }
    } else {
      let delimiters = this.getDelimiters(areaLED);
      let busStopNames = listBusStop.map(busStop => busStop.name);
      let adjustedBusStopNames = listBusStop.map(busStop => busStop.adjustedName);
      let text =
        areaLED.linkReferenceData == LinkDataTextLEDEnum.BUS_STOP_NAME
          ? busStopNames.join(delimiters)
          : adjustedBusStopNames.join(delimiters);
      if (text.length > 0) {
        areaLED.text = text;
        await this.drawAreaTextOnCanvas(areaLED, renderer, displayModel);
      }
    }
  }

  /**
   * Get delimiters
   *
   * @param textAreaLED
   * @returns
   */
  private getDelimiters(textAreaLED: TextAreaLED): string {
    switch (textAreaLED.delimiters) {
      case DelimitersEnum.OTHER:
        return textAreaLED.otherDelimiters;
      case DelimitersEnum.SPACE:
        return DelimitersDataEnum.SPACE;
      case DelimitersEnum.COMMA:
        return DelimitersDataEnum.COMMA;
      default:
        return DelimitersDataEnum.SEMICOLON;
    }
  }

  /**
   * Draw index word for bus stop
   *
   * @param indexWords
   * @param area
   * @param isCheckNameAdjusted
   * @param renderer
   * @param displayModel
   */
  private drawIndexWordForBusStop(
    indexWords: IndexWord[],
    area: AreaLED,
    isCheckNameAdjusted: boolean,
    renderer: Renderer2,
    displayModel: DisplayModelEnum
  ): void {
    this.clearAreaCanvas(area);
    this.routeSelected.busStops
      .filter(data => data.isChecked)
      .forEach(async busStop => {
        let index = indexWords.findIndex(
          indexWord =>
            indexWord.name == (isCheckNameAdjusted ? busStop.adjustedName : busStop.name) &&
            indexWord.groupId == area.getArea().indexWordGroupId
        );
        if (index != -1 && this.busStopSelected?.index == busStop.index) {
          this.handleDrawAreaIndexWord(area, indexWords, index, renderer, displayModel);
        }
      });
  }

  /**
   * Clear area canvas
   *
   * @param area
   */
  private clearAreaCanvas(area: AreaLED): void {
    let ctx = area.canvas.getContext('2d');
    ctx.clearRect(0, 0, area.canvas.width, area.canvas.height);
  }

  /**
   * Reflect data from route list editor
   */
  private reflectDataFromRouteListEditor(): void {
    if (this.isPlayPreview || this.isEditDataBusStop || this.isEditDataRoute) {
      return;
    }
    if (this.isChangeBusStopChecked) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: 'There are edited data. Do you want to save?',
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.edsRouteListService.saveEdsRouteList(Helper.convertEdsRouteToBackward(this.routeSelected)).subscribe(
              () => {
                this.reflectEdsRoutes();
              },
              () => this.handleErrorMessage('An error has occurred. Please try again.')
            );
          } else {
            this.routeSelected.busStops = this.oldBusStops;
            this.reflectEdsRoutes();
          }
        }
      );
    } else {
      this.reflectEdsRoutes();
    }
  }

  /**
   * Reflect eds routes
   */
  private reflectEdsRoutes(): void {
    if (this.edsRoutes.length > 0) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: 'Please select either replace or append to the current route list.',
            button1: 'Replace',
            button2: 'Append',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.handleReflectDataFromRouteListEditor(true);
            return;
          }
          this.handleReflectDataFromRouteListEditor(false);
        }
      );
    } else {
      this.handleReflectDataFromRouteListEditor(false);
    }
  }

  /**
   * Handle reflect data from route list editor
   *
   * @param isReplaceData
   */
  private handleReflectDataFromRouteListEditor(isReplaceData: boolean): void {
    this.edsRouteListService.reflectDataFromRouteListEditor(isReplaceData).subscribe(
      dataReflection => {
        this.clearDataAfterDelete();
        this.edsRoutes = isReplaceData
          ? Helper.convertEdsListRouteListFromServer(dataReflection)
          : this.edsRoutes.concat(Helper.convertEdsListRouteListFromServer(dataReflection));
        if (this.edsRoutes.length > 0) {
          this.selectRoute(this.edsRoutes[this.edsRoutes.length - 1], null);
          this.isCheckedAllRoute = this.edsRoutes.every(data => data.isChecked);
        }
      },
      error => {
        this.handleShowErrorMessageFromServer(`${error.error?.detail}`);
      }
    );
  }

  /**
   * Handle show error message from server
   *
   * @param error
   * @returns
   */
  private handleShowErrorMessageFromServer(error: any): void {
    switch (error) {
      case ErrorCodeDestination.ROUTE_MAX:
        this.handleErrorMessage('The maximum number of routeList being created is 1000!');
        break;
      case ErrorCodeDestination.ROUTE_NO_FORMAT_INVALID:
        this.handleErrorMessage('Invalid Route No. format!');
        break;
      case ErrorCodeDestination.DUPLICATE_ROUTE:
        this.handleErrorMessage('Duplicated RouteNo and Suffix!');
        break;
      case ErrorCodeDestination.SUFFIX_FORMAT_INVALID:
        this.handleErrorMessage('Invalid Route Suffix format!');
        break;
      case ErrorCodeDestination.ROUTE_NAME_FORMAT_INVALID:
        this.handleErrorMessage('Invalid Route Name format!');
        break;
      case ErrorCodeDestination.BUS_STOP_NO_FORMAT_INVALID:
        this.handleErrorMessage('Invalid Bus Stop No. format!');
        break;
      case ErrorCodeDestination.BUS_STOP_NAME_FORMAT_INVALID:
        this.handleErrorMessage('Invalid Bus Stop Name format!');
        break;
      case ErrorCodeDestination.HEADER_FORMAT_INVALID:
        this.handleErrorMessage('Invalid header format!');
        break;
      case ErrorCodeDestination.START_CODE_FORMAT_INVALID:
        this.handleErrorMessage('Invalid Start code format!');
        break;
      case ErrorCodeDestination.NO_START_CODE:
        this.handleErrorMessage('Data file is empty!');
        break;
      case ErrorCodeDestination.ADJUSTED_BUS_STOP_NAME_FORMAT_INVALID:
        this.handleErrorMessage('Invalid Adjusted Bus Stop Name format!');
        break;
      case ErrorCodeDestination.LABEL_FORMAT_INVALID:
        this.handleErrorMessage('Invalid Label format!');
        break;
      case ErrorCodeDestination.ADJUSTED_ROUTE_NO_FORMAT_INVALID:
        this.handleErrorMessage('Invalid Adjusted Route No. format!');
        break;
      case ErrorCodeDestination.ADJUSTED_ROUTE_NAME_FORMAT_INVALID:
        this.handleErrorMessage('Invalid Adjusted Route Name format!');
        break;
      case ErrorCodeDestination.TEMPLATE_FORMAT_INVALID:
        this.handleErrorMessage('Invalid Template format!');
        break;
      default:
        this.handleErrorMessage('An error has occurred. Please try again.');
        break;
    }
  }

  /**
   * Handle error message
   * @param message
   */
  private handleErrorMessage(message: string): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: `Error`,
        text: message
      }
    });
  }

  /**
   * Import route list
   */
  private importRouteList(): void {
    if (this.isPlayPreview || this.isEditDataBusStop || this.isEditDataRoute) {
      return;
    }
    if (this.isChangeBusStopChecked) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: 'There are edited data. Do you want to save?',
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.edsRouteListService.saveEdsRouteList(Helper.convertEdsRouteToBackward(this.routeSelected)).subscribe(
              () => {
                this.importEdsRoutes();
              },
              () => this.handleErrorMessage('An error has occurred. Please try again.')
            );
          } else {
            this.routeSelected.busStops = this.oldBusStops;
            this.importEdsRoutes();
          }
          this.isChangeBusStopChecked = false;
          this.isCheckedAllBusStop = this.routeSelected.busStops?.every(busStop => busStop.isChecked);
        }
      );
    } else {
      this.importEdsRoutes();
    }
  }

  /**
   * Import eds routes
   */
  private importEdsRoutes(): void {
    if (this.edsRoutes.length > 0) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: 'Please select either replace or append to the current route list.',
            button1: 'Replace',
            button2: 'Append',
            title: 'Confirmation'
          }
        },
        result => {
          this.isReplaceData = result ? result : false;
          this.handleImportDataRouteList();
        }
      );
    } else {
      this.isReplaceData = true;
      this.handleImportDataRouteList();
    }
  }

  /**
   * Handle import data route list
   */
  private handleImportDataRouteList(): void {
    let element = document.getElementById(this.IMPORT_FILE_ELEMENT) as HTMLInputElement;
    element.setAttribute('accept', `.${this.XLSX_EXTENSIONS}`);
    element.click();
  }

  /**
   * Upload route list from client file (.xlsx)
   *
   * @param event
   * @returns
   */
  public uploadRouteListFromClientFile(event: any): void {
    let selectedFiles: any[] = event.target.files;
    const typeFiles = [this.XLSX_EXTENSIONS];
    const file = selectedFiles[this.FIRST_ELEMENT];
    // reset input file value
    let element = document.getElementById(this.IMPORT_FILE_ELEMENT) as HTMLInputElement;
    element.value = null;
    let typeName = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
    if (!typeFiles.includes(typeName)) {
      this.handleErrorMessage('This file format is not supported.');
      return;
    }
    this.edsRouteListService.uploadRouteListFromClientFile(this.isReplaceData, file).subscribe(
      edsRouteListFromServer => {
        this.clearDataAfterDelete();
        this.edsRoutes = this.isReplaceData
          ? Helper.convertEdsListRouteListFromServer(edsRouteListFromServer)
          : this.edsRoutes.concat(Helper.convertEdsListRouteListFromServer(edsRouteListFromServer));
        if (this.edsRoutes.length > 0) {
          this.selectRoute(this.edsRoutes[this.edsRoutes.length - 1], null);
        }
        this.isCheckedAllRoute = this.edsRoutes.every(data => data.isChecked);
      },
      error => {
        this.handleShowErrorMessageFromServer(`${error.error?.detail}`);
      }
    );
  }

  /**
   * Export route list
   *
   * @returns
   */
  private exportRouteList(): void {
    if (this.isPlayPreview || this.isEditDataBusStop || this.isEditDataRoute) {
      return;
    }
    if (!this.edsRoutes.some(data => data.isChecked)) {
      this.handleErrorMessage('Please select a route.');
      return;
    }
    if (!this.isChangeBusStopChecked) {
      this.writeRouteListToFile();
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: 'There are edited data. Do you want to save?',
          button1: 'Yes',
          button2: 'No',
          title: 'Confirmation'
        }
      },
      result => {
        this.isChangeBusStopChecked = false;
        if (result) {
          this.edsRouteListService.saveEdsRouteList(Helper.convertEdsRouteToBackward(this.routeSelected)).subscribe(
            () => {
              this.writeRouteListToFile();
            },
            () => this.handleErrorMessage('An error has occurred. Please try again.')
          );
        } else {
          this.routeSelected.busStops = this.oldBusStops;
          this.writeRouteListToFile();
        }
      }
    );
  }

  /**
   * Write route list to file
   */
  private writeRouteListToFile(): void {
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: 'Please select export routes either “All Routes” or “Selected Routes”.',
          button1: 'All Routes',
          button2: 'Selected Routes',
          title: 'Confirmation'
        }
      },
      result => {
        let edsRoutesToExport = this.getEdsRouteToExport(_.cloneDeep(this.edsRoutes), result ? result : false);
        if (edsRoutesToExport.length <= 0) {
          return;
        }
        this.edsRouteListService.writeRouteListToFile(Helper.convertExportEdsRoutesToBackward(edsRoutesToExport)).subscribe(
          response => {
            const fileNameResponse = response.headers.get('content-disposition');
            const file = new File([response.body], fileNameResponse, {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            fileSaver.saveAs(file);
          },
          () => this.handleErrorMessage('An error has occurred. Please try again.')
        );
      }
    );
  }

  /**
   * Get eds route to export
   *
   * @param edsRoutes
   * @param isExportAllRoute
   * @returns
   */
  private getEdsRouteToExport(edsRoutes: Array<RouteDestination>, isExportAllRoute: boolean): RouteDestination[] {
    if (edsRoutes?.length <= 0) {
      return [];
    }
    edsRoutes.forEach(route => {
      route['listBusStop'] = Helper.convertEdsBusStopToBackward(route.busStops.filter(busStop => busStop.isChecked));
      for (let index = 1; index <= DisplayIndexEnum.DISPLAY_6; index++) {
        if (route[`idTemplate${index}`]) {
          route[`template${index}Name`] = route.templateNamesToDisplay[index - 1];
        }
      }
    });
    return isExportAllRoute ? edsRoutes : edsRoutes.filter(route => route.isChecked);
  }

  /**
   * Delete route list
   *
   * @returns
   */
  private deleteRouteList(): void {
    if (this.isPlayPreview || this.isEditDataBusStop || this.isEditDataRoute) {
      return;
    }
    const routesChecked = this.edsRoutes
      .filter(route => route.isChecked)
      .map(data => {
        return data.id;
      });
    if (routesChecked.length <= 0) {
      this.handleErrorMessage('Please select a route.');
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: 'Do you want to delete the selected route?',
          button1: 'Yes',
          button2: 'No',
          title: 'Confirmation'
        }
      },
      result => {
        if (result) {
          this.edsRouteListService.deleteEdsRouteChecked(routesChecked).subscribe(
            () => {
              this.edsRoutes = this.edsRoutes.filter(route => !routesChecked.includes(route.id));
              this.isCheckedAllRoute = false;
              if (this.edsRoutes.length == 0) {
                this.clearDataAfterDelete();
              } else if (routesChecked.includes(this.routeSelected.id) && this.edsRoutes.length > 0) {
                this.isChangeBusStopChecked = false;
                this.selectRoute(this.edsRoutes[0], null);
              }
              this.toast.success('Successfully!');
            },
            () => this.handleErrorMessage('An error has occurred. Please try again.')
          );
        }
      }
    );
  }

  /**
   * Clear data after delete all route
   */
  private clearDataAfterDelete(): void {
    this.changeDetectorRef.detectChanges();
    Helper.clearNodeChild(this.divContainCanvas1?.nativeElement);
    Helper.clearNodeChild(this.divContainCanvas2?.nativeElement);
    Helper.clearNodeChild(this.divContainCanvas3?.nativeElement);
    Helper.clearNodeChild(this.divContainCanvas4?.nativeElement);
    Helper.clearNodeChild(this.divContainCanvas5?.nativeElement);
    Helper.clearNodeChild(this.divContainCanvas6?.nativeElement);
    this.clearTimeoutDisplay(this.timeoutsDisplay);
    this.clearAllThreadDisplay();
    this.clearAllAreaBusStop();
    this.busStopSelected = undefined;
    this.routeSelected = undefined;
    this.isChangeBusStopChecked = false;
  }

  /**
   * Edit data destination sign
   *
   * @returns
   */
  private editDataDestinationSign(): void {
    if (this.isEditDataRoute || this.isPlayPreview) {
      return;
    }
    if (this.busStopSelected) {
      this.isEditDataBusStop = true;
      this.dataService.sendData([Constant.IS_EDIT_DATA, this.isEditDataBusStop]);
    } else {
      if (!this.routeSelected) {
        this.handleErrorMessage('Please select a route.');
        return;
      }
      this.isEditDataRoute = true;
      this.dataService.sendData([Constant.IS_EDIT_DATA, this.isEditDataRoute]);
    }
  }

  /**
   * Save eds route List
   */
  public saveEdsRouteList(): void {
    if (!this.validateEdsRouteList()) {
      return;
    }
    if (!this.routeSelected.isEditAdjustedNo) {
      this.routeSelected.isEditAdjustedNo = this.routeSelected.adjustedRouteNo != this.adjustedRouteNoEdit.trim();
    }
    if (!this.routeSelected.isEditAdjustedName) {
      this.routeSelected.isEditAdjustedName = this.routeSelected.adjustedRouteName != this.adjustedRouteNameEdit.trim();
    }
    this.routeSelected.adjustedRouteNo = this.adjustedRouteNoEdit.trim();
    this.routeSelected.adjustedRouteName = this.adjustedRouteNameEdit.trim();
    this.edsRouteListService.saveEdsRouteList(Helper.convertEdsRouteToBackward(this.routeSelected)).subscribe(
      () => {
        this.saveDataSuccess.emit(true);
        this.isEditDataRoute = false;
        this.isChangeBusStopChecked = false;
        this.dataService.sendData([Constant.IS_EDIT_DATA, this.isEditDataRoute]);
        this.isChangeData = true;
        this.toast.success('Successfully!');
      },
      () => this.handleErrorMessage('An error has occurred. Please try again.')
    );
  }

  /**
   * Validate eds route list
   *
   * @returns
   */
  private validateEdsRouteList(): boolean {
    let adjustedRouteNoEdit = this.adjustedRouteNoEdit.trim();
    let adjustedRouteNameEdit = this.adjustedRouteNameEdit.trim();
    if (adjustedRouteNoEdit.length > this.ROUTE_NO_MAX_LENGTH) {
      this.handleErrorMessage('Adjusted Route No. must contain no more than 5 characters.');
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (adjustedRouteNoEdit.length < this.ROUTE_NO_MIN_LENGTH) {
      this.handleErrorMessage('Adjusted Route No. cannot be empty.');
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (!adjustedRouteNoEdit.match(Constant.FORMAT_NO_REGEX)) {
      this.handleErrorMessage('Adjusted Route No. must contain only alphanumeric characters.');
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (adjustedRouteNameEdit.length > this.ROUTE_NAME_MAX_LENGTH) {
      this.handleErrorMessage('Adjusted Route Name must contain no more than 48 characters.');
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (adjustedRouteNameEdit.length < this.ROUTE_NAME_MIN_LENGTH) {
      this.handleErrorMessage('Adjusted Route Name cannot be empty.');
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (!adjustedRouteNameEdit.match(Constant.FORMAT_ROUTE_NAME_REGEX)) {
      this.handleErrorMessage('Adjusted Route Name must contain only alphanumeric characters.');
      this.saveDataSuccess.emit(false);
      return false;
    }
    return true;
  }

  /**
   * Save eds bus stop
   *
   * @returns
   */
  public saveEdsBusStop(): void {
    if (!this.validateEdsBusStop()) {
      return;
    }
    let index = this.routeSelected.busStops.findIndex(busStop => busStop.index == this.busStopSelected.index);
    if (index == -1) {
      return;
    }
    if (!this.busStopSelected.isEdited) {
      this.busStopSelected.isEdited = this.busStopSelected.adjustedName != this.adjustedBusStopNameEdit.trim();
    }
    this.busStopSelected.adjustedName = this.adjustedBusStopNameEdit.trim();
    this.routeSelected.busStops[index].adjustedName = this.adjustedBusStopNameEdit;
    this.routeSelected.busStops[index].isEdited = this.busStopSelected.isEdited;
    this.edsRouteListService.saveEdsRouteList(Helper.convertEdsRouteToBackward(this.routeSelected)).subscribe(
      () => {
        this.saveDataSuccess.emit(true);
        this.isEditDataBusStop = false;
        this.isChangeBusStopChecked = false;
        this.dataService.sendData([Constant.IS_EDIT_DATA, this.isEditDataBusStop]);
        this.isChangeData = true;
        this.toast.success('Successfully!');
      },
      () => this.handleErrorMessage('An error has occurred. Please try again.')
    );
  }

  /**
   * Validate eds bus stop
   *
   * @returns
   */
  private validateEdsBusStop(): boolean {
    if (this.adjustedBusStopNameEdit.trim().length > this.BUS_STOP_NAME_MAX_LENGTH) {
      this.handleErrorMessage('Adjusted Bus Stop Name must contain no more than 48 characters.');
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (this.adjustedBusStopNameEdit.trim().length < this.BUS_STOP_NAME_MIN_LENGTH) {
      this.handleErrorMessage('Adjusted Bus Stop Name cannot be empty.');
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (!this.adjustedBusStopNameEdit.trim().match(Constant.FORMAT_BUS_STOP_NAME_REGEX)) {
      this.handleErrorMessage('Adjusted Bus Stop Name must contain only alphanumeric characters.');
      this.saveDataSuccess.emit(false);
      return false;
    }
    return true;
  }

  /**
   * Change template routes
   *
   * @param route
   * @returns
   */
  public changeTemplateRoutes(route?: RouteDestination): void {
    if (this.isPlayPreview || this.isEditDataBusStop || this.isEditDataRoute) {
      return;
    }
    let routesChecked = route ? [route] : this.edsRoutes.filter(route => route.isChecked);
    const oldRoutes = _.cloneDeep(routesChecked);
    if (routesChecked?.length <= 0) {
      this.handleErrorMessage('Please select a route.');
      return;
    }
    this.dialogService.showDialog(
      DialogChangeTemplateForDestinationComponent,
      {
        data: {
          display1: this.getDisplayTemplateToShowOnDialog(routesChecked, DisplayIndexEnum.DISPLAY_1),
          display2: this.getDisplayTemplateToShowOnDialog(routesChecked, DisplayIndexEnum.DISPLAY_2),
          display3: this.getDisplayTemplateToShowOnDialog(routesChecked, DisplayIndexEnum.DISPLAY_3),
          display4: this.getDisplayTemplateToShowOnDialog(routesChecked, DisplayIndexEnum.DISPLAY_4),
          display5: this.getDisplayTemplateToShowOnDialog(routesChecked, DisplayIndexEnum.DISPLAY_5),
          display6: this.getDisplayTemplateToShowOnDialog(routesChecked, DisplayIndexEnum.DISPLAY_6)
        }
      },
      result => {
        if (result) {
          routesChecked.forEach(route => {
            for (let index = 1; index <= DisplayIndexEnum.DISPLAY_6; index++) {
              let display = result[`display${index}`];
              route[`display${index}`] = display;
              route[`idTemplate${index}`] = display?.id;
              route.templateNamesToDisplay[index - 1] = display ? `${display?.templateGroupName}_${display?.name}` : null;
            }
            let templateNames = route.templateNamesToDisplay.filter(data => data);
            route.isShowIconMultiDisplay = templateNames.length > 1;
            route.templateNameShowInGUI = templateNames[0];
          });
          this.edsRouteListService.saveEdsRoutes(Helper.convertEdsRoutesToBackward(routesChecked)).subscribe(
            () => {
              this.clearAllBeforeLeave();
              const templateIds = [
                this.routeSelected.idTemplate1,
                this.routeSelected.idTemplate2,
                this.routeSelected.idTemplate3,
                this.routeSelected.idTemplate4,
                this.routeSelected.idTemplate5,
                this.routeSelected.idTemplate6
              ];
              if (templateIds.length == 0) {
                return;
              }
              if (routesChecked.find(route => route.id == this.routeSelected.id)) {
                this.handleDrawTemplateForRoute(templateIds);
              }
              this.toast.success('Successfully!');
            },
            () => {
              routesChecked = oldRoutes;
              this.handleErrorMessage('An error has occurred. Please try again.');
            }
          );
        }
      }
    );
  }

  /**
   * Handle draw template for route
   *
   * @param templateIds
   */
  private handleDrawTemplateForRoute(templateIds: number[]): void {
    this.areaLEDService.getAllAreaForRoute(templateIds).subscribe(
      areasResponse => {
        if (!areasResponse || areasResponse.length == 0) {
          return;
        }
        this.handleDataAreasForRoute(areasResponse);
        this.drawPreview();
      },
      () => this.handleErrorMessage('An error has occurred. Please try again.')
    );
  }

  /**
   * Get display template to show on dialog
   *
   * @param routesChecked
   * @param index
   * @returns
   */
  private getDisplayTemplateToShowOnDialog(routesChecked: RouteDestination[], index: number): TemplateLED {
    return routesChecked.every(route => route[`idTemplate${index}`] == routesChecked[0][`idTemplate${index}`])
      ? routesChecked[0][`display${index}`]
      : null;
  }

  /**
   * Cancel edit data
   *
   * @param isEditRoute
   */
  public cancelEditData(isEditRoute: boolean): void {
    if (isEditRoute) {
      this.isEditDataRoute = false;
      this.adjustedRouteNoEdit = this.routeSelected.routeNo;
      this.adjustedRouteNameEdit = this.routeSelected.routeName;
    } else {
      this.isEditDataBusStop = false;
      if (this.indexBusStopPreview != -1) {
        this.adjustedBusStopNameEdit = this.routeSelected.busStops[this.indexBusStopPreview].name;
      }
    }
    this.dataService.sendData([Constant.IS_EDIT_DATA, false]);
  }

  /**
   * save change data to publish
   * @param isDelivery
   * @returns
   */
  private saveChangeDataToPublish(isDelivery?: boolean): void {
    if (this.isEditDataRoute || this.isPlayPreview || this.isEditDataBusStop) {
      return;
    }
    if (!this.edsRoutes.filter(route => route.isChecked).length) {
      this.handleErrorMessage('Please select a route.');
      return;
    }
    if (this.isChangeBusStopChecked) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: 'There are edited data. Do you want to save?',
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.edsRouteListService.saveEdsRouteList(Helper.convertEdsRouteToBackward(this.routeSelected)).subscribe(
              () => {
                this.selectPublishRoutes(isDelivery);
              },
              () => this.handleErrorMessage('An error has occurred. Please try again.')
            );
          } else {
            this.routeSelected.busStops = this.oldBusStops;
            this.selectPublishRoutes(isDelivery);
          }
          this.isChangeBusStopChecked = false;
        }
      );
    } else {
      this.selectPublishRoutes(isDelivery);
    }
  }

  /**
   * select publish routes
   * @param isDelivery
   * @returns
   */
  private selectPublishRoutes(isDelivery?: boolean): void {
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: 'Please select publish routes either “All Routes” or “Selected Routes”.',
          button1: 'All Routes',
          button2: 'Selected Routes',
          title: 'Confirmation'
        }
      },
      result => {
        let edsRoutesPublish = this.getEdsRouteToExport(_.cloneDeep(this.edsRoutes), result ? result : false);

        let edsRoutesNoTemplate = edsRoutesPublish.find(
          edsRoute =>
            !edsRoute.display1 && !edsRoute.display2 && !edsRoute.display3 && !edsRoute.display4 && !edsRoute.display5 && !edsRoute.display6
        );
        if (edsRoutesNoTemplate) {
          this.handleErrorMessage('You have routes with no template set.');
          return;
        }
        isDelivery ? this.delivery(edsRoutesPublish) : this.downloadPublishFile(edsRoutesPublish);
      }
    );
  }

  /**
   * delivery
   * @param edsRoutesPublish
   */
  private delivery(edsRoutesPublish: RouteDestination[]): void {
    // open popup
    this.dialogService.showDialog(
      DialogDeliveryDestinationComponent,
      {
        data: {
          edsRoutes: edsRoutesPublish
        }
      },
      result => {}
    );
  }

  /**
   * download publish file
   * @param edsRoutesDownload
   */
  private downloadPublishFile(edsRoutesDownload: RouteDestination[]): void {
    let dataPublish = Helper.convertDataPublish(edsRoutesDownload);
    this.publishDestinationService.downloadPublishFile(dataPublish.edsRoutes, JSON.stringify(dataPublish.templateIds)).subscribe(
      data => {
        const fileNameResponse = data.headers.get('content-disposition');
        const file = new File([data.body], fileNameResponse);
        fileSaver.saveAs(file);
        this.publishTimetableService.deleteFileZipAfterDownload(fileNameResponse).toPromise();
      },
      () => this.handleErrorMessage('An error has occurred. Please try again.')
    );
  }
}
