import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-anchor-picker',
  templateUrl: './anchor-picker.component.html',
  styleUrls: ['./anchor-picker.component.scss']
})
/**
 * Component class for anchor picker
 */
export class AnchorPickerComponent implements OnInit {

  /**
   * anchor value
   */
  @Input()
  anchor: number;

  /**
   * anchor change notifier
   */
  @Output()
  anchorChange = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Set the anchor value
   * @param anchor anchor value
   */
  setAnchor(anchor) {
    this.anchor = anchor;
    this.anchorChange.emit(this.anchor);
  }

}
