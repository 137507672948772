import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ScreenNameEnum } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { Color } from '../color-picker/entity/color';

@Component({
  selector: 'color-picker-led',
  templateUrl: './color-picker-led.component.html',
  styleUrls: ['./color-picker-led.component.scss']
})
export class ColorPickerLedComponent implements AfterViewInit, OnChanges {
  @Input()
  public inputColor: string;
  @Output()
  public colorPickerChange = new EventEmitter<string>();
  @Output()
  public hideColorPicker = new EventEmitter<boolean>();

  public showed = false;
  public color: Color;

  public alpha: string;
  public presetColors: Array<Color>;
  public recentColors: Array<Color> = new Array<Color>();

  isSetAlpha: boolean;
  ScreenNameEnum = ScreenNameEnum;

  constructor(private dataService: DataService) {
    this.color = new Color(0, 0, 0, '0');
    this.alpha = '1';
    this.initPresetColors();
  }

  ngAfterViewInit(): void {
    if (this.inputColor) {
      this.getColorData(this.inputColor);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputColor']) {
      if (this.inputColor) {
        this.getColorData(this.inputColor);
      }
    }
  }

  public togglePicker() {
    this.showed = !this.showed;
    this.hideColorPicker.emit(this.showed);
    if (!this.showed) {
      this.colorPickerChange.emit(this.color.toRgbaHex(this.color));
      this.updateRecentColors(this.color);
    }
  }

  private getColorData(colorStr: string) {
    this.color = Color.fromHex(colorStr);
    this.alpha = this.color.a;
    this.isSetAlpha = this.color.a == '0';
  }

  public hide(e: Event) {
    if (!this.showed) {
      return;
    }
    this.showed = false;
    this.hideColorPicker.emit(this.showed);
    this.colorPickerChange.emit(this.color.toRgbaHex(this.color));
    this.updateRecentColors(this.color);
  }

  public inputRed(data) {
    let inputValue = data.target.value;
    let redValue = inputValue != '' ? (Number.isNaN(parseInt(inputValue)) ? 0 : parseInt(inputValue)) : 0;
    if (redValue < 0) redValue = 0;
    if (redValue > 255) redValue = 255;
    this.color.r = redValue;
    this.inputColor = this.color.toRgbaHex(this.color);
  }

  public inputGreen(data) {
    let inputValue = data.target.value;
    let greenValue = inputValue != '' ? (Number.isNaN(parseInt(inputValue)) ? 0 : parseInt(inputValue)) : 0;
    if (greenValue < 0) greenValue = 0;
    if (greenValue > 255) greenValue = 255;
    this.color.g = greenValue;
    this.inputColor = this.color.toRgbaHex(this.color);
  }

  public inputBlue(data) {
    let inputValue = data.target.value;
    let blueValue = inputValue != '' ? (Number.isNaN(parseInt(inputValue)) ? 0 : parseInt(inputValue)) : 0;
    if (blueValue < 0) blueValue = 0;
    if (blueValue > 255) blueValue = 255;
    this.color.b = blueValue;
    this.inputColor = this.color.toRgbaHex(this.color);
  }

  private initPresetColors() {
    this.presetColors = new Array<Color>();
    this.presetColors.push(Color.fromHex('#c00000'));
    this.presetColors.push(Color.fromHex('#ff0000'));
    this.presetColors.push(Color.fromHex('#ffc000'));
    this.presetColors.push(Color.fromHex('#ffff00'));
    this.presetColors.push(Color.fromHex('#92d050'));
    this.presetColors.push(Color.fromHex('#00b050'));
    this.presetColors.push(Color.fromHex('#00b0f0'));
    this.presetColors.push(Color.fromHex('#0070c0'));
    this.presetColors.push(Color.fromHex('#002060'));
    this.presetColors.push(Color.fromHex('#7030a0'));
  }

  public choosePreset(preset: Color) {
    this.color = preset;
    this.inputColor = this.color.toRgbaHex(this.color);
  }

  private updateRecentColors(color: Color) {
    if (this.recentColors.findIndex(recentColor => recentColor.equal(color)) < 0) {
      this.recentColors.push(color);
    }
    if (this.recentColors.length > 9) {
      this.recentColors = this.recentColors.slice(this.recentColors.length - 10);
    }
  }

  /**
   * check or uncheck
   * @param e
   */
  public changeChecked(e): void {
    e.stopPropagation();
    this.isSetAlpha = !this.isSetAlpha;
    let alphaValue = this.isSetAlpha ? 0 : 1;
    if (alphaValue < 0) alphaValue = 0;
    if (alphaValue > 1) alphaValue = 1;
    this.alpha = `${alphaValue}`;
  }
}
