import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'app/service/common.service';

@Injectable()
export class CustomMultipleDatePickerComponentI18n extends NgbDatepickerI18n {
  language: string; // Thêm thuộc tính language để xác định ngôn ngữ

  constructor(private translateService: TranslateService) {
    super();
    this.language = this.translateService.currentLang;
  }
  getDayNumerals(date: NgbDateStruct): string {
    return '';
  }

  getWeekNumerals(weekNumber: number): string {
    return '';
  }

  getYearNumerals(year: number): string {
    return year.toString();
  }

  getWeekdayShortName(weekday: number): string {
    if (this.language === 'jp') {
      const weekdays = ['月', '火', '水', '木', '金', '土', '日'];
      return weekdays[weekday - 1];
    } else {
      const weekdays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
      return weekdays[weekday - 1];
    }
  }

  getWeekdayFullName(weekday: number): string {
    if (this.language === 'jp') {
      const weekdays = ['月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日', '日曜日'];
      return weekdays[weekday - 1];
    } else {
      const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      return weekdays[weekday - 1];
    }
  }

  getMonthShortName(month: number): string {
    if (this.language === 'jp') {
      const months = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
      return months[month - 1];
    } else {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return months[month - 1];
    }
  }

  getMonthFullName(month: number): string {
    if (this.language === 'jp') {
      return this.getMonthShortName(month);
    } else {
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ];
      return months[month - 1];
    }
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    const day = date.day;
    const month = this.getMonthFullName(date.month);
    const year = date.year;
    if (this.language === 'jp') {
      return `${year}年${month}${day}日`;
    } else {
      return `${day} ${month} ${year}`;
    }
  }
}
