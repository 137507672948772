import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Constant } from 'app/config/constants';
import { SendDataService } from 'app/service/send-data.service';
import _ from 'lodash';
@Component({
  selector: 'custom-input-time',
  templateUrl: './custom-input-time.component.html',
  styleUrls: ['./custom-input-time.component.scss']
})
export class CustomInputTimeComponent implements OnInit, OnChanges {
  valueInputHH: string;
  valueInputMM: string;
  valueInputSS: string;
  @Input() public timeSecond: number;
  @Output() public timeChange = new EventEmitter<{ timeChangeValue: number; inputElement: ElementRef }>();
  @Input() public idCustomInputTime: string;
  TYPE_INPUT = TYPE_INPUT;
  private currentInputFocus;
  @Input() public isBorder: boolean;
  @Input() public disableInputTime: boolean;
  constructor(private dataService: SendDataService) {
    this.dataService.currentData.subscribe(data => {
      const inputTimeElement = data.object.inputElement?.inputElement;
      if (data.object.isFocus) {
        if (inputTimeElement) {
          inputTimeElement.focus();
        }
        this.timeSecond = data.object.time;
      } else if (inputTimeElement) {
        inputTimeElement.blur();
      }
    });
  }

  ngOnInit(): void {
    if (this.timeSecond) {
      this.changeTime(this.timeSecond);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNaN(changes.timeSecond?.currentValue)) {
      this.changeTime(changes.timeSecond?.previousValue);
      return;
    }
    this.changeTime(changes.timeSecond?.currentValue);
  }
  /**
   * key down
   */
  public keyDown(event: any) {
    if (event.keyCode == 13) {
      this.dataService.sendData({
        object: {
          isFocus: false
        }
      });
      const time = `${this.valueInputHH}:${this.valueInputMM}:${this.valueInputSS}`;
      this.timeChange.emit({ timeChangeValue: this.convertDuration(time), inputElement: this.currentInputFocus });
    }
  }

  /**
   * focus
   */
  focus(element: any, event: any) {
    this.dataService.sendData({
      object: {
        isFocus: true,
        key: Constant.FOCUS_INPUT_TIME
      }
    });
    this.currentInputFocus = element;
    event.target.select();
  }

  /**
   * change time
   * @param timeNumber
   */
  private changeTime(timeNumber: number) {
    if (!_.isNumber(timeNumber)) {
      return;
    }
    const time = this.formatDuration(timeNumber).split(':');
    this.valueInputHH = time[0];
    this.valueInputMM = time[1];
    this.valueInputSS = time[2];
  }

  /**
   * convert ss to hh:mm:ss
   * @param time string time
   */
  convertDuration(time): number {
    if (!time) {
      return 0;
    }
    if (time.includes('.')) {
      return NaN;
    }
    const timeArray = time.split(':');
    return +timeArray[0] * 3600 + +timeArray[1] * 60 + +timeArray[2];
  }

  /**
   * format time to hh:mm:ss:SS
   * @param duration time
   */
  formatDuration(duration): string {
    if (!duration && isNaN(duration)) {
      return '';
    }
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor(duration / 60) % 60;
    const seconds = duration % 60;
    return [hours, minutes, seconds].map(time => (time < 10 ? '0' + time : time)).join(':');
  }

  /**
   * focusout
   *
   * @param event
   * @param type
   */
  focusout(event: any, type: any) {
    let valueInput = event.target.value;
    const defaultValueInput = '00';
    switch (type) {
      case TYPE_INPUT.HOUR:
        if (valueInput.length == 0) {
          this.valueInputHH = defaultValueInput;
        } else if (valueInput.length == 1) {
          this.valueInputHH = valueInput.padStart(2, '0');
        }
        break;
      case TYPE_INPUT.MINUTE:
        if (valueInput.length == 0) {
          this.valueInputMM = defaultValueInput;
        } else if (valueInput.length == 1) {
          this.valueInputMM = valueInput.padStart(2, '0');
        }
        break;
      case TYPE_INPUT.SECONDS:
        if (valueInput.length == 0) {
          this.valueInputSS = defaultValueInput;
        } else if (valueInput.length == 1) {
          this.valueInputSS = valueInput.padStart(2, '0');
        }
        break;
      default:
        break;
    }
    this.focusOutSendData();
  }
  /**
   * focus out send data
   */
  private focusOutSendData() {
    this.dataService.sendData({
      object: {
        isFocus: false,
        key: Constant.FOCUS_INPUT_TIME
      }
    });
    const time = `${this.valueInputHH}:${this.valueInputMM}:${this.valueInputSS}`;
    this.timeChange.emit({ timeChangeValue: this.convertDuration(time), inputElement: this.currentInputFocus });
  }
}

export enum TYPE_INPUT {
  HOUR,
  MINUTE,
  SECONDS
}
