import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { Helper } from 'app/common/helper';
import { Countries } from 'app/config/countries';
import _ from 'lodash';

@Component({
  selector: 'custom-phone-input',
  templateUrl: './custom-phone-input.component.html',
  styleUrls: ['./custom-phone-input.component.scss']
})
export class CustomPhoneInputComponent implements OnInit {
  @Input() phoneNumberInput: string;
  @Output() phoneNumberOutput = new EventEmitter();
  @Output() dialCodeLength = new EventEmitter();
  countries: any[] = Countries;
  selectedCountry: any;
  dropdownOpen: boolean = false;
  textSearch: string;
  phoneNumber: string;
  private DIALCODE_JP = '+81';
  private clickListener: () => void;
  constructor(private renderer: Renderer2, private el: ElementRef, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (!this.phoneNumberInput) {
      this.phoneNumber = '';
      this.selectedCountry = Countries.find(e => e.dialCode == this.DIALCODE_JP);
      this.dialCodeLength.emit(this.selectedCountry.dialCode.length);
    } else {
      this.phoneNumber = Helper.convertPhoneNumber(_.cloneDeep(this.phoneNumberInput));
      this.selectedCountry = Countries.find(e => e.dialCode == this.convertDialCode(_.cloneDeep(this.phoneNumberInput)));
      const phoneNumberOutput = `${this.selectedCountry.dialCode}-${this.phoneNumber}`;
      this.phoneNumberOutput.emit(phoneNumberOutput);
      this.dialCodeLength.emit(this.selectedCountry.dialCode.length);
    }
  }

  ngOnDestroy() {
    this.removeClickListener();
  }

  /**
   * toggleDropdown
   */
  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
    if (this.dropdownOpen) {
      this.addClickListener();
    } else {
      this.removeClickListener();
    }
  }

  /**
   * addClickListener
   */
  addClickListener(): void {
    if (!this.clickListener) {
      this.clickListener = this.renderer.listen('document', 'click', this.clickOutsideHandler.bind(this));
    }
  }

  /**
   * removeClickListener
   */
  removeClickListener(): void {
    if (this.clickListener) {
      this.clickListener();
      this.clickListener = null;
    }
  }

  /**
   * clickOutsideHandler
   * @param event
   */
  clickOutsideHandler(event: Event): void {
    const target = event.target as HTMLElement;
    if (!this.el.nativeElement.contains(target)) {
      this.dropdownOpen = false;
      this.removeClickListener();
    }
  }

  /**
   * onCountryChange
   * @param country
   */
  onCountryChange(country: any): void {
    this.selectedCountry = country;
    this.phoneNumberOutput.emit(`${this.selectedCountry.dialCode}-${this.phoneNumber}`);
    this.dialCodeLength.emit(this.selectedCountry.dialCode.length);
    this.dropdownOpen = false;
    this.removeClickListener();
  }

  /**
   * changePhoneNumber
   */
  changePhoneNumber(): void {
    this.phoneNumberOutput.emit(`${this.selectedCountry.dialCode}-${this.phoneNumber}`);
  }

  /**
   * search
   * @returns
   */
  search(): void {
    if (!this.textSearch) {
      this.countries = Countries;
      return;
    }
    this.countries = Countries.filter(
      e =>
        e.name
          .trim()
          .toLocaleLowerCase()
          .includes(this.textSearch.trim().toLocaleLowerCase()) ||
        e.dialCode
          .trim()
          .toLocaleLowerCase()
          .includes(this.textSearch.trim().toLocaleLowerCase())
    );
  }

  /**
   * convertPhoneNumber
   * @param input
   * @returns
   */
  convertPhoneNumber(input: string): string {
    if (!input) {
      return;
    }
    if (!input.includes('-')) {
      return input;
    }
    const index = input.indexOf('-');
    return input.substring(index);
  }

  /**
   * convertDialCode
   * @param input
   * @returns
   */
  convertDialCode(input: string): string {
    if (!input || !input.includes('-')) {
      return this.DIALCODE_JP;
    }
    return input.split('-')[0];
  }

  /**
   * closeDropDown
   */
  closeDropDown(): void {
    this.dropdownOpen = false;
  }

  /**
   * getWidthInput
   */
  getWidthInput() {
    const maxHeight = 228;
    const widthIcon = document.getElementById('icon').offsetWidth;
    return maxHeight - widthIcon;
  }

  /**
   * validateNumber
   * @param event
   */
  validateNumber(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^\u0030-\u0039]/g, '');
    this.phoneNumber = input.value;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * validatePaste
   * @param event
   */
  validatePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || window['clipboardData'];
    const pastedData = clipboardData.getData('text');
    // Chặn nếu dữ liệu dán không chỉ chứa ký tự số
    if (!/^\d+$/.test(pastedData)) {
      event.preventDefault();
    }
  }
}
