import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomDatePickerI18n extends NgbDatepickerI18n {
  private readonly weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  private readonly months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  constructor(private translateService: TranslateService) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return this.translateService.instant(`date-picker.week-day.${this.weekdays[weekday - 1]}`);
  }
  getMonthShortName(month: number): string {
    return this.translateService.instant(`date-picker.month.${this.months[month - 1]}`);
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
